import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';
import axios from 'axios';





import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import WALogo from './WA_logo.png'


import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';

import MuiAlert from '@mui/material/Alert';



import EdonLogo from './edonLogoWhite.png'
import FlexLogo from './flexe_Logo.png';
import MicrosoftLogo from './MicrosoftLogo.jpeg'
import AcmeLogo from './img/acme.jpeg';


import HeaderMenus from './Components/HeaderMenus.js';



import MarketFilesGrid from './Components/MarketFilesGrid';
import ParameterSelector from './Components/ParameterSelector.js';
import WaitingOnOptimization from './Components/WaitingOnOptimization.js';
import WarehouseDataDisplay from './Components/WarehouseDataDisplay.js';
import WarehouseMap from './Components/WarehouseMap.js';
import ParetoDistanceTable from './Components/ParetoDistanceTable.js';
import ParetoDistanceChart from './Components/ParetoDistanceChart.js';
import SavedNetOptzModels from './Components/SavedNetOptzModels';
import DropDownSelector from './Components/DropDownSelector';
import Copyright from './Components/Copyright.js';
import AccountDropDown from './Components/AccountDropDown.js';
import MessageSnackBar from './Components/MessageSnackBar';
import Unauthorized from './Components/Unauthorized';



// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});







export default function NetworkOptimization(props) {
  const contextUserData = useContext(UserContext)
  const [userData, setUserData] = React.useState(() => {
    const storedUserData = localStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : contextUserData;
  });




  const pageTitle = 'Network Optimization';


  const [ marketFilesData, setMarketFilesData ] = React.useState([]);
  const [ viewMarketFilesData, setViewMarketFilesData ] = React.useState(false);


  const [ savedNetOptzModelsData, setSavedNetOptzModelsData ] = React.useState([])
  const [ viewSavedNetOptzModels, setViewSavedNetOptzModels ] = React.useState(false);
  const [ viewSavedNetOptzModelsMessage, setSavedNetOptzModelsMessage ] = React.useState('View Saved Network Optimizations');


  const [ selectedFile, setSelectedFile ] = React.useState();
  const  [ viewParameters, setViewParameters ] = React.useState(true);
  const [ viewWaiting, setViewWaiting ] = React.useState(false);
  const [ processingMessageIndex, setProcessingMessageIndex] = React.useState(5);
  const [ viewWarehouseDataDisplay, setViewWarehouseDataDisplay ] = React.useState(false);
  const [ resultsDistanceInTable, setResultsDistanceInTable ] = React.useState([]);
  const [ paretoDistanceChart, setParetoDistanceChart ] = React.useState([]);
  const [ disableSaveButton, setDisableSaveButton ] = React.useState(false);



  const [ netOptzData, setNetOptzData ] = React.useState();
  const [ viewResults, setviewResults ] = React.useState(false);
  const [ warehouseDetailTable, setWarehouseDetailTable ] = React.useState()
  const [snackOpen, setSnackOpen] = React.useState(false);


  const startNew = () => {
    //setViewMarketFilesData(false);
    //setViewMarketMessage('View Saved Market Files');
    //setViewSavedNetOptzModels(false);
    setViewWarehouseDataDisplay(false);
    setViewParameters(true);
    setviewResults(false);

  }


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };




  {/* Get Market Files */}
  //SECTION View Market Files

  const [ viewMarketMessage, setViewMarketMessage ] = React.useState('Start New Market Network Optimization');
  //SECTION Axios get Market File Data
  const getMarketFileData = () => {
    //console.log(headers);
    const accountCode = userData.accountCode;
    const headers = props.headers;

    axios.get(`information/listmarketfilesinfo/${accountCode}`, { headers })
    .then(function (response) {
      // handle success


      setMarketFilesData(response.data);
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status != 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }

    })
    .then(function () {
      // always executed
    });
  }
  //!SECTION

  const toggleSavedMarketFiles = () => {
    if (viewMarketFilesData){
      setViewMarketFilesData(false);
      setViewMarketMessage('Start New Market Network Optimization');
    }else{
      setViewSavedNetOptzModels(false);
      // setViewParameters(false);
      // setSelectedFile(false);
      setviewResults(false);
      setSavedNetOptzModelsMessage('View Saved Network Optimizations');
      setViewMarketFilesData(true);
      setViewMarketMessage('Hide Market Files');
      setViewParameters(false);
      getMarketFileData();
    };
  };
//!SECTION


  //SECTION Axios get Saved Network Optimization
  const resetSavedNetworkOptimizations = async () => {
    const userid = userData.email; 
    const headers = props.headers;
    const accountCode = userData.accountCode;
    await axios.get(`NetOptz/netmodelstatsgenerator/${accountCode}`, { headers })
    .then(function (response) {
      // handle success
      // console.log("Reset Data: ", response.data );
      setSavedNetOptzModelsData(response.data);
      
      //console.log("Saved NetModel", response.data)
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status != 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }
    })
    .then(function () {
      // always executed
    });
  }
  //!SECTION
  

  {/* Mark a  Network Optimization as saved */}

  //SECTION Axios Save a Network Optimization PUT
  const saveaNetworkOptimization = () => {
    //console.log(netOptzData);
    // console.log(netOptzID);
    const userid = userData.email;
    const market = netOptzData.market;
    const file = netOptzData.file;
    const maxdistance = netOptzData.maxDistanceParma;
    const maxwarehouses = netOptzData.maxWarehousesParma;
    const requestedservicelevel = netOptzData.serviceLevelParma;

    axios.put(`/platformsavenetmodel/${userid}/${netOptzID}`)
    .then(function (response) {
      // handle success
      //("Model Saved Message", response.data)
      setDisableSaveButton(true);
      setSnackOpen(true);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      //!Message bar
      setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
      setMessageBarOpen(true);
      setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
    })
    .then(function () {
      // always executed
    });
  }
  //!SECTION

//SECTION View Saved Network Optimizations


  //SECTION Axios get Saved Network Optimization
  const getSavedNetworkOptimizations = async () => {
    //console.log(headers);
    const userid = userData.email;
    const headers = props.headers;
    const accountCode = userData.accountCode;
    await axios.get(`NetOptz/netmodelstatsgenerator/${accountCode}`, { headers })
    .then(function (response) {
      // handle success
      setSavedNetOptzModelsData(response.data);
      // console.log("Get Data: ", response.data );
      
      //console.log("Saved NetModel", response.data)
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status != 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }
    })
    .then(function () {
      // always executed
    });
  }
  //!SECTION

  const toggleSavedNetOptzModels = () => {
    if (viewSavedNetOptzModels){
      setViewSavedNetOptzModels(false);
      setSavedNetOptzModelsMessage('View Saved Network Optimizations');
    }else{

      getSavedNetworkOptimizations();
      setViewSavedNetOptzModels(true);
      setSavedNetOptzModelsMessage('Hide Saved Network Optimizations');
    };
  };
//!SECTION

  {/* Handler when a Market is selected */}
  const handleMarketFileNames = (fileindex) => {
    // console.log("Selected File index", fileindex);
    // console.log("Selected File", marketFilesData[fileindex-1]);
    setSelectedFile(marketFilesData[fileindex-1])
    toggleSavedMarketFiles();
    setViewParameters(true);
  }

  //SECTION Axios Network Optimizing POST
  const handleOptimizing = async (distance, warehouses, serviceLevel) => {
    setViewParameters(false);
    setViewWaiting(true);
    const userid = userData.email;
    // console.log("Warehouse File: ", warehouseFileName )
    await axios.post(`/platformnetoptimize/${userid}/${accountCode}/${selectedFile.marketName}/${selectedFile.fileName}/${distance}/${warehouses}/${serviceLevel}/${warehouseFileName}`)
    .then(function (response) {
      var tries = 12
      var duration = 7000
      var first_pause = 1000
      //! this delays one second before executing
      setTimeout(() => { 
      getProcessedNetOptzData(tries, duration, accountCode, selectedFile.marketName, selectedFile.fileName, distance, warehouses, serviceLevel, warehouseFileName);
       }, first_pause)
      
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      //!Message bar
      setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
      setMessageBarOpen(true);
      setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      return;
    })
    .then(function () {
      // always executed
    });
  }
  //!SECTION

  {/* Handler for getting a saved network model */}

  const [ netOptzID, setNetOptzID ] = React.useState();
  const [ memberCode, setMemberCode ] = React.useState(userData.membershipCode);

  //SECTION Axios get a saved Network Optimization mode
  const handlegetsavedmodel = ( model ) => {
    model = savedNetOptzModelsData[model[0]-1]
    // const file = model.file;
    const userid = userData.email;
    // const market = model.market;
    // const maxdistance = model.userdistance;
    // const maxwarehouses = model.userwarehouses;
    // const requestedservicelevel = model.userservicelevel;
    const ID = model.netmodelID
    setNetOptzID(ID);
    const headers = props.headers;

    axios.get(`NetOptz/getplatformnetmodel/${ID}`, { headers })
    .then(function (response) {
      // handle success
      // console.log("File", response.data);
      setNetOptzData(response.data);
      setWarehouseDetailTable(response.data.warehouseDetailTable);
      setResultsDistanceInTable(response.data.resultsDistanceInTable);
      setParetoDistanceChart(response.data.paretoDistanceChart);
      setviewResults(true);
      setViewWarehouseDataDisplay(true);
      setViewSavedNetOptzModels(false);
      setModelWarehouseFileName(response.data.warehousefile)
      setSavedNetOptzModelsMessage('View Saved Network Optimizations')
      setDisableSaveButton(response.data.saved)
      setAccountName(response.data.accountName);
      setMemberCode(response.data.membershipCode)
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status != 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }
    })
    .then(function () {
      // always executed
    });
  }
  //!SECTION

  const [ modelWarehouseFileName ,setModelWarehouseFileName ] = React.useState('')
  //SECTION Axios get processing Network Optimization
  const getProcessedNetOptzData = async (tries, duration, accountCode, marketName, filename, distance, warehouses, serviceLevel, warehouseFileName) => {
    const userid = userData.email;
    await axios.get(`/getplatformprocessednetmodel/${userid}/${accountCode}/${marketName}/${filename}/${distance}/${warehouses}/${serviceLevel}/${warehouseFileName}`)
    .then(function (response) {
        // console.log(response.data)
        setNetOptzData(response.data)
        setWarehouseDetailTable(response.data.warehouseDetailTable)
        setViewWaiting(false);
        setviewResults(true);
        setViewWarehouseDataDisplay(true);
        setResultsDistanceInTable(response.data.resultsDistanceInTable);
        setParetoDistanceChart(response.data.paretoDistanceChart);
        setDisableSaveButton(response.data.saved)
        setModelWarehouseFileName(response.data.warehousefile)
        setNetOptzID(response.data.netOptzID)
        
        
        //console.log("Disable", response.data.saved)
      })
      // check every so often
      .catch(function (error) {
        //! THIS NEEDS TO BE CHANGED
        if (tries > 0){
          //console.log("Trying again: ", tries);
          setTimeout(() => { getProcessedNetOptzData(--tries, duration, accountCode, marketName, filename, distance, warehouses, serviceLevel, warehouseFileName); }, duration)
          setProcessingMessageIndex(tries);
          //console.log("Tryed again: ", tries);
        }else{
          //! May want a variable instead of true
          if(true){
            setViewParameters(true);
            setViewWaiting(false);
            setProcessingMessageIndex(5);
            //! Change this
            alert("We are still working on the optimization. It will be available soon on the Dashboard page. In Network Models Pending Review.");

            //history.push('/net-opt-results-page');
          };
        }
      })
        };
  //!SECTION

  //SECTION Select the warehouse File

  const [viewWarehouseSelectMessage, setViewWarehouseSelectMessage] = React.useState("Select Warehouse File");
  const [viewWarehouseSelect, setViewWarehouseSelect] = React.useState(false);


  const toggleWarehouseSelect = () => {
  if (viewWarehouseSelect) {
    setViewWarehouseSelect(false);
    setViewWarehouseSelectMessage("Select Warehouse File");
  } else {
    setViewWarehouseSelect(true);
    setviewResults(false);
    setViewWarehouseSelectMessage("Hide Warehouse Select");
    getWarehouseFileList();
  }
  };

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  //SECTION Axios get warehouse file list
  const getWarehouseFileList = () => {
    const user = userData.email;
    const headers = props.headers;
    console.log(props.headers)
    // console.log(collection)

    axios
      .get(`information/warehousefilelist/${accountCode}` , {headers: headers})
      .then(function (response) {
        setWarehouseFileList(response.data);
  
        
      })
      .catch(function (error) {
        // handle error
        //!Message bar
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })
      
      .then(function () {
        // always executed
      });
    };
    //!SECTION
  
  //! props to send down to the Drop Down Selector Component
  //List to send down. Must have an ID!
  const [warehouseFileList, setWarehouseFileList] = React.useState([
    {
      id: 1,
      label: "Default Warehouses",
      value: "Zip_Metros_Default.csv",
    },
  ]);
  //handler to send down
  const handleSelectedWarehouseFile = async (filename) => {
    setWarehouseFileName(filename); // the component will send back the name of the file chosen
  };
  //Variables to send down
  const [ warehouseFileName, setWarehouseFileName ] = React.useState('default');
  const dropDownTitles = {
    dropDownTitle: "Select A Warehouse File",
    dropDownButtonTitle: "Load Warehouse File",
    dropDownLabel: "Select A Warehouse File",
    dropDownHelperText: "A Default will be use if not selected.",
    dropDownAlertMessage: "Warehouse File is now " ,
  };
  //!SECTION


  // ! Pick an Account drop down
    //! to change by account dropdown
  //SECTION Account Select
  const [ accountName, setAccountName ] = React.useState(userData.company);
  const [ accountCode, setAccountCode ] = React.useState(userData.accountCode);
  const [ viewAccounts, setViewAccounts ] = React.useState(false);
  const [ viewAccountsMessage, setAccountsMessage ] = React.useState('Select Account');
  const toggleAccounts = () => {
    if (viewAccounts) {
      setViewAccounts(false);
      setAccountsMessage("View Accounts");
      // setViewFileDeleteButton(false);
    } else {
      setViewAccounts(true);
      setAccountsMessage("Hide Accounts");
      // getAccountList();
    }
  };
  //handler to send down
  const handleSelectedAccount = async (accountname, accountcode, membershipCode) => {
    setAccountName(accountname);
    setAccountCode(accountcode);
    setMemberCode(membershipCode);
    closeAllWindows();
     // the component will send back the name of the file chosen
  };
  const closeAllWindows = () => {
    //! Close Market Files
    setViewMarketFilesData(false);
    setViewMarketMessage('Start New Market Network Optimization');
    //!Close Saved Network Optimization
    setViewSavedNetOptzModels(false);
      //Close Sub Windows
      setViewParameters(false);
    setSavedNetOptzModelsMessage('View Saved Network Optimizations');
      //Close Sub Windows
      setviewResults(false);

    //!Close Select Warehouse File
    setViewWarehouseSelect(false);
    setViewWarehouseSelectMessage("Select Warehouse File");
  };
  //!SECTION
  

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        {/* Content*/}
        {/* Content*/}
        {/* Content*/}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          {/* <img src={EdonLogo} width={200} alt="Logo" /> */}
          {memberCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
            <Grid item sx={{ p: 2 }}>
              <img src={WALogo} width={200} alt="Logo" />
            </Grid>
          )}
          {memberCode === "0x4ee11bfe" && //Warehouse Anywhere membership
          <Grid item sx={{p:2}}>
              <img src={FlexLogo} width={150} alt="Logo" />
            </Grid>
          }
          {memberCode === "0xd4eed5a7" && //Warehouse Anywhere membership
            <Grid item sx={{pt:2}}>
                <img src={EdonLogo } width={200} alt="Logo" />
              </Grid>
          }
          <Container
            overflow="auto"  
            maxWidth="xl"
            sx={{ mt: 5, mb: 10, border: 1 }}
          >

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
              <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  {accountCode === '0xef4b67067c' ?
                  <img src={MicrosoftLogo}  width={200} alt="Logo" /> :
                  accountCode === ('0xf14195642') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" /> : 
                  accountCode === ('0x6a6d078c6d3') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" />:  
                  <Typography align="center" variant={"h4"}>
                    
                  </Typography>
                  }
                  <Typography align="center" variant={"h4"}>
                    {accountName}
                  </Typography>
                  <Typography align="center" variant={"h5"}>
                    Network Optimization
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {userData.email}
                  </Typography>

                  {/* Unauthorized User */}
                  {!userData.services.includes("NO") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }

              {/* Accounts Drop Down */}
              {userData.accountViewer && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 ,display:"flex", flexDirection:"column"}}
                >

                    <Button
                      onClick={toggleAccounts}
                    >
                      {viewAccountsMessage}
                    </Button>
                    {
                      viewAccounts && (

                     <AccountDropDown 
                      handleSelectedAccount={handleSelectedAccount}
                      toggleAccounts={toggleAccounts}
                      user={userData}
                      headers={props.headers} />
                    
                      ) // send the list of files and a handler down to the dropdown
                    }
                </Grid>
              )}

                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0 }}
            >
              {/* Market Files START NEW OPTIMIZATION*/}
              {userData.userRole > 5 && userData.services.includes("NO") && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <Button onClick={toggleSavedMarketFiles}>
                      {" "}
                      {viewMarketMessage}
                    </Button>
                    
                    {viewMarketFilesData && (
                      <>
                      <Grid item                   
                      xs={12}
                      md={12}
                      lg={12}
                      sx={{ alignContent: "center", border: 0 }}>
                        <Typography align="center" variant={"subtitle2"} >Using the warehouse file: {warehouseFileName}</Typography>
                      </Grid>
                      <MarketFilesGrid
                        user={userData}
                        marketFilesData={marketFilesData}
                        handleMarketFileNames={handleMarketFileNames}
                      />
                      </>
                    )}
                  </Paper>
                </Grid>
              )}

              {viewParameters && (
                <>
                  {selectedFile && (
                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      align={"center"}
                      sx={{ alignContent: "center", border: 0 }}
                    >
                      <Paper
                        sx={{
                          p: 0,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          height: 80,
                        }}
                      >
                        <Typography align="center" variant={"h4"}>
                          {selectedFile.marketName}:{" "}
                          {selectedFile.numberOfOrders.toLocaleString()} Orders
                        </Typography>
                      </Paper>
                    </Grid>
                  )}

                  {/* Parameter Selector*/}
                  {selectedFile && (
                    

                    <Grid
                      item
                      xs={12}
                      md={12}
                      lg={12}
                      align={"center"}
                      sx={{ alignContent: "center", border: 0 }}
                    >
                      <Paper
                        sx={{ p: 2, display: "flex", flexDirection: "column" }}
                      >
                        <Button onClick={toggleSavedMarketFiles}>
                          {" "}
                          Select Parameters{" "}
                        </Button>
                        <Grid item                   
                          xs={12}
                          md={12}
                          lg={12}
                          sx={{ alignContent: "center", border: 0 }}>
                        <Typography align="center" variant={"subtitle2"} >Using the warehouse file: {warehouseFileName}</Typography>
                      </Grid>

                        <ParameterSelector
                          handleOptimizing={handleOptimizing}
                        />
                      </Paper>
                    </Grid>
                  )}
                </>
              )}

              {/* View Saved Net Models */}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Button onClick={toggleSavedNetOptzModels}>
                    {" "}
                    {viewSavedNetOptzModelsMessage}
                  </Button>
                  {viewSavedNetOptzModels && (
                    <SavedNetOptzModels
                      user={userData}
                      savedNetOptzModelsData={savedNetOptzModelsData}
                      getSavedNetworkOptimizations={
                        getSavedNetworkOptimizations
                      }
                      handlegetsavedmodel={handlegetsavedmodel}
                      resetSavedNetworkOptimizations={
                        resetSavedNetworkOptimizations
                      }
                    />
                  )}
                </Paper>
              </Grid>

              {/* Set Warehouse File */}
              {userData.userRole > 5 && userData.services.includes("NO") && (
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Button onClick={toggleWarehouseSelect}>
                    {" "}
                    {viewWarehouseSelectMessage}
                  </Button>
                  {viewWarehouseSelect && (
                    <DropDownSelector
                      itemList={warehouseFileList}
                      handleSelectedItem={handleSelectedWarehouseFile}
                      dropDownTitles={dropDownTitles}
                      toggleClose = {toggleWarehouseSelect}
                    />
                  )}
                </Paper>
              </Grid>
              )}

              {/* Loading Visual */}
              {viewWaiting && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  align={"center"}
                  sx={{ alignContent: "center", border: 0 }}
                >
                  <Paper
                    sx={{ p: 2, display: "flex", flexDirection: "column" }}
                  >
                    <WaitingOnOptimization
                      processingMessageIndex={processingMessageIndex}
                    />
                  </Paper>
                </Grid>
              )}
              {/* Results */}
              {viewResults && (
                <>
                  {/* Current Network Parameters */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    align={"center"}
                    sx={{ border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Typography variant={"h4"}>
                        {netOptzData.market}
                      </Typography>
                      <Typography variant={"h5"}>Demand File: {netOptzData.file}</Typography>
                      <Typography variant={"h5"}>Warehouse File: {modelWarehouseFileName}</Typography>
                      <Typography variant={"h5"}>Account: {accountName}</Typography>
                      <Typography variant={"h5"}>
                        Your Parameters: Distance {netOptzData.maxDistanceParma}
                        , Warehouses {netOptzData.maxWarehousesParma}, Service
                        Level {netOptzData.serviceLevelParma * 100}%{" "}
                      </Typography>
                    </Paper>
                  </Grid>
                  {/* High Level Stat Results */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    align={"center"}
                    sx={{ border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Typography variant={"h4"}>
                        Network Optimization Results{" "}
                      </Typography>
                      <Typography variant={"h5"}>
                        Warehouses: {netOptzData.numOfWarehouses}{" "}
                      </Typography>
                      <Typography variant={"h5"}>
                        Average Distance: {netOptzData.avgDistance}{" "}
                      </Typography>
                      <Typography variant={"h5"}>
                        Service Level {netOptzData.serviceLevelResult}{" "}
                      </Typography>
                    </Paper>
                  </Grid>

                  {/* Save Button */}
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    align={"center"}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper sx={{ p: 2 }}>
                      <Button
                        disabled={disableSaveButton} //! this needs to be fixed
                        // disabled={true}
                        onClick={saveaNetworkOptimization}
                        sx={{
                          backgroundColor: "#68a0d7",
                          color: "white",
                          size: "large",
                          "&:hover": { backgroundColor: "#BA5F8e" },
                        }}
                        variant="contained"
                      >
                        Publish Optimization
                      </Button>
                      <Snackbar
                        anchorOrigin={{ horizontal: "center", vertical: "top" }}
                        open={snackOpen}
                        autoHideDuration={3000}
                        onClose={handleClose}
                        message="Model Saved"
                      >
                        <MuiAlert
                          elevation={6}
                          variant="filled"
                          severity="success"
                        >
                          {"Network Optimization Saved"}
                        </MuiAlert>
                      </Snackbar>
                    </Paper>
                  </Grid>

                  {viewWarehouseDataDisplay && (
                    <Grid item xs={12} align={"center"}>
                      <Paper
                        sx={{ p: 2, display: "flex", flexDirection: "column" }}
                      >
                        <Typography variant="h4" sx={{ p: 2, border: 0 }}>
                          {netOptzData.market} Warehouse Map
                        </Typography>
                        <WarehouseMap netOptzData={netOptzData} />
                      </Paper>
                    </Grid>
                  )}

                  {viewWarehouseDataDisplay && (
                    <Grid item xs={12}>
                      <Paper
                        sx={{ p: 2, display: "flex", flexDirection: "column" }}
                      >
                        <Typography variant="h4" sx={{ p: 2, border: 0 }}>
                          {netOptzData.market} Warehouse Details
                        </Typography>
                        <WarehouseDataDisplay
                          warehouseDetailTable={warehouseDetailTable}
                        />
                      </Paper>
                    </Grid>
                  )}

                  {viewWarehouseDataDisplay && (
                    <>
                      <Grid item xs={12} md={6} lg={6}>
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h4" sx={{ p: 2, border: 0 }}>
                            Distance Chart
                          </Typography>
                          <ParetoDistanceChart
                            netOptzData={netOptzData}
                            paretoDistanceChart={paretoDistanceChart}
                          />
                        </Paper>
                      </Grid>

                      <Grid item xs={12} md={6} lg={6}>
                        <Paper
                          sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Typography variant="h4" sx={{ p: 2, border: 0 }}>
                            Distance Table
                          </Typography>
                          <ParetoDistanceTable
                            resultsDistanceInTable={resultsDistanceInTable}
                          />
                        </Paper>
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>

            <Grid item border={0} xs={12} sm={12} md={12}>
              <Copyright sx={{ pt: 4, align: "center" }} />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};