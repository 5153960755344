import * as React from 'react';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

// function preventDefault(event) {
//   event.preventDefault();
// }




export default function InventoryOptzServiceLevel(props) {
  
  


  return (
    <React.Fragment>
        <Grid minHeight="50" container>

          <Grid item xs={12} md={12} lg={12}>
            <Title>Service Level</Title>
            <Divider />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>

            <Typography color="text.secondary" sx={{pt:2 }}>
            Same Day:
            </Typography>

            <Typography component="p" variant="h5" sx={{pt:0 }}>
            {(props.sameDayServiceLevel * 100) + "%"}
            </Typography>
            <Divider sx={{pt:1}}/>
          </Grid>

          <Grid item xs={12} md={12} lg={12}>

            <Typography color="text.secondary" sx={{pt:2 }}>
            Two Hour:        
            </Typography>

            <Typography component="p" variant="h5" sx={{pt:0 }}>
              {((props.twoHourServiceLevel * 100).toFixed(0)) + "%"}
            </Typography>

          </Grid>

        </Grid>
            <Divider sx={{pt:2}}/>

    </React.Fragment>
  );
}