import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { dataGridStyles } from './dataGridStyles';

import { 
  DataGridPro,
  GridToolbar,
  GridActionsCellItem } from '@mui/x-data-grid-pro';



export default function CurrentMarketsTable(props) {
    const contextUserData = useContext(UserContext)
    const [userData, setUserData] = React.useState(() => {
      const storedUserData = localStorage.getItem('userData');
      return storedUserData ? JSON.parse(storedUserData) : contextUserData;
    });
    const visibleColumns = {
      id: false,
      userCode: false,
    }
    const nonAdminUser = userData.userRole !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentMarkets  = {
      columns: [
      {field: 'id', hide: true},
      // {field: 'accountName', headerName: 'Account Name', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'marketName', headerName: 'Market Name', width:200, headerAlign: 'center', align: 'center',  editable: false, },
      {field: 'regionName', headerName: 'Region', width:200, headerAlign: 'center', align: 'center', editable: false},
      {field: 'countryName', headerName: 'Country', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'status', headerName: 'Status', width:125, headerAlign: 'center', align: 'center', editable: false},
    //   {field: 'actions', type: 'actions', headerName: 'Delete User', width:150 ,  
    //   getActions: (params) => [
    //     <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteMobileUser(params.id)}  label="Delete" />
    //   ]},

      ], 
      rows: props.currentMarketsDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 
          sx={dataGridStyles.root}
          pagination

          initialState={{
            pageSize: 10,
            columns: {
              columnVisibilityModel: visibleColumns,
            },
            sorting: {
              sortModel: [{ field: "regionName" }],
            },
          }}
          slots={{toolbar: GridToolbar}}

      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentMarkets} 

      />
    </div>

  );
};