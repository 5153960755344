import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { dataGridStyles } from './dataGridStyles';

import { 
  DataGridPro,
  GridToolbar,
   } from '@mui/x-data-grid-pro';


export default function SimpleGrid(props) {

  //if no props filter model is passed, set it to empty
  const [selectedModel, setSelectedModel ] = React.useState();
  const  GridData  = {
    columns: props.gridColumns, 
    rows: props.gridData
    };

  return (

    <div style={{ height: 400, width: '100%'}}>
      <DataGridPro

        sx={dataGridStyles.root}
        pagination
        initialState={{
          pageSize: 10,
          columns: {
            columnVisibilityModel: props.visibleColumns,
          },
          sorting: {
            sortModel: [ props.sortModel ],
          },
          pinnedColumns: {left: props.pinnedColumns},
        }}
        slots={{toolbar: GridToolbar}}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        // props.handleMarketFileNames(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...GridData} 

      />
    </div>

  );
};