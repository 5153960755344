import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { dataGridStyles } from './dataGridStyles';
import { 
  DataGridPro,
  GridToolbar,
  GridActionsCellItem } from '@mui/x-data-grid-pro';



import DeleteIcon from '@mui/icons-material/Delete';


export default function MobileUserTable(props) {
  // !new code
  // const userData = useContext(UserContext)

  const visibleColumns = {
    id: false,
    userCode: false,
  }


    const [selectedModel, setSelectedModel ] = React.useState();
    const  MobileUserStats  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'user', headerName: 'Email', width:275, headerAlign: 'center', align: 'center',  editable: false},
      // {field: 'accountName', headerName: 'Company Name', width:200, headerAlign: 'center', align: 'center',  editable: false, hide: nonAdminUser},
      {field: 'userFirstName', headerName: 'First Name', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'userLastName', headerName: 'Last Name', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'manager', headerName: 'Manager', width:275, headerAlign: 'center', align: 'center', editable: false},
      {field: 'markets', headerName: 'Markets', width:125, headerAlign: 'center', align: 'center', editable: false},
      // {field: 'warehouseAccess', headerName: 'Locations', width:125, headerAlign: 'center', align: 'center', editable: false},
      {field: 'lastLogin', headerName: 'Last Log In', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'lastActivity', headerName: 'Last Activity', width:200,  headerAlign: 'center', align: 'center',  editable: false},
      {field: 'createdDate', headerName: 'User Create Date', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'createdBy', headerName: 'Created By', width:275, headerAlign: 'center', align: 'center',  editable: false},
      // {field: 'membershipCode', headerName: 'Membership Code',  width:150, editable: false, headerAlign: 'center',  align: 'center', hide:nonAdminUser},
      {field: 'actions', type: 'actions', headerName: 'Delete User', width:150 ,  
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteMobileUser(params.id)}  label="Delete" />
      ]},

      ], 
      rows: props.mobileUsersDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 
          sx={dataGridStyles.root}
          pagination
          initialState={{
            pageSize: 10,
            columns: {
              columnVisibilityModel: visibleColumns,
            },

            // filter: {
            //   filterModel: {
            //     items: [{ field: 'user', operator: '=', value: 10}],
            //   },
            // },
            // sorting: {
            //   // sortModel: [{ field: 'desk', sort: 'asc' }],
            // },
            pinnedColumns: {left: ['user']},


           }}
          slots={{toolbar: GridToolbar}}
        
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedModel(newSelectionModel)
        }}
        selectedModel={selectedModel}
        {...MobileUserStats} 

      />
    </div>

  );
};