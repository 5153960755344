import React from 'react';


import { XAxis, YAxis, LineChart, Line, Tooltip, Legend, ResponsiveContainer } from 'recharts';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);


export default function Linechart(props) {


    return (
        <div style={{ height: 400, width: '100%' }}>
            <ResponsiveContainer height="80%">
            <LineChart width={730} height={250} data={props.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>

                <XAxis dataKey="Period"stroke="#fff"  />
                <YAxis stroke="#fff" />
                <Tooltip />
                <Legend />
                <Line type="linear" dataKey="Quantity" stroke="#68a0d7" strokeWidth={3} />
                <Line type="linear" dataKey="Quantity Forecast" stroke="#d76ad7" strokeWidth={2} strokeDasharray="5 5" />
                {/* <Line type="monotone" dataKey="Sales Moving Average" stroke="#68a0d7" strokeWidth={2} strokeDasharray="5 10" /> */}


                </LineChart>

              </ResponsiveContainer>


            </div>


    )
}