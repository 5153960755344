import * as React from 'react';
import axios from 'axios';

import { DataGrid, GridToolbar, GridActionsCellItem  } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import MessageSnackBar from './MessageSnackBar';

import { useContext } from 'react'; 
import { UserContext } from '../context/UserContext.js';


const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function MarketFilesGrid(props) {

  const userData = useContext(UserContext);

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION
    
    const [selectedModel, setSelectedModel ] = React.useState();
  //SECTION Axios Delete Market File
    const deleteMarketFile = React.useCallback( (sentID, userData) => async () => {
      const user = userData.email
      const index = props.marketFilesData.map(file => file.id).indexOf(sentID)
      // console.log(props.marketFilesData)
      // console.log("user: ", user)
      // console.log("id: ", sentID)
      // console.log("index: ", index)
      const market =  props.marketFilesData[index].marketName
      const filename =  props.marketFilesData[index].fileName
      // console.log("market Name: ", market)

      await axios.delete(`/deletemarketfile/${filename}/${user}/${market}`)
      .then(function (response) {
        // handle success
        alert(response.data)
      })
      .catch(function (error) {
        // handle error
        // console.log(error);
        //!Message bar
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })
      .then(function () {
        // always executed
      });

    },
    [props], );
    //!SECTION



    const  MarketFileNames  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'memberCompany', headerName: 'Member Company', hide:(userData.userRole<10), width:200, editable: false},
      {field: 'accountCompany', headerName: 'Account Company', hide:(userData.userRole<8),width:200, editable: false},
      {field: 'fileName', headerName: 'File', width: 300, editable: false},
      {field: 'marketName', headerName: 'Market', width: 300, editable: false},
      {field: 'numberOfOrders', headerName: 'Number of Orders', width: 150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'numberOfZips', headerName: 'Number of Zips', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'marketPopulation', headerName: 'Market Population', width:150, editable: false, headerAlign: 'center', align:'center', type: 'number'},
      {field: 'user', headerName: 'User', width: 300, editable: false},
      {field: 'actions', type: 'actions', headerName: 'Delete Market', width:150 ,  
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={deleteMarketFile(params.id)}  label="Delete" />
      ]},
      ], 
      rows: props.marketFilesData
      };

  return (

    <div style={{ height: 400, width: '100%' }}>
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
      <DataGrid 
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        props.handleMarketFileNames(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...MarketFileNames} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};