import * as React from "react";
// import axios from "axios";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Snackbar from '@mui/material/Snackbar'
// import MuiAlert from '@mui/material/Alert';
// import MessageSnackBar from './MessageSnackBar';

// import ItemAssociationReceipt from './ItemAssociationReceipt'
// import InboundShipments from './InboundShipments.js';
// import CustomerOrder from './CustomerOrder.js'
// import CustomerOrderDetail from "./CustomerOrderDetail.js"
// import { Typography } from '@mui/material';
// import ATLSaltBox from '../img/ATLSaltbox.png'

import ViewReplenishmentOrdersGrid from "./ViewReplenishmentOrdersGrid.js";
import ViewReplenishmentDetailGrid from "./ViewReplenishmentDetailGrid.js";

// const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });

export default function ViewReplenishmentOrders(props) {
  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "left",
        alignContent: "center",
        p: 1,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <Grid
        item
        xs={8}
        md={8}
        lg={8}
        sx={{ alignContent: "center", border: 0, p: 1 }}
      >
        <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
          <ViewReplenishmentOrdersGrid
            gridData={props.replenishmentOrdersData}
            handleSelectReplenishmentOrder={
              props.handleSelectReplenishmentOrder
            }
          />
        </Paper>
      </Grid>

      {true && (
        <>
          <Grid
            item
            xs={4}
            md={4}
            lg={4}
            sx={{ alignContent: "center", border: 0, p: 1 }}
          >
            <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
              <ViewReplenishmentDetailGrid
                gridData={props.replenishmentOrderDetail}
              />
            </Paper>
          </Grid>
        </>
      )}
    </Grid>
  );
}
