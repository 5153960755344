import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';

import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import EdonLogo from './edonLogoWhite.png'
import Button from '@mui/material/Button';
import axios from 'axios';
import Snackbar from '@mui/material/Snackbar'


import NetworkOptimizationsGrid from './Components/NetworkOptimizationsGrid.js';
import InventoryOptimizationsSaved from './Components/InventoryOptimizationsSaved.js';
import MarketSummary from './Components/MarketSummary.js'
import EchelonCostSummary from './Components/EchelonCostSummary.js'
import EchelonItemSummary from './Components/EchelonItemSummary.js'
//import EchelonItemTypeSummary from './Components/EchelonItemTypeSummary.js'
import InventoryDetail from './Components/InventoryDetailGrid.js';
import InventoryOptzServiceLevel from './Components/InventoryOptzServiceLevel.js';
import MuiAlert from '@mui/material/Alert';
import EchelonInputGrid from './Components/EchelonInputGrid.js';
import EchelonGridExplanations from './Components/EchelonGridExplanations.js';
import Copyright from "./Components/Copyright.js";
import HeaderMenus from "./Components/HeaderMenus.js";
import WALogo from './WA_logo.png'
import FlexLogo from './flexe_Logo.png';
import MicrosoftLogo from './MicrosoftLogo.jpeg'
import AcmeLogo from './img/acme.jpeg';
import AccountDropDown from './Components/AccountDropDown.js'
import MessageSnackBar from './Components/MessageSnackBar';
import Unauthorized from './Components/Unauthorized';



// const mdTheme = createTheme();

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});




export default function Inventory(props) {
  const userData = useContext(UserContext);
  const pageTitle = "Inventory Optimization"
  const [ buttonTitle, setButtonTitle ] = React.useState('Start a Inventory Optimization');
  const [ viewNetOptz, setViewNetOptz ] = React.useState(false);
  const [ setViewSavedInvOptz ] = React.useState(false);
  const [netModels, setNetModels ] = React.useState([]);

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION


  //SECTION Axios get Network Optimization Stats
  const getStatsTable = async () => {
    const accountCode = userData.accountCode;
    const headers = props.headers;

    axios.get(`NetOptz/netmodelstatsgenerator/${accountCode}`, {headers})
    .then(function (response) {
      // handle success
      setNetModels(response.data);
    
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status !== 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }
      
    })
    .then(function () {
      // always executed
    });
  };
  //!SECTION
  
    {/* //& Select Network Optimizations */}

    {/* //$ Holds the selected Inventory Optimization into state*/}
  const [ selectedModel, setSelectedModel ] = React.useState([]);
    
    {/* $ Load the warehouse count of the selected  Inventory Optimization into state
     This will present the title of the Network Optimization*/}
  const [ warehouseCount, setWarehouseCount ] = React.useState();
    
    //!   Enable the  Network Optimization button
    //! This is sent down to the EchelonInputGrid Container
  const [ optimizeButtonDisable, setOptimizeButtonDisable ] = React.useState(true)
  
  //$ Handler sent to NetworkOptimization Component
  const handleSelectedNetModel = (modelindex) => {
    const model = netModels.filter(function(item) {return item.id == modelindex});
    // console.log("model", model)
    // console.log("ID",model[0].netmodelID)

    const newModel = [];
    newModel.push(model[0].market);
    newModel.push(model[0].userdistance);
    newModel.push(model[0].userwarehouses);
    newModel.push(model[0].userservicelevel);
    newModel.push(model[0].file);
    setCurrentNetModelID(model[0].netmodelID) //! set netModel ID to search for saved Inventory models
    setSelectedModel(newModel);
    setWarehouseCount(model[0].statwarehousecount);
    setOptimizeButtonDisable(false);
    toggleNetOptz(); //! This will get the data
    setMarketSummary([0,0]);
    setViewSavedInvOptz(true);
  };
  
  //SECTION Echelon Policy Grid Instructions
  const [ viewEchelonInstructions, setViewEchelonInstructions ] = React.useState(false);
  const [ echelonButtonTitle, setEchelonButtonTitle ] = React.useState("Echelon Policy Grid Instructions");

  const toggleEchelonInstructions = () => {
    if (viewEchelonInstructions){
      setViewEchelonInstructions(false);
      setEchelonButtonTitle("Echelon Policy Grid Instructions");
    
    }else{
      setViewEchelonInstructions(true);
      setEchelonButtonTitle("Hide Echelon Policy Grid Instructions");
    }
  };
//!SECTION


//SECTION Start a New Inventory Market Inventory Optimization
  //! Closes the Network Optimization Table 
  const toggleNetOptz = () => {
    if (viewNetOptz){
      setViewNetOptz(false);
      setButtonTitle("Start a new Market Inventory Optimization");
    
    }else{
      setViewNetOptz(true);
      setButtonTitle("Hide Network Optimizations")
      getStatsTable(); //! Go get the data
      //! close everything
      setViewMarketSummary(false);
      setViewSavedInvOptz(false);
      setSavedViewInvOptz(false);
      setSavedButtonTitle("Review Saved Inventory Optimization");
      setWarehouseCount();
      
    }
  };
//!SECTION

    const [ marketSummary, setMarketSummary ] = React.useState([-1,-1]);
    const [ costByEchelon, setCostByEchelon ] = React.useState([0,0,0]);
    const [ quantityByEchelon, setQuantityByEchelon ] = React.useState([0,0,0]);
    //const [ itemCountByEchelon, setItemCountByEchelon ] = React.useState([0,0,0]);
    const [ viewMarketSummary, setViewMarketSummary ] = React.useState(false)
    //! This holds the selected service level info for inventory Optimization
    const [ twoHourServiceLevel, setTwoHourServiceLevel ] = React.useState();
    const [ sameDayServiceLevel, setSameDayServiceLevel ] = React.useState();
    

    const [ saveButtonDisable, setSaveButtonDisable ] = React.useState(true);
    

    const [ currentNetModelID, setCurrentNetModelID ] = React.useState([]);  //! Why is this a list?
    const [ currentInvModelID, setCurrentInvModelID ] = React.useState();
    


    // const toggleViewMarketSummary = () => {

    // };

  const handleMarketSummary = (data) => {    
    var marketData = [];
    marketData.push(data.marketTotalCosts)
    marketData.push(data.marketTotalQuantity)
    setMarketSummary(marketData);
    setCostByEchelon(data.costByMarket);
    setQuantityByEchelon(data.quantityByMarket);
    //setItemCountByEchelon(data.itemTypeCountByEchelon);
    setSaveButtonDisable(data.saved);
    setCurrentInvModelID(data.invOptID); //I might not need this
    setCurrentNetModelID(data.netOptID);
    setTwoHourServiceLevel(data.twoHourServiceLevel)
    setSameDayServiceLevel(data.sameDayServiceLevel)
    setViewMarketSummary(true);
    
  };
  

    //& Load Inventory Detail Information  
    //& This is sent down to the Echelon Grid  */}  
    //$ This holds the inventory detail for the inventory optimization*/}
    const [ inventoryDetail, setInventoryDetail ] = React.useState([]);
    
    //$ handles the inventory detail info coming from the API 
  const handleInventoryDetail = (data) => {
    setInventoryDetail(data);
    // console.log("Inventory Detail:", data)
    // console.log("Inventory Type:", typeof data)
  };


  //!  View Network Optimization Detail 
    //!  Open Inventory Optimization Detail 
    
      // controls the view and Title of Inventory Optimization Button
  const [ viewDetail, setViewDetail ] = React.useState(false);
  const [ invButtonTitle, setInvButtonTitle ] = React.useState('View Inventory Detail');

      //! Detail is loaded in the Inventory Detail Component
  const toggleDetail = () => {
    if (viewDetail){
      setViewDetail(false);
      setInvButtonTitle("View Inventory Detail");
    }else{
      setViewDetail(true);
      setInvButtonTitle("Hide Inventory Detail")
    }};



  // !Save Inventory Optimization  
  // !Save Inventory Optimization  

  // !Send save request to API and set messages when completed
  const [ saveMessage, setSaveMessage ] = React.useState('');
  const [ snackbarOpen, setSnackbarOpen ] = React.useState(false)
  //SECTION Axios Save A Inventory Optimization Model
  const handleSaveInvOpt = async () => {
    axios.put(`/saveinventorymodel/${userData.email}/${currentInvModelID}`)
    .then(function (response) {
      //console.log("Saved Message:",response.data )
      setSaveButtonDisable(true);
      setSaveMessage(response.data)
      setSnackbarOpen(true);
    })
    .catch(function (error) {
      // handle error
      console.log(error);
      //!Message bar
      setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
      setMessageBarOpen(true);
      setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
    })
    .then(function () {
      // always executed
    });
  };
  //!SECTION


    //! Snackbar notifications that the inventory optimizaation is saved
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbarOpen(false);
    };

 
//SECTION Review Saved Inventory Optimization
  const [ savedViewInvOptz, setSavedViewInvOptz ] = React.useState(false);
  const [ savedButtonTitle, setSavedButtonTitle ] = React.useState("Review Saved Inventory Optimizations");
  const [ savedInventoryOptimizations, setSavedInventoryOptimizations ] = React.useState([]);
  
  //SECTION Axios Get A saved Inventory Optimization 
  const getSavedInventoryOptimizations = async () => {
    const headers = props.headers;
    const accountCode = userData.accountCode;
    await axios.get(`InvOptz/savedinventoryoptimizations/${accountCode}`, {headers})
    .then(function (response) {
      // handle success
      setSavedInventoryOptimizations(response.data)
    })
    .catch(function (error) {
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status != 401) {
        setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }
    })
    .then(function () {
      // always executed
    });
  };
  //!SECTION
  
  
  // !Toggle the view and title if open get and display the data 
  const toggleSavedInvOptz = () => {
  if (savedViewInvOptz){
    setSavedViewInvOptz(false);
    setSavedButtonTitle("Review Saved Inventory Optimization");
  }else{
    getSavedInventoryOptimizations(); //! Go get the data
    setSavedViewInvOptz(true);
    setSavedButtonTitle("Hide Saved Inventory Optimizations");
  }
  };
//!SECTION



//SECTION Account Select
    const [ accountName, setAccountName ] = React.useState(userData.userCompany);
    const [ accountCode, setAccountCode ] = React.useState(userData.accountCode);
    const [ memberCode, setMemberCode ] = React.useState(userData.membershipCode);
    const [ viewAccounts, setViewAccounts ] = React.useState(false);
    const [ viewAccountsMessage, setAccountsMessage ] = React.useState('Select Account');
    const toggleAccounts = () => {
      if (viewAccounts) {
        setViewAccounts(false);
        setAccountsMessage("View Accounts");
        // setViewFileDeleteButton(false);
      } else {
        setViewAccounts(true);
        setAccountsMessage("Hide Accounts");
        // getAccountList();
      }
    };
    //handler to send down
    const handleSelectedAccount = async (accountname, accountcode, membershipCode) => {
      setAccountName(accountname);
      setAccountCode(accountcode);
      setMemberCode(membershipCode);
      closeAllWindows();
       // the component will send back the name of the file chosen
    };

    const closeAllWindows = () => {
      //! Close Saved Inventory Optimizations
      setSavedViewInvOptz(false);
      setSavedButtonTitle("Review Saved Inventory Optimization");
        //Close Sub Windows
        setViewMarketSummary(false);
        setViewSavedInvOptz(false);
        setSavedViewInvOptz(false);
        setSavedButtonTitle("Review Saved Inventory Optimization");
      //!Close Start a Inventory Optimization
      setViewNetOptz(false);
      setButtonTitle("Start a new Market Inventory Optimization");
      //!Close Echelon Policy Grid
      setViewEchelonInstructions(false);
      setEchelonButtonTitle("Echelon Policy Grid Instructions");
      //!Disable Optimize Button
      setOptimizeButtonDisable(true);
    };
//!SECTION



  return (
    <ThemeProvider theme={darkTheme}>

      <Box sx={{ display: 'flex' }}>

        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar /> 




          <Container overflow="auto"  //! Changed from scroll to auto  
          maxWidth="xl"
          sx={{alignContent: 'center',  border:0 }}>



          {memberCode === "0x12a6b5324" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            }
          {memberCode === "0x4ee11bfe" && //Warehouse Anywhere membership
            <Grid item sx={{p:2}}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            }
          {memberCode === "0xd4eed5a7" && //Warehouse Anywhere membership
            <Grid item sx={{pt:2}}>
                <img src={EdonLogo } width={200} alt="Logo" />
              </Grid>
          }

          <Grid container spacing={3} sx={{alignContent: 'center', border:0, pb:2}} >
            <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
              <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 } }>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', border:0 }}>
                {accountCode === '0xef4b67067c' ?
                  <img src={MicrosoftLogo}  width={200} alt="Logo" /> :
                  accountCode === ('0xf14195642') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" /> : 
                  accountCode === ('0x6a6d078c6d3') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" />:  
                  <Typography align="center" variant={"h4"}>
                    
                  </Typography>
                  }
                  <Typography align="center" variant={"h4"}>
                    {accountName}
                  </Typography>
                    <Typography align='center' variant={'h5'}>Inventory Optimization</Typography>
                    <Typography align='center' variant={'subtitle1'}>{userData.email}</Typography>

                  {/* Unauthorized User */}
                  {!userData.services.includes("IO") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }

              {/* Accounts Drop Down */}
              {userData.accountViewer && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 ,display:"flex", flexDirection:"column"}}
                >
                    <Button
                      onClick={toggleAccounts}
                    >
                      {viewAccountsMessage}
                    </Button>
                    {
                      viewAccounts && (

                     <AccountDropDown 
                      handleSelectedAccount={handleSelectedAccount}
                      toggleAccounts={toggleAccounts}
                      user={userData}
                      headers={props.headers} />
                    
                      ) // send the list of files and a handler down to the dropdown
                    }
                </Grid>
              )}                 
                
                </Paper>
              </Grid>
          </Grid>


            {/* Market Title and Warehouse Count */}
          <Grid container spacing={3} sx={{alignContent: 'center', border:0 }} >
              {
              warehouseCount &&
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    height: 80,
                  }}
                >
                  <Typography align='center' variant={'h4'}>{selectedModel[0]}: {warehouseCount} Warehouses</Typography>
                  <Typography align='center' variant={'h5'}>File: {selectedModel[4]}</Typography>
                </Paper>
                
              </Grid>
              }


          {
            (viewMarketSummary) &&
            <>

              {/* Save This Inventory Optimization */}
              <Grid item align="center" xs={12} md={12} lg={12}>
                <Button disabled={saveButtonDisable} onClick={handleSaveInvOpt} sx={{backgroundColor:"#68a0d7", color: "white", size: "large",
                    '&:hover': {backgroundColor: "#BA5F8e"}}} variant="contained" >Publish This Inventory Optimization</Button>
                <Snackbar
                  anchorOrigin = {{ horizontal: 'center', vertical: 'top'}}
                  open={snackbarOpen}
                  autoHideDuration={3000}
                  onClose={handleClose}
                  message={saveMessage}

                >
                    <MuiAlert elevation={6} variant="filled" severity="success">{saveMessage}</MuiAlert>
                  </Snackbar>
              </Grid> 



              <Grid item xs={3} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <MarketSummary marketSummary={marketSummary} />
                </Paper>
              </Grid>


              <Grid item xs={3} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <InventoryOptzServiceLevel twoHourServiceLevel={twoHourServiceLevel} sameDayServiceLevel={sameDayServiceLevel} />
                </Paper>
              </Grid>

              
              {/* Echelon Cost Summary */}
              <Grid item xs={3} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <EchelonCostSummary costByEchelon={costByEchelon} />
                </Paper>
              </Grid>

              {/* Echelon Item Quantity Summary */}
              <Grid item xs={3} md={3} lg={3}>
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    height: 240,
                  }}
                >
                  <EchelonItemSummary quantityByEchelon={quantityByEchelon} />
                </Paper>
              </Grid>



              {/* View Inventory Optimization Detail  */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                  <Button onClick={toggleDetail}>{invButtonTitle}</Button>
                  {viewDetail &&
                    <InventoryDetail inventoryDetail={inventoryDetail}  />
                  }
                </Paper>
              </Grid> 
              



              </>
              }

                {/* View Saved Inventory Optimizations  */}
              
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleSavedInvOptz}>{savedButtonTitle}</Button>
                  {savedViewInvOptz &&
                    <InventoryOptimizationsSaved user={userData} headers={props.headers} handleInventoryDetail={handleInventoryDetail} handleMarketSummary={handleMarketSummary} savedInventoryOptimizations={savedInventoryOptimizations} toggleSavedInvOptz={toggleSavedInvOptz} />
                  }
                </Paper>
              </Grid>
              


              {/* Network Optimizations  */}
                {userData.userRole > 5 && userData.services.includes("IO") &&
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleNetOptz}>{buttonTitle}</Button>
                  {viewNetOptz &&
                    <NetworkOptimizationsGrid user={userData} netModels={netModels} handleSelectedNetModel={handleSelectedNetModel} />
                  }
                </Paper>
              </Grid>
              }

              {/* Echelon Policy Grid Explanation  */}

            {userData.userRole > 5 &&
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                  <Button onClick={toggleEchelonInstructions}>{echelonButtonTitle}</Button>
                  {viewEchelonInstructions &&
                    <EchelonGridExplanations />
                  }
                </Paper>
              </Grid>
            }



              {/* EchelonInputGrid */}
              {userData.userRole > 5 &&
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>

                  <Grid item xs={12} sm={12}  sx={{textAlign:'center', ml:0, border:0, mb:3}}>
                    <Typography sx={{mb:2}} variant={'h2'}>Echelon Policy Grid</Typography>
                    <Divider/>
                  </Grid>

                  <EchelonInputGrid  user={userData} handleInventoryDetail ={handleInventoryDetail} optimizeButtonDisable={optimizeButtonDisable} currentNetModelID={currentNetModelID} selectedModel={selectedModel} handleMarketSummary={handleMarketSummary} accountCode={accountCode} />
                </Paper>
              </Grid>
              }

            </Grid>




            <Copyright sx={{ pt: 4 }} />
          </Container>


          
        </Box>
      </Box>
    </ThemeProvider>
  );
}

