import React from 'react';


import {  XAxis, YAxis, LineChart, Line, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);


export default function Linechart(props) {


    return (
        <div style={{ height: 400, width: '100%' }}>
            <ResponsiveContainer height="80%">
            <LineChart width={730} height={250} data={props.data}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                <CartesianGrid strokeDasharray="1 1" />
                <XAxis dataKey="name"stroke="#fff" />
                <YAxis stroke="#fff"  />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="value" strokeWidth={3} stroke="#8884d8" />

                </LineChart>

              </ResponsiveContainer>


            </div>


    )
}