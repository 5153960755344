import * as React from "react";
import axios from "axios";
import Button from "@mui/material/Button";

// import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
import Paper from "@mui/material/Paper";

// import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";

// import Typography from '@mui/material/Typography';
// import MenuItem from '@mui/material//MenuItem';

// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Snackbar from '@mui/material/Snackbar'
// import MuiAlert from '@mui/material/Alert';
// import MessageSnackBar from './MessageSnackBar';

import ReplenishmentProductInfo from "./ReplenishmentProductInfo";
import ReplenishmentShipmentInfo from "./ReplenishmentShipmentInfo";
import ReplenishmentOrderInfo from "./ReplenishmentOrderInfo";

// const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });

export default function CreateReplenishmentShipment(props) {
  //! Local SnackBar Messaging
  //SECTION Message Bar Controls
  // const [snackBarMessage, setSnackBarMessage] = React.useState("");
  // const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  // const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSnackbarOpen(false);
  // };

  // const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  // const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  // const handleMessageBarClosing = () => {
  //   setMessageBarOpen(false);
  // };

  //!SECTION

  // const [buttonDisable, setButtonDisable] = React.useState(true);

  const [showShipButton, setShowShipButton] = React.useState(false);
  const [selectedReplenishmentOrder, setSelectedReplenishmentOrder] =
    React.useState("");
  const [productToShip, setProductToShip] = React.useState(false);
  const [validTrackingNumber, setValidTrackingNumber] = React.useState(false);
  const [validCarrier, setValidCarrier] = React.useState(false);

  React.useEffect(() => {
    if (productToShip && validTrackingNumber && validCarrier) {
      setShowShipButton(true);
    } else {
      setShowShipButton(false);
    }
  }, [productToShip, validTrackingNumber, validCarrier]);
  //!SECTION

  // SECTION Replenishment Order
  //NOTE Need to get this from the data base
  const [replenishmentOrder, setReplenishmentOrder] = React.useState([]);

  const handleReplenishmentOrderSelected = (replenishmentOrderId) => {
    setSelectedReplenishmentOrder(replenishmentOrderId);
    // NOTE need to get the replenishment order selected
    // console.log(replenishmentOrderId);
    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";

    axios
      .get(`/iot/replenishmentOrder/${owner}/${user}/${replenishmentOrderId}`)
      .then(function async(response) {
        // handle success
        // setAccessGridData(response.data);
        // console.log("replenishment orders:", response.data);
        // console.log("replenishment orders:", response.data.data);
        setReplenishmentOrder(response.data.data);
        // toggleAccessTable();
        // setDisableAccessButton(true);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
        //!Message bar
        //!Message bar
        var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
        props.handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
      })
      .then(function () {
        // always executed
      });
  };

  //NOTE Need a Product Delete function

  const [shipmentProducts, setShipmentProducts] = React.useState("");

  // ! product added to shipment
  const handleReplenishmentOrderProductAdd = (addProduct) => {
    setProductToShip(true); // at least one product to show ship button
    //! add product to the shipment
    setShipmentProducts((prevState) => [...prevState, addProduct]);
    //! copy added product id into a array varable
    var productId = addProduct.productId;
    var productAdd = replenishmentOrder.find((x) => x.productId === productId);
    // console.log("Product that was added", productAdd)
    //! copy all the products to variable
    var currentOrder = replenishmentOrder;
    // console.log("copied order", currentOrder)
    //! reduce the qty on the order of the product by 1
    var quantity = productAdd.qty - 1;
    //!if the qty is 0 (all has been ordered) change the status of the product in the replenishment order
    if (quantity === 0) {
      var newArr = currentOrder.map((obj) => {
        if (obj.productId === productId) {
          return { ...obj, status: "shipping" };
        }
        return obj;
      });
    }
    // ! take the current item ordering to the current order 
    currentOrder = currentOrder.filter((object) => {
      return object.productId !== productId;
    });
    // ! add the item and the status to the current order
    currentOrder.push(newArr);
    setReplenishmentOrder(...currentOrder);
  };

  const [viewFulfillmentDetail, setViewFulfillmentDetail] =
    React.useState(false);

  const handleViewFulfillmentDetail = () => {
    setViewFulfillmentDetail(true);
  };

  //! Shipment Info
  const [selectedCarrier, setSelectedCarrier] = React.useState("");
  const [trackingNumber, setTrackingNumber] = React.useState("");

  const handleTrackingNumber = (trackingNum) => {
    // console.log(event.target.value)
    setTrackingNumber(trackingNum);
    setValidTrackingNumber(true);

    // setSelectedAccount(event.target.value);
    // setValidSelectedAccount(true);
  };
  const handleSelectCarrier = (carrier) => {
    // console.log(carrier)
    setSelectedCarrier(carrier);
    setValidCarrier(true);
    // setValidSelectedAccount(true);
  };

  const [destinationId, setDestinationId] = React.useState("SB-ATL");
  const handleSelectedDestination = (destinationId) => {
    setDestinationId(destinationId);
  };

  const shippedStatusUpdates = async (data) => {
    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";

    await axios({
      method: "put",
      url: `/iot/updateReplenishmentOrder/${owner}/${user}`,
      data: data,
    })
      .then(function (response) {
        // Message about the Shipment
        // console.log(response.data);
      })
      .catch(function (error) {
        // handle error
        // console.log(error.response.data.detail);
        // //!Message bar
        // setMessageBarMessage(`Oh no! Something went wrong. ${error.response.data.detail} You can email info@edonconnect.tech for help. ${error.response.data.detail}`);
        // setMessageBarOpen(true);
        // setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      })
      .then(function (response) {});
  };

  const completeShipment = async () => {
    var currentLocation = "SE_DC";
    var data = {
      currentLocationId: currentLocation,
      replenishmentOrder: selectedReplenishmentOrder,
      destinationId: destinationId,
      carrier: selectedCarrier,
      trackingNumber: trackingNumber,
      products: shipmentProducts,
    };
    var owner = "edon_owner";
    var user = "glenn.caudill@edonconnect.com";

    await axios({
      method: "post",
      url: `/iot/createNewItemShip/${owner}/${user}`,
      data: data,
    })
      .then(function (response) {
        // Message about the Shipment
        shippedStatusUpdates(data);
        // console.log(response.data);
        // alert(response.data.message)

        //NOTE Add snackbar
        props.handleSnackBar(response.data.message, "success");
        props.handleCloseCreateShipments();
      })
      .catch(function (error) {
        // handle error

        // console.log("errormessage", error.response.data);
        // console.log("errordetail", error.response.data.detail);
        // console.log("errorcode", error.response.status);
        // console.log("errortext", error.statusText);
        // alert(error.response.data.detail)
        // //!Message bar
        var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
        props.handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
      })
      .then(function (response) {});
  };
  // !SECTION Replenishment Order

  return (
    <div>
      <Grid
        container
        marginTop={5}
        sx={{
          justifyContent: "left",
          alignItems: "stretch",
          p: 1,
          border: 1,
          borderStyle: "dotted",
          borderRadius: "5px",
          direction: "row-reverse",
        }}
      >
        <Grid item xs={4} md={4} lg={4} sx={{ border: 0 }}>
          <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
            <ReplenishmentOrderInfo
              replenishmentOrder={replenishmentOrder}
              handleReplenishmentOrderSelected={
                handleReplenishmentOrderSelected
              }
              handleViewFulfillmentDetail={handleViewFulfillmentDetail}
              handleSelectedDestination={handleSelectedDestination}
              replenishmentOrderList={props.replenishmentOrderList}
            />
          </Paper>
        </Grid>
        {/* viewFulfillmentDetail */}
        {viewFulfillmentDetail && (
          <>
            <Grid item xs={4} md={4} lg={4} sx={{ border: 0 }}>
              <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
                <ReplenishmentProductInfo
                  replenishmentOrder={replenishmentOrder}
                  handleReplenishmentOrderProductAdd={
                    handleReplenishmentOrderProductAdd
                  }
                />
              </Paper>
            </Grid>

            <Grid item xs={4} md={4} lg={4} sx={{ border: 0 }}>
              <Paper sx={{ p: 1, display: "flex", flexDirection: "column" }}>
                <ReplenishmentShipmentInfo
                  handleTrackingNumber={handleTrackingNumber}
                  handleSelectCarrier={handleSelectCarrier}
                  destinationId={destinationId}
                />
              </Paper>
            </Grid>
          </>
        )}

        {showShipButton && (
          <Grid
            container
            sx={{
              alignContent: "left",
              justifyContent: "flex-end",
              align: "left",
              border: 0,
            }}
          >
            <Button
              align="left"
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={completeShipment}
            >
              Complete Shipment
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
