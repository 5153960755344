import * as React from 'react';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

// function preventDefault(event) {
//   event.preventDefault();
// }




export default function EchelonItemQuantitySummary(props) {
  
  
  const EchelonItemData = {
    "market": props.quantityByEchelon[0],
    "node": props.quantityByEchelon[1],
    "regional": props.quantityByEchelon[2],
  }
  //console.log("EchelonItemData", EchelonItemData)

  return (
    <React.Fragment>
        <Grid minHeight="50" container>

          <Grid item xs={12} md={12} lg={12}>
            <Title>Item Quantity</Title>
            <Divider />
          </Grid>

          <Grid item xs={12} md={12} lg={12}>

            <Typography color="text.secondary" sx={{pt:2 }}>
            Market:        
            </Typography>

            <Typography component="p" variant="h5" sx={{pt:0 }}>
              {EchelonItemData.market.toLocaleString()}
            </Typography>

            <Divider sx={{pt:1}}/>
          </Grid>
          <Grid item xs={12} md={12} lg={12}>

            <Typography color="text.secondary" sx={{pt:2 }}>
            Node:
            </Typography>

            <Typography component="p" variant="h5" sx={{pt:0 }}>
            {EchelonItemData.node.toLocaleString()}
            </Typography>
          </Grid>


        </Grid>
            <Divider sx={{pt:2}}/>

    </React.Fragment>
  );
}