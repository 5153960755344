import * as React from 'react';
import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useHistory } from 'react-router-dom';
import EdonWidget from './img/edon Connect Logo only.png'


import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Copyright from './Components/Copyright';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert sx={{width: '100%'}} elevation={6} ref={ref} variant="filled" {...props} />;
  });



// const theme = createTheme();
const vertical ='top';
const horizontal ='center';

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


export default function SignUp(props) {
  const history = useHistory();
  const [ userEmail ] = React.useState('');
  const [ emailError, setEmailError ] = React.useState(false);
  const [ emailErrorMessage, setEmailErrorMessage ] = React.useState('');


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setEmailError(false);
    props.handleEmailSent();
  };

  const [ buttonDisable, setButtonDisable ] = React.useState(true);
  const [ validEmail, setValidEmail ] = React.useState(false);
  const [ badEmail, setBadEmail ] = React.useState(false);
  const [ validSecurityCode, setValidSecurityCode ] = React.useState(false);
  const [ badSecurityCode, setBadSecurityCode ] = React.useState(false);
  

  const formValidation =  (e) => {
    e.preventDefault();
    // console.log(e.target.id);
    // console.log(e.target.value);
    switch (e.target.id){
      case 'userEmail': 
        var email = e.target.value;
        // eslint-disable-next-line 
        var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        var emailValid = email.match(pattern);
        if (emailValid){
          // setValidFields(prevState => ({
          //   ...prevState,
          //   "userEmail": false,
          //   }));
          //   setHelperText(prevState => ({
          //     ...prevState,
          //     userEmail: "",
          //     }));
            setValidEmail(true);
            setBadEmail(false)
          }else{
            // setValidFields(prevState => ({
            //   ...prevState,
            //   "userEmail": true}));

            // setHelperText(prevState => ({
            //   ...prevState,
            //   userEmail: "Need a valid email adress",
            //   }));
            setValidEmail(false);
            setBadEmail(true);
        }
        break;
      case 'securityCode': 
        if (e.target.value.length === 7){
            setValidSecurityCode(true);
            setBadSecurityCode(false);            
          }else{
            setValidSecurityCode(false)
            setBadSecurityCode(true);            
        }
        break;
      default:
        break;
    }
  }

  React.useEffect(() => {
    if (validEmail && validSecurityCode) {
      setButtonDisable(false);
    }else{
      setButtonDisable(true);
    }
  },[ validEmail, validSecurityCode]
  )
  //!SECTION


  //SECTION Axios Verify an User email on Registration
  //!Review THIS 
  //!Review THIS 
  //!Review THIS 
  const handleRegisterSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    // eslint-disable-next-line no-console
    //! Check to see if email is in the system for this account

    const securityCode = data.get("securityCode"); 
    const userEmail = data.get("userEmail"); 
    // console.log("Account", accountCode)
    // console.log("userEmail", userEmail)

    await axios.get(`/adminstration/verifyuseremail/${securityCode}/${userEmail}`)
    .then(function (response) {
      // handle success
      // console.log("Verified Email", response.data.message);

      // setUserEmail(userEmail);
      props.onRegister(userEmail, securityCode);
      event.target.reset();
      
      // handleHaveAnAccount(); #! don't do this here
      //var setUserDisplayStats = response.data
      //("Membership Stats:", response.data); 
    })
    .catch(function (error) {
      // handle error
      // console.log("Error", error.response.data.detail);
      setEmailErrorMessage(error.response.data.detail);
      setEmailError(true);
      event.target.reset();
    })
  };
  //!SECTION


  const handleHaveAnAccount = () => {
    history.push('/');
  };



  return (
    <ThemeProvider theme={darkTheme}>
        
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: '#68a0d7' }}>
            <AppRegistrationIcon />
          </Avatar> */}
          <img src={EdonWidget} width={50} margin={40} alt="Logo" />
          <Typography component="h1" marginTop={2} variant="h5">
            User Account Activation
          </Typography>


          <Box component="form" noValidate onSubmit={handleRegisterSubmit} sx={{ mt: 3 }}>     
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <Snackbar sx={{width: '50%'}} anchorOrigin={{vertical, horizontal}} open={props.emailSent} autoHideDuration={6000} onClose={handleClose}>
                  <Alert sx={{width: '100%'}}  onClose={handleClose} severity="success">User Account Activated! To create password an email has been sent to: {userEmail}. Make sure you check your junk email folder.</Alert>
                </Snackbar>
              </Grid>

              <Grid item xs={12} sm={12}>
                <Snackbar sx={{width: '50%'}} anchorOrigin={{vertical, horizontal}} open={emailError} autoHideDuration={6000} onClose={handleClose}>
                  <Alert sx={{width: '100%'}}  onClose={handleClose} severity="error">{emailErrorMessage}</Alert>
                </Snackbar>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  error = {badEmail}
                  required
                  fullWidth
                  id="userEmail"
                  label="Email Address"
                  name="userEmail"
                  autoComplete="email"
                  onChange={(e) => formValidation(e)}
                  />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  error = {badSecurityCode}
                  required
                  fullWidth
                  id="securityCode"
                  label="Security Code"
                  name="securityCode"
                  onChange={(e) => formValidation(e)}
                />
              </Grid>
           
            </Grid>
            <Button
              disabled={buttonDisable}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Activate Account
            </Button>
            <Grid container justifyContent="center">
              <Grid item>
                <Button fullWidth onClick={handleHaveAnAccount} align='center' variant="body2">
                  Already have an account?
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </ThemeProvider>
  );
}