import * as React from 'react';
import axios from 'axios';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

// import Snackbar from '@mui/material/Snackbar'
// import MuiAlert from '@mui/material/Alert';
// import MessageSnackBar from './MessageSnackBar';

import ItemAssociationReceipt from './ItemAssociationReceipt'
import InboundShipments from './InboundShipments.js';



// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });


export default function ReceiptInboundShipment(props) {


    //NOTE Used
    // eslint-disable-next-line 
    const [ selectedReplenishmentOrder, setSelectedReplenishmentOrder ] = React.useState('');
    const [ shipmentDetail, setShipmentDetail ] = React.useState([]);

    
    // const handleReplenishmentOrderSelected =(replenishmentOrderId) => {
    //     setSelectedReplenishmentOrder(replenishmentOrderId)
    // }

    //SECTION Get Inbound Shipment Data
    //NOTE Used
    const [ trackingNumber, setTrackingNumber ] = React.useState("");


    const handleGetShipmentData = (trackingNum) => {
        const owner = "edon_owner"
        const user = "glenn.caudill@edonconnect.com"
        setTrackingNumber(trackingNum)
        axios
        .get(
          `/iot/inboundShipment/${owner}/${user}/${trackingNum}`
          )
          .then(function async(response) {
            // handle success
            // console.log("Shipment Info:", response.data);
            // console.log("Products Info:", response.data.data);
            setShipmentDetail(response.data.data)
          })
          .catch(function (error) {
            // handle error
            // console.log(error);
            //!Message bar
            var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`
            props.handleSnackBar(errorMessage, "error")// error: red, warning: orange, information: blue, success: green
          })
          .then(function () {
            // always executed
          });
    }

    const handleCompleteInboundShipment = () => {
      setShipmentDetail([]);
      setTrackingNumber([]);

    }


    // const [ shipmentProducts, setShipmentProducts ] = React.useState("");
    
    // const handleReplenishmentOrderProductAdd = (addProduct) =>
    //   {
    //     setProductToShip(true); // at least one product to shop ship button
    //     //! add product to the shipment
    //     setShipmentProducts(prevState => ([
    //         ...prevState,
    //         addProduct
    //     ]));

    //     //! copy added product to varable
    //     var productId = addProduct.productId
    //     // console.log("Replenishment Orders",replenishmentOrder)
    //     // console.log("Product ID", productId)
    //     // console.log("Product that was added", replenishmentOrders.find(x=> x.productId === productId))
    //     var productAdd = replenishmentOrder.find(x=> x.productId === productId)
    //     // console.log("Product that was added", productAdd)
    //     //! copy all the products to variable
    //     var currentOrder = replenishmentOrder
    //     // console.log("copied order", currentOrder)

    //     //! reduce the qty
    //     var quantity = productAdd.qty - 1;

    //     //!if the qty is 0 change the status of the product in the replenishment order
    //     if (quantity === 0) {
    //       var newArr = currentOrder.map(obj => {
    //           if (obj.productId === productId) {
    //             return {...obj, status: "shipping"};
    //           }
    //           return obj;
    //         });
    //       }
    //     currentOrder = currentOrder.filter(object => {
    //       return object.productId !== productId;
    //     });
    //     currentOrder.push(newArr)
    //     setReplenishmentOrder(...currentOrder)
    //   }
    
    const [ viewAssociation, setViewAssociation ] = React.useState(false)
    

    const handleViewAssociation = () => {
      setViewAssociation(true)
    }

    //! Shipment Info
    // const [ selectedCarrier, setSelectedCarrier] = React.useState("");




  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "left",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      {/* <MessageSnackBar messageBarMessage={snackBarMessage} messageBarOpen={snackbarOpen} handleMessageBarClosing={handleClose} messageSeverity={messageSeverity} />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {snackBarMessage}
            </MuiAlert>
          </Snackbar> */}
    {/* {showShipButton &&
    <Grid container sx={{alignContent: 'left', justifyContent:"flex-end",  align:"left", border:0 } }>
          <Button
            align="left"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={completeShipment}
          >
            Complete Shipment
          </Button>
    </Grid>
  } */}
      
    <Grid item xs={6} md={6} lg={6} sx={{alignContent: 'center', border:0 } }>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <InboundShipments handleGetShipmentData={handleGetShipmentData} shipmentDetail={shipmentDetail} handleViewAssociation ={handleViewAssociation } />
        </Paper>
    </Grid>

  { viewAssociation &&
  <>
    <Grid item xs={6} md={6} lg={6} sx={{alignContent: 'center', border:0 } }>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <ItemAssociationReceipt  handleCompleteInboundShipment={handleCompleteInboundShipment} trackingNumber={trackingNumber} handleSnackBar={props.handleSnackBar} />
        </Paper>
    </Grid>


  </>
}

    </Grid>
  );
}