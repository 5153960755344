import React from "react";
// import axios from 'axios';


import Grid from "@mui/material/Grid";


// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
import DropDownSelector from "./DropDownSelector";
// import MessageSnackBar from "./MessageSnackBar";
// import Container from "@mui/material/Container";



// const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });


export default function WarehouseDropDown(props) {
  //! Local SnackBar Messaging


 //SECTION Message Bar Controls
//  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
//  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
//  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
//  const handleMessageBarClosing = () => {
//    setMessageBarOpen(false);
//  };
 //!SECTION


  const handleSelectedWarehouse = (warehouseName) => {
    const result = props.warehouseList.find( ({ value }) => value === warehouseName );
    props.handleSelectedWarehouse(result.label, result.value)
  };

  const plecibo = () => {
  };

  
  //Variables to send down
  const warehouseTitles = {
    dropDownTitle: "Select Location",
    dropDownButtonTitle: "Start Scanning",
    dropDownLabel: "Select Location",
    dropDownHelperText: "Your current location or warehouse.",
    dropDownAlertMessage: "Inventory will assigned to " ,
    buttonView: true
  };

  return (
        <Grid
        container
        sx={{
            alignContent: "left",
            justifyContent: "flex-end",
            align: "left",
            border: 0,
        }}
        >
          <DropDownSelector
                itemList={props.warehouseList}
                handleSelectedItem={handleSelectedWarehouse}
                dropDownTitles={warehouseTitles}
                toggleClose={plecibo}

            />
    </Grid>
  );
}