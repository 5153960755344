import * as React from 'react';

import { DataGrid, GridActionsCellItem  } from '@mui/x-data-grid';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function ReplenishmentShipmentGrid(props) {

    const deleteProduct = React.useCallback( (sentID) => async () => {
        var currentProducts = [...props.gridData]
        currentProducts.splice(sentID-1,1)
        // console.log("CurrentProducts", currentProducts)
        props.handleRemoveProduct(currentProducts)
    },
    [props], );

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          {/* <GridToolbarColumnsButton /> */}
          <GridToolbarFilterButton />
          {/* <GridToolbarDensitySelector /> */}
          <GridToolbarExport />
        </GridToolbarContainer>
      );
    }

    const [selectedModel, setSelectedModel ] = React.useState();
    const  GridData  = {
        columns: [
            {field: 'id', hide: true},
            {field: 'productId', headerName: 'Product Number', align:'center', headerAlign: 'center', width:150, editable: false},
            {field: 'serialNum', headerName: 'Serial Number', align:'center', headerAlign: 'center', width:150, editable: false},
            {field: 'actions', type: 'actions', headerName: 'Delete', width:75,  align:'center', headerAlign: 'center',
            getActions: (params) => [
                <GridActionsCellItem icon={<DeleteIcon />} onClick={deleteProduct(params.id)}  label="Delete" />
            ]},
      ], 
      rows: props.gridData
      };

  return (

    <div style={{ height: 300, width: '100%' }}>
      <DataGrid 
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        // props.handleMarketFileNames(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...GridData} 
      components={{
        Toolbar: CustomToolbar,
      }}
      />
    </div>

  );
};