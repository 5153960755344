import * as React from "react";
import { useContext } from "react";
import { UserContext } from "./context/UserContext.js";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSessionStorage } from 'react-use';
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import WALogo from "./WA_logo.png";
import Button from "@mui/material/Button";
import EdonLogo from "./edonLogoWhite.png";
import HeaderMenus from "./Components/HeaderMenus.js";
import FlexLogo from "./flexe_Logo.png";
// import MessageSnackBar from "./Components/MessageSnackBar.js";
import WarehouseScanning from "./Components/WarehouseScanning.js";
import AccountDropDown from './Components/AccountDropDown.js'
import Copyright from "./Components/Copyright.js";
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import Unauthorized from "./Components/Unauthorized.js";




// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});



export default function CycleCount(props) {
  const userData = useContext(UserContext);

  const pageTitle = "Inventory Scanning";
  // const user = userData;

  
  //! Page SnackBar Messaging
  //! put this at the page and send it down
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackBar = (message, severity) => {
    setSnackBarMessage(message);
    setMessageSeverity(severity)
    setSnackbarOpen(true);
  }


    //!SECTION

    const [ setMemberCode ] = React.useState(userData.membershipCode);
    
    
    
    //SECTION Select Account
    const [ eventAccount, setEventAccount ] = useSessionStorage(localStorage, "EventAccount", userData.company);
    const [ accountName, setAccountName ] = React.useState(userData.company);
    const [ accountCode, setAccountCode ] = React.useState(userData.accountCode);
    const [ viewAccounts, setViewAccounts ] = React.useState(false);
    const [ viewAccountsMessage, setAccountsMessage ] = React.useState('Select Account');
    const [ eventAccountSerialType, setEventAccountSerialType ] = React.useState(false);

    //! event Account Code
    const [ eventAccountCode, setEventAccountCode ] = React.useState(userData.accountCode);

    const toggleAccounts = () => {
        if (viewAccounts) {
            setViewAccounts(false);
            setAccountsMessage("View Accounts");
            // setViewFileDeleteButton(false);
        } else {
            setViewAccounts(true);
            setAccountsMessage("Hide Accounts");
            setViewScanInventoryMessage('Scan Inventory')
            // getAccountList();
        }
    };

    //handler to send down
      const handleSelectedAccount = async (accountname, accountcode, membershipCode) => {
        setAccountName(accountname); 
        setAccountCode(accountcode);
        setEventAccount(accountname);
        setEventAccountCode(accountcode);
        setMemberCode(membershipCode);
        getEventAccountType(accountcode);
      };
      //set up the account type endpoint

      const getEventAccountType = async (accountcode) => {
        var headers = props.headers
        // console.log(accountcode)
        //! set up end point for event account type
        // console.log(props.headers)
        var parameters = {
          accountcode: accountcode
        }
        await axios
        .get(
          `${props.mobileSite}MobileApp/accountscantype`, { headers:headers, params: parameters }
          )
          .then(function async(response) {
            // console.log(response.data)
            if (response.data === 'product') {
              setEventAccountSerialType(false)
            }
          })
          .catch(function (error) {
            //get the error message
            // console.log("Error", error.response.data.detail);
            var message = error.response.data.detail + " You can set the scanning type on the admin page."
            handleSnackBar(message, "warning")
          });
      };


    //!SECTION



  const [viewScanInventory, setViewScanInventory] =
  React.useState(false);

  const [
    viewScanInventoryMessage,
    setViewScanInventoryMessage,
  ] = React.useState( "Scan Inventory");


  const [
    warehouseList,
    setWarehouseList
  ] = useSessionStorage(localStorage, "EventWarehouseList",[]);

  const eventCleanLocalStorage = () => {
    // localStorage.removeItem('EventAccount');
    // localStorage.removeItem('');
    localStorage.removeItem('EventWarehouseList');
    localStorage.removeItem('EventIndex');
    localStorage.removeItem('EventWarehouseName');
    localStorage.removeItem('EventViewWarehouseName');
    localStorage.removeItem('EventViewWarehouseScans');
    localStorage.removeItem('EventValidProductNumbers');
    localStorage.removeItem('EventValidSerialNumbers');
    localStorage.removeItem('EventCurrentShelves');
    localStorage.removeItem('EventShelfContents');
    localStorage.removeItem('EventCurrentShelfNumber');
    localStorage.removeItem('EventViewWarehouseDropDown');
    localStorage.removeItem('EventViewScanInventory');
    localStorage.removeItem('EventScanningTypeMessage');
    localStorage.removeItem('EventViewPartsScans');
    localStorage.removeItem('EventTotalProductScans');
    localStorage.removeItem('EventProductScans');
    
  };

  const getWarehouseList = async (accountCode) => {
    var headers = props.headers
    var parameters = { accountcode: accountCode }
    // console.log("accountCode: ", accountCode)
    await axios
      .get(
        `${props.mobileSite}MobileApp/validwarehouselocations`, 
        { headers:headers, params: parameters }
        )
        .then(function async(response) {
          setWarehouseList(response.data.warehouses)
          // console.log(response.data.warehouses)
          // response.data.warehouses.forEach((warehouse, index) =>{
          //   // console.log("warehouse: ",warehouse)
          //   // console.log("index: ", index)
          //   //! add id, label and value
          //   warehouse.id = index;
          //   warehouse.label = warehouse.warehouseName;
          // }
          // );
          // setWarehouseList(response.data.warehouses) 

          })
        .catch(function (error) {
          // handle error
          console.log(error);
          //! Need itMessage bar
          // setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          // setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        })
        .then(function () {
          // console.log("Warehouse List", warehouseList)
        });
    }

  const toggleScanInventory = async () => {
    if (viewScanInventory) {
        setViewScanInventory(false);
        setViewScanInventoryMessage(
        "Scan Inventory"
      );
      eventCleanLocalStorage();

    } else {
      getWarehouseList(accountCode);
      setViewScanInventory(true);
      setViewScanInventoryMessage(
        "Hide Scan Inventory"
        );
      
        }
  };



  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        {/* Content*/}
        {/* Content*/}
        {/* Content*/}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <img src={EdonLogo} width={200} alt="Logo" />
          <Container
            overflow="auto"  //! Changed from scroll to auto 
            maxWidth="xl"
            sx={{ mt: 5, mb: 10, border: 1 }}
          >
            {userData.membershipCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            )}
            {userData.membershipCode === "0x4ee11bfe" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 2 }}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            )}

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
        {/* <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} /> */}
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity={messageSeverity}>
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>

              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  {/* <Button
                      onClick={eventCleanLocalStorage}
                    >
                      Clear
                    </Button> */}
                  <Typography align="center" variant={"h4"}>
                    {eventAccount}
                  </Typography>
                  <Typography align="center" variant={"h4"}>
                    {pageTitle}
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {userData.email}
                  </Typography>

                  {!userData.services.includes("IS") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }

            {/* Accounts Drop Down */}
            {userData.accountViewer && (
                <Grid
                  item
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", border: 0 ,display:"flex", flexDirection:"column"}}
                >

                    <Button
                      onClick={toggleAccounts}
                    >
                      {viewAccountsMessage}
                    </Button>
                    {
                      viewAccounts && (

                        <AccountDropDown 
                        handleSelectedAccount={handleSelectedAccount}
                        toggleAccounts={toggleAccounts}
                        user={userData} />

                      ) // send the list of files and a handler down to the dropdown
                    }
                </Grid>
              )}
                </Paper>
              </Grid>
            </Grid>


            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0 }}
            >

              {/* Scan Inventory */}
              {userData.userRole > 5 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleScanInventory}>
                        {" "}
                        {viewScanInventoryMessage}
                      </Button>

                      {/* Item Assignment Filters */}

                      {viewScanInventory &&  (
                        <WarehouseScanning
                        user={userData}
                        eventAccount={eventAccount}
                        accountCode={accountCode}
                        eventAccountCode={eventAccountCode}
                        accountName={accountName}
                        toggleScanInventory={toggleScanInventory} 
                        warehouseList={warehouseList}
                        handleSnackBar={handleSnackBar}
                        eventAccountSerialType={eventAccountSerialType}
                        mobileSite={props.mobileSite}
                        headers= {props.headers}
                        // getWarehouseList={getWarehouseList}
                        // toggleAccounts={toggleAccounts}
                          
                        />
                      )}
                    </Paper>
                  </Grid>
                </>
              )}

            </Grid>

            <Grid item border={0} xs={12} sm={12} md={12}>
              <Copyright sx={{ pt: 4, align: "center" }} />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
