import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { dataGridStyles } from './dataGridStyles';

import { 
  DataGridPro,
  GridToolbar,
  GridActionsCellItem } from '@mui/x-data-grid-pro';



import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';


export default function MobileUserTable(props) {

  const userData = useContext(UserContext)
  const visibleColumns = {
    id: false,
    userCode: false,
  }

    const nonAdminUser = userData.userRole !== 10 ? true : false;
    const [selectedModel, setSelectedModel ] = React.useState();

    if (nonAdminUser === true) {
      var currentServices  = {
        columns: [
        {field: 'id', hide: true},
        {field: 'service', headerName: 'Service', width:175, headerAlign: 'center', align: 'center',  editable: false},
        {field: 'status', headerName: 'Status', width:100, headerAlign: 'center', align: 'center',  editable: false},
        ], 
        rows: props.currentServicesDisplayData
        }
    }else{

    var currentServices  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'service', headerName: 'Service', width:175, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'status', headerName: 'Status', width:100, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'actions', type: 'actions', headerName: 'Change Service Status', width:200, hide: nonAdminUser,
      getActions: (params) => [
        <GridActionsCellItem icon={<ChangeCircleIcon />} onClick={props.changeCurrentServices(params.id, props.currentServicesDisplayData.find(x => x.id === params.id).status, props.selectedAccount)}  label="Change" />
      ]
    },
      ], 
      rows: props.currentServicesDisplayData
      }
    };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 
          sx={dataGridStyles.root}
          pagination

          initialState={{
            pageSize: 10,
            columns: {
              columnVisibilityModel: visibleColumns,
            },
            sorting: {
              sortModel: [{ field: "status" }],
            },
          }}
          slots={{toolbar: GridToolbar}}
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentServices} 

      />
    </div>

  );
};