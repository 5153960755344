import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './listItems';
import EdonLogo from './edonLogoWhite.png'
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import MachinePic from './img/machingLearning.jpeg'
import TextField from '@mui/material/TextField';
import HeaderMenus from './Components/HeaderMenus.js';
import Unauthorized from './Components/Unauthorized.js'



// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="/">
        Edon Connect
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);




export default function MachineLearning(props) {
  const contextUserData = useContext(UserContext)
  const [userData, setUserData] = React.useState(() => {
    const storedUserData = localStorage.getItem('userData');
    return storedUserData ? JSON.parse(storedUserData) : contextUserData;
  });

  const pageTitle = "AI";
  const [open, setOpen] = React.useState(true);
  const [equipmentPic, setEquipmentPic] = React.useState('./img/machingLearning.jpeg');
  const [equipmentName, setEquipmentName] = React.useState('Equipment Name');

  const handleGetEquipmentPic = (event) => {
    // console.log(e.target.value);
    const formData = new FormData(event.currentTarget);
    // console.log(formData.get('equipmentPic'));
    // e.preventDefault();
    // setEquipmentName(e);

  }


  const toggleDrawer = () => {
    setOpen(!open);
  };


  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />


          {/* Content*/}
          {/* Content*/}
          {/* Content*/}

        <Container sx={{ py: 8 }} maxWidth="md">
          <img src={EdonLogo} width={200} alt="Logo" />

          {!userData.services.includes("AI") &&

              <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                <Unauthorized />
              </Grid>
          }

          {/* End hero unit */}
          <Grid container spacing={4}>


              <Grid item  xs={12} sm={12} md={12}>
                <Card
                  sx={{ height: '80%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '10%',
                      maxHeight: "50%"
                    }}
                    image={MachinePic}
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      Artificial Intelligence
                    </Typography>
                    <Typography>
                      Our machine learning algorithms will help you optimize your 
                      forward deployed supply chain.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>


            {false &&
              <Grid item  xs={12} sm={12} md={12}>
              <Box sx ={{display: "flex", justifyContent:'space-around'}} >
                <Typography variant="h6" gutterBottom center component="div">{equipmentName}</Typography>
              </Box>
              <Box component="form" onSubmit={handleGetEquipmentPic} sx={{ mt: 1, justifyContent:'space-around' }}> 
                <TextField
                margin="normal"
                fullWidth
                name="equipmentName"
                label="Equipment Name"
                type="text"
                id="equipmentName"
              />
            
              <Button
                // disabled={buttonDisable}
                align="center"
                type="submit"
                variant="contained"
                fullWidth
                sx={{ mt: 3, mb: 2 }} 
              >
                Find Equipment Image
              </Button>

              </Box>
                <Card
                  sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      // 16:9
                      pt: '10%',
                      maxHeight: "100%"
                    }}
                    image={equipmentPic}
                    alt="random"
                  />
                </Card>
              </Grid>
            }
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <Copyright sx={{ pt: 10, align: "center" }} />
          </Grid>
          
        </Container>

          
        </Box>
      </Box>
    </ThemeProvider>
  );
}