import * as React from 'react';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

// function preventDefault(event) {
//   event.preventDefault();
// }




export default function EchelonCost(props) {

    const EchelonCostData = {
        "market": props.costByEchelon[0],
        "node": props.costByEchelon[1],
        "regional": props.costByEchelon[2],
    }    
    //console.log(EchelonCostData)

  return (
    <React.Fragment>
        <Grid minHeight="50" container>
            <Grid item xs={12} md={12} lg={12}>
                <Title>Costs by Echelon</Title>
                <Divider/>
            </Grid>
        <Divider />
        <Grid item xs={12} md={12} lg={12}>
            <Typography color="text.secondary" sx={{pt:2 }}>
            Market:        
            </Typography>
            <Typography component="p" variant="h5" sx={{pt:0 }}>
            {EchelonCostData.market.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0})}
            </Typography>
                <Divider sx={{pt:1}} />
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
            <Typography color="text.secondary" sx={{pt:2 }}>
            Node:
            </Typography>

            <Typography component="p" variant="h5" sx={{pt:0 }}>
            {EchelonCostData.node.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 0})}
            </Typography>

        </Grid>


        </Grid>
            <Divider sx={{pt:2}}/>


    </React.Fragment>
  );
}