import * as React from "react";

// import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material//MenuItem";


export default function DropDownInitialValue(props) {
  // Store Item selected
  

const [selectedItem, setSelectedItem] = React.useState(props.initialSelection);

  // handle the event of item selection
  const handleItemSelect = (event) => {
    setSelectedItem(event.target.value);
    props.handleSelectedItem(event.target.value)
  };
  
  return (
    <Grid
      container
      marginTop={0}
      maxWidth="sm"
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 0,
        border: 0,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      {/* <Stack direction="column" alignItems="center" spacing={2}> */}
        <Typography variant="subtitle">
          {props.dropDownTitles.dropDownTitle}
        </Typography>

          <TextField
            margin="normal"
            select
            fullWidth
            name="SelectItem"
            label={props.dropDownTitles.dropDownLabel}
            value={selectedItem}
            onChange={handleItemSelect}
            type="number"
            id={props.itemList.id}
            helperText={props.dropDownTitles.dropDownHelperText}
          >
            {props.itemList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.id}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>

      {/* </Stack> */}
    </Grid>
  );
}