import React from 'react';
import { Pie, PieChart, ResponsiveContainer, Legend, Cell } from 'recharts';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);


export default function Piechart(props) {

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
 
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };
    
    const COLORS = props.colors;


    return (
        <div style={{ height: 400, width: '100%' }}>
            <ResponsiveContainer height="100%">
            <PieChart width={730} height={250}>
                <Legend verticalAlign="top" wrapperStyle={{fontSize:"14px"}} height={10}/>
                <Pie 
                data={props.data}
                dataKey="value"
                labelLine={false}
                nameKey="name"
                label={renderCustomizedLabel}
                paddingAngle="5"
                cx="50%"
                cy="50%"
                >
                {props.data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}

                </Pie>
            </PieChart>

              </ResponsiveContainer>


            </div>


    )
}