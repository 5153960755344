import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
// import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
// import Button from '@mui/material/Button';




const echelon = [
    {
        value: 'R',
        label: 'Regional',
    },
    {
        value: 'M',
        label: 'Market',
    },
    {
        value: 'N',
        label: 'Node',
    },
]


function RedBar() {
  return (
    <Box
        sx={{
            '@media (min-width:0px)': {
                height:10,
            },
            '@media (min-width:600px)': {
                height:25,
            },
            '@media (min-width:900px)': {
                height:22,
            },
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? 'rgba(255, 0, 0, 0.1)'
                    : 'rgb(255 132 132 / 25%)',
        }}
    />
  );
}





export default function GridInput(props) {



  return (
    <>
    <Grid  container border={1}>

        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>

        <Grid item xs={12} sm={12}>
            <Grid container overflow='auto' justifyContent="space-around" >
                <Grid item align='center' xs={3} sm={3}>
                    <TextField name="325" value={props.gridInputs[325]} fullWidth onChange={props.handleGridInputChange} select id="325">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}  >
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField  name="650" value={props.gridInputs[650]} onChange={props.handleGridInputChange} fullWidth select id="650">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="975" value={props.gridInputs[975]} onChange={props.handleGridInputChange} fullWidth select id="975">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="1300" value={props.gridInputs[1300]} onChange={props.handleGridInputChange} fullWidth select id="1300">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Grid>


        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>
        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>

        <Grid item xs={12} sm={12}>
            <Grid container justifyContent="space-around" sx={{}}>
                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="275" value={props.gridInputs[275]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField  name="550" value={props.gridInputs[550]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="825" value={props.gridInputs[825]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="1100" value={props.gridInputs[1100]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>
        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>

        <Grid item xs={12} sm={12}>
            <Grid container justifyContent="space-around" sx={{}}>
                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="175" value={props.gridInputs[175]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField  name="350" value={props.gridInputs[350]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="525" value={props.gridInputs[525]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="700" value={props.gridInputs[700]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>
        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>

        <Grid item xs={12} sm={12}>

            <Grid container justifyContent="space-around" sx={{}}>
                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="25" value={props.gridInputs[25]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField  name="50" value={props.gridInputs[50]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="75" value={props.gridInputs[75]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>

                <Grid item align={'center'} xs={3} sm={3}>
                    <TextField name="100" value={props.gridInputs[100]} onChange={props.handleGridInputChange} fullWidth select id="margin-none">
                        {echelon.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                            {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
        <RedBar />
        </Grid>
    </Grid>

    </>
  )
};