
import React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';



import { DataGrid } from '@mui/x-data-grid';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);



export default function DemandAnalyzerTable(props) {


    const  demandAnlyzerTable  = {
        columns: [
        {field: 'id', headerName: 'Id',hide: true},
        {field: 'stat', headerName: 'Statistic', flex: 1, sortable: false, editable: false},
        {field: 'value', headerName: 'Value', editable: false, flex:1, sortable:false, headerAlign: 'center', type: 'number', hide: false},
        ], 
        rows: props.data
        }


    return (
        <div style={{ height: 300, width: '100%' }}>

                <DataGrid 
                disableColumnSort
                disableSelectionOnClick
                showCellRightBorder
                showColumnRightBorder
                showCellLeftBorder
                disableColumnMenu
                hideFooterPagination

                    {...demandAnlyzerTable} 
                />

            </div>


    )
}