import * as React from 'react';
// import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from './Title';
import Divider from '@mui/material/Divider';
import { Grid } from '@mui/material';

// function preventDefault(event) {
//   event.preventDefault();
// }





export default function MarketSummary(props) {




  const totalItemCost = props.marketSummary[0]
  const totalItemQuantity = props.marketSummary[1]





  

  return (
    <React.Fragment>

    <Grid minHeight="50" container>

      <Grid item xs={12} md={12} lg={12}>
        <Title>Market Summary</Title>
        <Divider />
      </Grid>

      <Grid item xs={12} md={12} lg={12}> 

        <Typography color="text.secondary" sx={{pt:2 }}>
        Total Item Costs:
        </Typography>

        <Typography component="p" variant="h5">
        {totalItemCost.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0})}
        </Typography>
        <Divider sx={{pt:1}}/>
      </Grid>

      <Grid item xs={12} md={12} lg={12}>

        <Typography color="text.secondary" sx={{pt:2 }}>
        Total Item Quantity:
        </Typography>

        <Typography component="p" variant="h5">
        {totalItemQuantity.toLocaleString()}
        </Typography>
      </Grid>
      </Grid>
      <Divider sx={{pt:2}}/>

    </React.Fragment>
  );
}