import * as React from 'react';

import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid'
import CircularProgress from '@mui/material/CircularProgress';
import { Typography } from '@mui/material';
// import Input from '@mui/material/Input'

const Input = styled('input')({
  display: 'none',
});

// This is a generic file drop
// Need to send it a handler that accepts a file 
// The file is in the state variable selected file 
export default function FileDrop(props) {
  const [selectedFile, setSelectedFile ] = React.useState();
  const [selectedFileName, setSelectedFileName ] = React.useState(true);
  const [viewSelectFileButton, setViewSelectFileButton ] = React.useState(true);
  const [viewLoadingSpinner, setviewLoadingSpinner ] = React.useState(false);

  const handleCapture = (event) => {
    setSelectedFile(event.target.files[0])
    setSelectedFileName(event.target.files[0].name)
    setViewSelectFileButton(false);
  }

  const handleUpload = (event) => {
    props.handleUploadFile(selectedFile); //send the file to the handler
    setviewLoadingSpinner(true);
  }



  return (

    <Grid container marginTop={5} sx={{justifyContent:'center', alignItems:'center', alignContent:'center', p:1, border:1, borderStyle: 'dotted', borderRadius: '5px'  }}>
    {viewSelectFileButton &&
        <Grid container  sx={{justifyContent:'center', border:0, p:5, borderStyle: 'dotted', borderRadius: '5px'  }} >
          <label htmlFor="contained-button-file">
            <Button variant="contained" component="label">
            <Input accept=".csv" onChange={handleCapture} id="contained-button-file" hidden type="file" />
              Select Your File
            </Button>
          </label>
        </Grid>
        }

      {!viewSelectFileButton &&
      <Grid container sx={{justifyContent:'center', border:0, p:0, borderStyle: 'dotted', borderRadius: '5px'  }}>
      <Button onClick={handleUpload} variant="contained" component="label">
          Upload
          {viewLoadingSpinner &&
          <CircularProgress sx={{margin:1}} color="inherit" size ={20}/>
            }
        </Button>
        <Grid container sx={{justifyContent:'center', border:0, p:0, borderStyle: 'dotted', borderRadius: '5px'  }}>
      <Typography>
            {selectedFileName}
      </Typography>
      </Grid>
      </Grid>
      }



    </Grid>
  );
}