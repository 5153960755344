import * as React from 'react';

// import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import axios from 'axios';

import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material//MenuItem';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

// import Snackbar from '@mui/material/Snackbar'
// import MuiAlert from '@mui/material/Alert';
// import MessageSnackBar from './MessageSnackBar';


import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import ATLSaltBox from '../img/ATLSaltbox.png'






// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function ReplenishmentShipmentInfo(props) {

  //!SECTION



   //SECTION Form Validation
    //! Form Validation
    const [ setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "productId": false,
        "serialNum": false,
        "quantity": false,
        "userFirstName": false,
        "userLastName": false,
        "userEmail": false,
        
    });
    const [ helperText, setHelperText ] = React.useState(
        {
        "productId":"SKU Number",
        "serialNum":"Unique Identifier",
        "quantity": false,
        "userFirstName":"",
        "userLastName":"",
        "userEmail":"",
       });
    //    NOTE Review
    const [ setValidFirstName ] = React.useState(false);
    const [ validProductNumber, setValidProductNumber ] = React.useState(true);
    const [ validSerialNumber, setValidSerialNumber ] = React.useState(true);


    // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){

        case 'trackingNum': 
          var trackingNum = e.target.value;
          // console.log(trackingNum)
          if (trackingNum.length >8){
            props.handleTrackingNumber(trackingNum)
          }
          // var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          // var emailValid = email.match(pattern);
          //NOTE Build Tracking verfications with carrier apis
          break;

        case 'userFirstName': 
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "userFirstName": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                userFirstName: "",
                }));
              setValidFirstName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "userFirstName": true}));
              setHelperText(prevState => ({
                ...prevState,
                userFirstName: "First Name needs at least two characters",
                }));
              setValidFirstName(false)
          }
          break;

        case 'productId': 
        // NOTE check to see if it is a valid Product Id
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "productId": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                productId: "",
                }));
              setValidProductNumber(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "productId": true}));
              setHelperText(prevState => ({
                ...prevState,
                productId: "This is not a valid Product Number",
                }));
              setValidProductNumber(false)
          }
          break;
        case 'serialNum': 
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "serialNum": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "",
                }));
              setValidSerialNumber(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "serialNum": true}));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "This is not a valid Serial Number",
                }));
              setValidSerialNumber(false)
          }
          break;

        default:
          break;
      }
    }

    // NOTE Review
    React.useEffect(() => {
      if (validProductNumber && validSerialNumber ) {
        setButtonDisable(false);
      }else{
        setButtonDisable(true);
      }
    },[ validProductNumber, validSerialNumber ]
    )
    //!SECTION

    const [ products, setProducts ] = React.useState([])

    // const handleRemoveProduct = async (currentProducts) => {
    //     setProducts([...currentProducts])
    // };

    //NOTE review
    //SECTION Axios Create User
  // const handleAddProduct = async (event) => {
  //   event.preventDefault();
  //   // console.log("What the Fuck")
  //   const formData = new FormData(event.currentTarget);
  //   const productId = formData.get("productId");
  //   const serialNum = formData.get("serialNum");
  //   var newId = products.length + 1
  //   var addProduct = {
  //       "id":newId,
  //       "productId": productId,
  //       "serialNum": serialNum,
  //   }   
  //   setProducts(prevState => ([
  //       ...prevState,
  //       addProduct
  //   ]));

  //   // console.log("data", data)
  //   // await axios({
  //   //   method: "post",
  //   //   url: `/adminstration/addplatformuser/`,
  //   //   data: data,
  //   // })
  //   //   .then(function (response) {
  //   //     // Message about the User account
  //   //     // console.log(response.data);
  //   //     setSnackBarMessage(response.data);
  //   //     setSnackbarOpen(true);
  //   //   })
  //   //   .catch(function (error) {
  //   //     // handle error
  //   //     console.log(error.response.data.detail);
  //   //     //!Message bar
  //   //     setMessageBarMessage(`Oh no! Something went wrong. ${error.response.data.detail} You can email info@edonconnect.tech for help. ${error.response.data.detail}`);
  //   //     setMessageBarOpen(true);
  //   //     setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
  //   //   })
  //   //   .then(function (response) {
  //   //     // Send the email
  //   //     if (sendUserEmail) {
  //   //       // console.log("Account Infor", assignAccount);
  //   //       props.sendUserInvite(userEmail, assignAccount, userFirstName);
  //   //     }
  //   //   });
  // };
  //!SECTION

    //NOTE review
    //SECTION Axios Create User
//   const handleCreatePlatformUser = async (event) => {
//     event.preventDefault();
//     const formData = new FormData(event.currentTarget);
//     const assignAccount = formData.get("assignAccount");
//     const userEmail = formData.get("userEmail");
//     const userFirstName = formData.get("userFirstName");
//     const userLastName = formData.get("userLastName");
//     const userRole = parseInt(formData.get("userRole"));
//     const creatorEmail = userData.email;
//     var data = {
//       creatorEmail: creatorEmail,
//       userFirstName: userFirstName,
//       userLastName: userLastName,
//       userEmail: userEmail,
//       userRole: userRole,
//       accountCode: assignAccount,
//     };
//     // console.log("Sending Data", data)

//     // console.log("data", data)
//     await axios({
//       method: "post",
//       url: `/adminstration/addplatformuser/`,
//       data: data,
//     })
//       .then(function (response) {
//         // Message about the User account
//         // console.log(response.data);
//         setSnackBarMessage(response.data);
//         setSnackbarOpen(true);
//       })
//       .catch(function (error) {
//         // handle error
//         console.log(error.response.data.detail);
//         //!Message bar
//         setMessageBarMessage(`Oh no! Something went wrong. ${error.response.data.detail} You can email info@edonconnect.tech for help. ${error.response.data.detail}`);
//         setMessageBarOpen(true);
//         setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
//       })
//       .then(function (response) {
//         // Send the email
//         if (sendUserEmail) {
//           // console.log("Account Infor", assignAccount);
//           props.sendUserInvite(userEmail, assignAccount, userFirstName);
//         }
//       });
//   };
  //!SECTION
  //SECTION Show Destination address
  const [viewDestinationAddress, setViewDestinationAddress] =React.useState(false);
    React.useEffect(() => {
    if (props.destinationId) {
      setViewDestinationAddress(true);
      }
    },
    [ props.destinationId ]
    )
  
  
  //!SECTION Show Destination address
  
  //SECTION Select Carrier
  const selectCarrier = (event) => {
      // console.log(event.target.value)
      props.handleSelectCarrier(event.target.value)
      setSelectedCarrier(event.target.value)
    // setSelectedAccount(event.target.value);
    // setValidSelectedAccount(true);
  };
    const [selectedCarrier, setSelectedCarrier] = React.useState("");


    const carrierList = [
    {
    id: 1,
    value: "FedEx",
    label: "FedEx",
    },
    {
    id: 2,
    value: "UPS",
    label: "UPS",
    },
    {
    id: 3,
    value: "USPS",
    label: "USPS",
    },
    {
    id: 4,
    value: "Roadway",
    label: "Roadway",
    },
    {
    id: 5,
    value: "Other",
    label: "Other",
    },
];
  //!SECTION select Carrier



  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 1,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        <Typography component="h1" variant="h5">
          Shipment Information
        </Typography>
      {true &&
        <Box
          component="form"
          noValidate
          sx={{ mt: 1 }}
        >
          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="selectCarrier"
            label="Carrier"
            value={selectedCarrier}
            onChange={selectCarrier}
            type="text"
            id="selectCarrier"
            helperText="Select Carrier for Shipment"
          >
            {carrierList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
      
          <TextField
            error = {validFields.quantity}
            helperText={helperText.quantity}
            margin="normal"
            fullWidth
            required
            name="trackingNum"
            label="Tracking Number"
            type="text"
            id="trackingNum"
            onChange={(e) => formValidation(e)}
            />
        </Box>
        }
        {viewDestinationAddress &&
            <Card sx={{ maxWidth: 545 }}>
                <CardMedia
                    component="img"
                    height="140"
                    image= {ATLSaltBox}
                    alt="green iguana"
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                    ATL Saltbox
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    Address:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    1345 Seaboard Industrial Blvd NW
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                    Atlanta, GA 30318
                    </Typography>
                </CardContent>
            </Card>
        }


      </Stack>

    </Grid>
  );
}