import * as React from 'react';
import Box from '@mui/material/Box';
import { Grid, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';



const theme = createTheme();

theme.typography.h2 = {

    '@media (min-width, 600px)': {
        fontSize: '1.2rem',
        align: 'center',
        justify: 'center',
    },

    [theme.breakpoints.up('md')]: {
        fontSize: '2.4rem'
    },
};


theme.typography.body2 = {
    fontSize: '.5rem',
    '@media (min-width, 600px)': {
        fontSize: '.5rem',
    },
    [theme.breakpoints.up('md')]: {
        fontSize: '.8rem'
    },
};



function AxisLabel(props) {
    
    return (
      <Box
          sx={{
              '@media (min-width:0px)': {
                paddingTop:1, 
                height: 76, 
                borderTop: 1,
                borderLeft: 1,
                margin: 0,
                },
              '@media (min-width:600px)': {
                paddingTop:5, 
                height: 106, 
                borderTop: 1,
                borderLeft: 1,
                margin: 0,
                },
                '@media (min-width:900px)': {
                    padding:2, 
                    height: 100, 
                    borderTop: 1,
                    borderLeft: 1,
                    margin: 0,
                },
            
              backgroundColor: (theme) =>
                  theme.palette.mode === 'light'
                      ? 'rgba(255, 0, 0, 0.1)'
                      : 'rgb(255 132 132 / 25%)',
          }}
      >
            <ThemeProvider theme={theme}>
                <Typography align='center' variant={'h2'}>{props.labelValue}</Typography>
            </ThemeProvider>
        </Box>

    );
  }


export default function LegendY() {
    const  yaxisTitle = "Cost%"

    return (


            <Grid container sx={{border: 0}}>

            <Grid items xs={3} sm={3}>
                <Grid container sx={{border:0, height: 600, justifyContent: "center", alignItems: "center", transform: 'rotate(180deg)'}}>

                        <Typography variant={'subtitle1'} sx={{transform: 'rotate(90deg)',border:0, align:'center'}}>{yaxisTitle} </Typography>

                </Grid>
            </Grid>

            
            <Grid item xs={9} sm={9}>

            <Grid sx={{pt:1 }} item xs={12} sm={12}>
                <Box 
                    sx={{ 
                    '@media (min-width:0px)': {
                        pt:4,
                        pl:0,
                        border: 0,
                        height:75,
                        pb:2,
                        }, 

                    '@media (min-width:900px)': {
                        pt: 4,
                        pl:2,
                        border: 0,
                        height:102,
                        pb:9
                    },

                    '@media (min-width:600px)': {
                        pt:3,
                        pl:3,
                        border: 0,
                        height:75,
                        pb:6,
                        },

                    }}>

                    <ThemeProvider theme={theme}>
                            <Typography variant={'body2'}>Regional</Typography>
                            <Typography variant={'body2'}>Market</Typography>
                            <Typography variant={'body2'}>Node</Typography>
                    </ThemeProvider>
                </Box>
            </Grid>

            <Grid sx={{border:1}}>
            <Grid borderTop={0} container>
                <Grid item xs={12} sm={12}>
                    <AxisLabel labelValue='100' />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AxisLabel labelValue='75' />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <AxisLabel labelValue='50' />
                </Grid>
                <Grid item borderBottom={1} xs={12} sm={12}>
                    <AxisLabel labelValue='25' />
                </Grid>
            </Grid>
            </Grid>
        </Grid>
        </Grid>
      
        )
};