import * as React from "react";

import { DataGrid, GridToolbar } from "@mui/x-data-grid";
// import DeleteIcon from "@mui/icons-material/Delete";
import { LicenseInfo } from "@mui/x-data-grid-pro";
const REACT_APP_GRID = process.env.REACT_APP_GRID;
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function ReplenishmentShipmentGrid(props) {
  // const deleteProduct = React.useCallback( (sentID) => async () => {
  //     var currentProducts = [...props.gridData]
  //     currentProducts.splice(sentID-1,1)
  //     console.log("CurrentProducts", currentProducts)
  //     props.handleRemoveProduct(currentProducts)
  // },
  // [props], );

  const [selectedModel, setSelectedModel] = React.useState();
  const GridData = {
    columns: [
      { field: "id", hide: true },
      {
        field: "replenishmentOrderStatus",
        headerName: "Status",
        align: "center",
        headerAlign: "center",
        width: 125,
        editable: false,
      },
      {
        field: "replenishmentLocationId",
        headerName: "Replenishment Location Id",
        align: "center",
        headerAlign: "center",
        width: 200,
        editable: false,
      },
      {
        field: "destinationId",
        headerName: "Replenishment Order Id",
        align: "center",
        headerAlign: "center",
        width: 200,
        editable: false,
      },
      {
        field: "replenishmentOrder",
        headerName: "Destination Id",
        align: "center",
        headerAlign: "center",
        width: 200,
        editable: false,
      },
      {
        field: "totalProductQuantity",
        headerName: "Products Quantity",
        align: "center",
        headerAlign: "center",
        width: 150,
        editable: false,
      },
      {
        field: "UTCcreatedAt",
        headerName: "Order Create Date",
        align: "center",
        headerAlign: "center",
        width: 200,
        editable: false,
      },
    ],
    rows: props.gridData,
  };

  return (
    <div style={{ height: 300, width: "100%" }}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "replenishmentOrderStatus", sort: "asc" }],
          },
        }}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectedModel(newSelectionModel);
          props.handleSelectReplenishmentOrder(newSelectionModel);
        }}
        selectedModel={selectedModel}
        {...GridData}
        components={{
          Toolbar: GridToolbar,
        }}
      />
    </div>
  );
}
