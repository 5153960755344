import * as React from "react";
import { useContext } from "react";
import { UserContext } from "./context/UserContext.js";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import WALogo from "./WA_logo.png";
import Button from "@mui/material/Button";
import EdonLogo from "./edonLogoWhite.png";

import TextField from "@mui/material/TextField";
import HeaderMenus from "./Components/HeaderMenus.js";
import FlexLogo from "./flexe_Logo.png";
import MessageSnackBar from "./Components/MessageSnackBar";

//NOTE Children

import AdvanceShippingNoticeGrid from "./Components/AdvanceShippingNoticeGrid.js";
import ReceiptInboundShipments from "./Components/ReceiptInboundShipments.js";
import CreateCustomerOrders from "./Components/CreateCustomerOrders.js";
import FulfillCustomerOrders from "./Components/FulfillCustomerOrders.js";
import ViewCustomerOrders from "./Components/ViewCustomerOrders.js";

import Copyright from "./Components/Copyright.js";
import Unauthorized from "./Components/Unauthorized.js";


// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function Fulfillment(props) {
  const userData = useContext(UserContext);


  const pageTitle = "Fulfillment";

  //SECTION Message Bar Controls
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [messageSeverity, setMessageSeverity] = React.useState("success");
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSnackBar = (message, severity) => {
    setSnackBarMessage(message);
    setMessageSeverity(severity);
    setSnackbarOpen(true);
  };
  //!SECTION

  //! Testing WebSocket
  const [testSocketMessages,] = React.useState("First");
  const testWebSocket = (event) => {
    event.preventDefault();
    // console.log("event", event);
    // const data = new FormData(event.currentTarget);
    // console.log(data.get("message"));
    const addMessage = (message, sender) => {
      const messages = document.getElementById("messages");
      const messageItem = document.createElement("li");
      messageItem.innerText = message;
      if (sender === "client") {
        messageItem.setAttribute("className", "text-success");
      } else {
        messageItem.setAttribute("className", "text-warning");
      }
      messages.appendChild(messageItem);
    };

    window.addEventListener("DOMContentLoaded", (event) => {
      const socket = new WebSocket("ws://localhost:8000/ws");

      // Connection opened
      socket.addEventListener("open", function (event) {
        // Send message on form submission
        document.getElementById("form").addEventListener("submit", (event) => {
          event.preventDefault();
          const message = document.getElementById("message").value;
          addMessage(message, "client");
          socket.send(message);
          event.target.reset();
        });
      });

      // Listen for messages
      socket.addEventListener("message", function (event) {
        addMessage(event.data, "server");
      });
    });
  };

  //! Advanced Shipping notice

  const [viewAdvanceShippingNotice, setViewAdvanceShippingNotice] =
    React.useState(false);
  const [
    viewAdvanceShippingNoticeMessage,
    setviewAdvanceShippingNoticeMessage,
  ] = React.useState("Advance Shipping Notice");
  const [advanceShippingNoticeData, setAdvanceShippingNoticeData] =
    React.useState([]);
  const toggleAdvanceShippingNotice = () => {
    if (viewAdvanceShippingNotice) {
      setViewAdvanceShippingNotice(false);
      setviewAdvanceShippingNoticeMessage("Advance Shipping Notice");
      // setViewReplenishmentOrdersTable(false); // view the
      // setDisableReplenishmentOrdersButton(false);
    } else {
      setViewAdvanceShippingNotice(true);
      setviewAdvanceShippingNoticeMessage("Hide Advance Shipping Notice");
      getAdvancedShippingNotice();
    }
  };

  const getAdvancedShippingNotice = () => {
    const owner = "edon_owner";
    const user = "glenn.caudill@edonconnect.com";
    const destinationId = "SB-ATL";
    //! Redo with new database and API
    // axios
    //   .get(`/iot/advanceShippingNotice/${owner}/${user}/${destinationId}`)
    //   .then(function async(response) {
    //     // handle success
    //     // setAccessGridData(response.data);

    //     setAdvanceShippingNoticeData(response.data.data);
    //     // console.log("Advance Shipping Notice:", response.data.data);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
    //     handleSnackBar(errorMessage, "error");
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };

  //SECTION Receipt Inbound Replenishment Shipments
  const [viewReceiptInboundShipments, setViewReceiptInboundShipments] =
    React.useState(false);
  const [
    viewReceiptInboundShipmentsMessage,
    setViewReceiptInboundShipmentsMessage,
  ] = React.useState("Receipt Inbound Shipments");

  const toggleReceiptInboundShipments = () => {
    if (viewReceiptInboundShipments) {
      setViewReceiptInboundShipments(false);
      setViewReceiptInboundShipmentsMessage("Receipt Inbound Shipments");
      // setViewReplenishmentOrdersTable(false); // view the
      // setDisableReplenishmentOrdersButton(false);
    } else {
      setViewReceiptInboundShipments(true);
      setViewReceiptInboundShipmentsMessage("Hide Receipt Inbound Shipments");
    }
  };

  //!SECTION Receipt Inbound Replenishment Shipments

  //! View Customer  Orders
  const [viewCustomerOrders, setViewCustomerOrders] = React.useState(false);
  const [viewCustomerOrdersMessage, setViewCustomerOrdersMessage] =
    React.useState("View Customer Orders");
  const [customerOrderData, setCustomerOrderData] = React.useState([]);
  const [customerOrderDetail, setCustomerOrderDetail] = React.useState([]);

  const getCustomerOrders = () => {

    console.log("Thanks")

    //! redo with new database and API
    // axios
    //   .get(`/iot/customerorders/${owner}/${user}`)
    //   .then(function async(response) {
    //     // handle success
    //     // setAccessGridData(response.data);

    //     setCustomerOrderData(response.data.data);
    //     // console.log("replenishment orders:", response.data.data);
    //     // toggleAccessTable();
    //     // setDisableAccessButton(true);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error);
    //     //!Message bar
    //     var errorMessage = `Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`;
    //     handleSnackBar(errorMessage, "error"); // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function () {
    //     // always executed
    //   });
  };

  const handleSelectCustomerOrder = (replenishmentOrderId) => {
    // console.log(replenishmentOrderId)
    // console.log(customerOrderData)
    // console.log(customerOrderData.id)
    setCustomerOrderDetail([
      customerOrderData.find((x) => x.id === replenishmentOrderId[0]),
    ]);
  };

  const toggleCustomerOrders = () => {
    if (viewCustomerOrders) {
      setViewCustomerOrders(false);
      setViewCustomerOrdersMessage("View Customer Orders");
      // setViewReplenishmentOrdersTable(false); // view the
      // setDisableReplenishmentOrdersButton(false);
    } else {
      setViewCustomerOrders(true);
      setViewCustomerOrdersMessage("Hide Customer Orders");
      getCustomerOrders();
    }
  };

  //!  Create Customer Orders

  const [viewCreateCustomerOrders, setViewCreateCustomerOrders] =
    React.useState(false);
  const [viewCreateCustomerOrdersMessage, setViewCreateCustomerOrdersMessage] =
    React.useState("Create Customer Orders");
  const toggleCreateCustomerOrders = () => {
    if (viewCreateCustomerOrders) {
      setViewCreateCustomerOrders(false);
      setViewCreateCustomerOrdersMessage("Create Customer Orders");
    } else {
      setViewCreateCustomerOrders(true);
      setViewCreateCustomerOrdersMessage("Hide Create Customer Orders");
    }
  };

  const handleCloseCreateCustomerOrders = () => {
    toggleCreateCustomerOrders();
  };

  const [viewFulfillCustomerOrders, setViewFulfillCustomerOrders] =
    React.useState(false);
  const [
    viewFulfillCustomerOrdersMessage,
    setViewFulfillCustomerOrdersMessage,
  ] = React.useState("Fulfill Customer Orders");

  const toggleFulfillCustomerOrders = () => {
    if (viewFulfillCustomerOrders) {
      setViewFulfillCustomerOrders(false);
      setViewFulfillCustomerOrdersMessage("Fulfill Customer Orders");
      // setViewAccessTable(false);
      // setDisableAccessButton(false);
    } else {
      setViewFulfillCustomerOrders(true);
      setViewFulfillCustomerOrdersMessage("Hide Customer Order Fulfillment");
    }
  };

  //!SECTION

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        {/* Content*/}
        {/* Content*/}
        {/* Content*/}
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />

          <img src={EdonLogo} width={200} alt="Logo" />
          <Container
            overflow="auto"  //! Changed from scroll to auto 
            maxWidth="xl"
            sx={{ mt: 5, mb: 5, border: 1 }}
          >
            {userData.membershipCode === "0x12a6b5324" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 1 }}>
                <img src={WALogo} width={200} alt="Logo" />
              </Grid>
            )}
            {userData.membershipCode === "0x4ee11bfe" && ( //Warehouse Anywhere membership
              <Grid item sx={{ p: 1 }}>
                <img src={FlexLogo} width={150} alt="Logo" />
              </Grid>
            )}

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
              <MessageSnackBar
                messageBarMessage={snackBarMessage}
                messageBarOpen={snackbarOpen}
                handleMessageBarClosing={handleClose}
                messageSeverity={messageSeverity}
              />
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  <Typography align="center" variant={"h4"}>
                    {userData.company} Fulfillment
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {userData.email}
                  </Typography>

                  {!userData.services.includes("FF") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }
                </Paper>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0 }}
            >
              {/* Advance Shipping Notice */}
              {userData.userRole > 10 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleAdvanceShippingNotice}>
                        {" "}
                        {viewAdvanceShippingNoticeMessage}
                      </Button>
                      {viewAdvanceShippingNotice && (
                        <>
                          <AdvanceShippingNoticeGrid
                            gridData={advanceShippingNoticeData}
                          />
                        </>
                      )}
                    </Paper>
                  </Grid>
                </>
              )}

              {/* Inbound Shipment Receipt */}
              {userData.userRole > 10 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleReceiptInboundShipments}>
                        {" "}
                        {viewReceiptInboundShipmentsMessage}
                      </Button>
                      {viewReceiptInboundShipments && (
                        <>
                          <ReceiptInboundShipments
                            handleSnackBar={handleSnackBar}
                          />
                        </>
                      )}
                    </Paper>
                  </Grid>
                </>
              )}

              {/* View Customer Orders */}
              {userData.userRole > 5 && (
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleCustomerOrders}>
                        {" "}
                        {viewCustomerOrdersMessage}
                      </Button>

                      {/* Testing a Web socket */}
                      {viewCustomerOrders && (
                        <>
                          <ViewCustomerOrders
                            customerOrderData={customerOrderData}
                            customerOrderDetail={customerOrderDetail}
                            handleSelectCustomerOrder={
                              handleSelectCustomerOrder
                            }
                          />
                          {false && (
                            <>
                              <Box
                                component="form"
                                onSubmit={testWebSocket}
                                noValidate
                                sx={{ mt: 1 }}
                              >
                                <TextField
                                  margin="normal"
                                  required
                                  id="message"
                                  label="message"
                                  name="message"
                                  autoFocus
                                />
                                <Button
                                  align="center"
                                  type="submit"
                                  variant="contained"
                                  sx={{ mt: 3, mb: 2 }}
                                >
                                  Send Message
                                </Button>
                              </Box>

                              <Typography>{testSocketMessages}</Typography>

                              <div className="container">
                                <h1 className="my-3">Testing a Websocket</h1>
                                <form id="form">
                                  <div className="input-group mb-3">
                                    <input
                                      id="message"
                                      type="text"
                                      className="form-control"
                                      placeholder="Message"
                                      required
                                    ></input>
                                    <Button onClick={testWebSocket}>
                                      Send
                                    </Button>
                                  </div>
                                </form>
                                <ul id="messages"></ul>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Paper>
                  </Grid>
                </>
              )}
              {/*  Create Customer Orders */}
              {userData.userRole > 5 && userData.services.includes("FF") &&(
                <>
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleCreateCustomerOrders}>
                        {" "}
                        {viewCreateCustomerOrdersMessage}
                      </Button>

                      {/* Item Assignment Filters */}
                      {viewCreateCustomerOrders && (
                        <CreateCustomerOrders
                          handleCloseCreateCustomerOrders={
                            handleCloseCreateCustomerOrders
                          }
                          handleSnackBar={handleSnackBar}
                        />
                      )}
                    </Paper>
                  </Grid>

                </>
              )}

              <>
                {/*  Fulfill Customer Orders */}
                {userData.userRole > 5 && userData.services.includes("FF") && (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    lg={12}
                    align="center"
                    sx={{ alignContent: "center", border: 0 }}
                  >
                    <Paper
                      sx={{ p: 2, display: "flex", flexDirection: "column" }}
                    >
                      <Button onClick={toggleFulfillCustomerOrders}>
                        {" "}
                        {viewFulfillCustomerOrdersMessage}
                      </Button>

                      {/* Fulfill Customer Orders */}
                      {viewFulfillCustomerOrders && (
                        <FulfillCustomerOrders
                          toggleFulfillCustomerOrders={
                            toggleFulfillCustomerOrders
                          }
                          handleSnackBar={handleSnackBar}
                        />
                      )}
                    </Paper>
                  </Grid>
                )}
                {/* {viewAccessTable &&
                <Grid
                item
                xs={12}
                md={12}
                lg={12}
                align="center"
                sx={{ alignContent: "center", border: 0 }}
                >

                <SimpleGrid gridColumns={accessGridColumns} gridData={accessGridData} />

                </Grid>
                } */}
              </>
            </Grid>

            <Grid item border={0} xs={12} sm={12} md={12}>
              <Copyright sx={{ pt: 4, align: "center" }} />
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
