import * as React from 'react';

import Typography from '@mui/material/Typography';



export default function Unauthorize() {
  return (
    <>
    <Typography align="center" variant={"h4"} color="secondary">
     Your account does not include this service. 
    </Typography>
    <Typography align="center" color="secondary" variant={"h6"}>
     Please contact your adminstrator or
    </Typography>
    <Typography align="center" color="secondary" variant={"h6"}>
     email info@edonconnect.com for more information.
    </Typography>
    </>
  );
}