import * as React from 'react';
// import axios from 'axios';

import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import MessageSnackBar from './MessageSnackBar';

// import ItemAssociationReceipt from './ItemAssociationReceipt'
// import InboundShipments from './InboundShipments.js';
// import CustomerOrder from './CustomerOrder.js'
// import CustomerOrderDetail from "./CustomerOrderDetail.js"
// import { Typography } from '@mui/material';
// import ATLSaltBox from '../img/ATLSaltbox.png'
// import ViewReplenishmentOrdersGrid from './ViewReplenishmentOrdersGrid.js'
import ViewReplenishmentShipmentGrid from './ViewReplenishmentShipmentGrid.js';
import ViewReplenishmentShipmentDetailGrid from './ViewReplenishmentShipmentDetailGrid.js';



// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function CreateCustomerOrder(props) {

  //! Local SnackBar Messaging
  //SECTION Message Bar Controls
  // eslint-disable-next-line 
  const [snackBarMessage, setSnackBarMessage] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };
  
  // eslint-disable-next-line 
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  // eslint-disable-next-line 
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  //SECTION Select the Customer Location Id

  // const [customerLocationId, setCustomerLocationId ] = React.useState('');
  
  // const [ viewCustomerOrderDetail, setViewCustomerOrderDetail ] = React.useState(false);

  // const [ replenishmentShipments, setReplenishmentShipments ] = React.useState([]);

  // const handleSelectCustomerLocationId = (locationId) => {
  //     setCustomerLocationId(locationId);
  //     setViewCustomerOrderDetail(true);
  //     // console.log(locationId);
  // }
    
    // const locationInfo = 
    // {
    //   "DB-ATL-201": 
    // {
    //   id: 1,
    //   name: "Salt Box ",
    //   address: "1345 Seaboard Industrial Blvd",
    //   cityStateZip: "Atlanta, GA 30318",
    //   utlizingAddress: "1345 Seaboard Industrial Blvd Atlanta, GA 30318",
    //   image: ATLSaltBox
    // },
    //   "DB-ATL-571": 
    // {
    //   id: 2,
    //   name: "Salt Box ",
    //   address: "1345 Seaboard Industrial Blvd",
    //   cityStateZip: "Atlanta, GA 30318",
    //   utlizingAddress: "1345 Seaboard Industrial Blvd Atlanta, GA 30318",
    //   image: ATLSaltBox
    // },
    //   "DB-ATL-152": 
    // {
    //   id: 3,
    //   name: "Salt Box ",
    //   address: "1345 Seaboard Industrial Blvd",
    //   cityStateZip: "Atlanta, GA 30318",
    //   utlizingAddress: "1345 Seaboard Industrial Blvd Atlanta, GA 30318",
    //   image: ATLSaltBox
    // },
    //   "DB-ATL-042": 
    // {
    //   id: 4,
    //   name: "Salt Box ",
    //   address: "1345 Seaboard Industrial Blvd",
    //   cityStateZip: "Atlanta, GA 30318",
    //   utlizingAddress: "1345 Seaboard Industrial Blvd Atlanta, GA 30318",
    //   image: ATLSaltBox
    // },
    // };
              


  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "left",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>
    {/* {showShipButton &&
    <Grid container sx={{alignContent: 'left', justifyContent:"flex-end",  align:"left", border:0 } }>
          <Button
            align="left"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            onClick={completeShipment}
          >
            Complete Shipment
          </Button>
    </Grid>
  } */}


      
    <Grid item xs={6} md={6} lg={6} sx={{alignContent: 'center', border:0, p: 2} }>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <ViewReplenishmentShipmentGrid gridData={props.shipmentData} handleSelectReplenishmentShipment={props.handleSelectReplenishmentShipment} />
        </Paper>
    </Grid>

  { true &&
  <>
    <Grid item xs={6} md={6} lg={6} sx={{alignContent: 'center', border:0, p:2} }>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            <ViewReplenishmentShipmentDetailGrid gridData={props.shipmentDetailData} />
        </Paper>
    </Grid>


  </>
}

    </Grid>
  );
}