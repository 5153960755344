import * as React from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';


import Box from '@mui/material/Box';

import { createTheme, ThemeProvider } from '@mui/material/styles';

//! signing on




// import userEvent from '@testing-library/user-event';
import Copyright from './Components/Copyright';
import Stack from '@mui/material/Stack';
import EdonLogo from './edonLogoWhite.png'
import { Typography } from '@mui/material';
// import Toolbar from '@mui/material/Toolbar';


// const theme = createTheme();

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });


  
  // function Logo(props) {
  
  //   return (
  //     <div style={{textAlign: "center"}}>
  //       <img src={EdonLogo} alt="Logo"/>
  //     </div>
  //   );
  // }

export default function FrontPage(props) {

  React.useEffect(() => {
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if (isMobile) {
      const timeout = setTimeout(() => {
        // Fallback to web version after 1 second if app does not open
        window.location.href = 'https://edonconnect.tech/frontpage';
      }, 2000);

      // Try opening the mobile app deep link
      const hiddenLink = document.createElement('a');
      hiddenLink.href = 'edon://open';
      hiddenLink.style.display = 'none';
      document.body.appendChild(hiddenLink);
      hiddenLink.click();

      // Cleanup timeout on component unmount
      return () => clearTimeout(timeout);

    } else {
      // Redirect to web for non-mobile users
      const timeout = setTimeout(() => {
      window.location.href = 'https://edonconnect.tech';
      }, 2000);
      // Cleanup timeout on component unmount
      return () => clearTimeout(timeout);
    }
  }, []);


  return (
    <ThemeProvider theme={darkTheme}>

      <Box sx={{ display: 'flex', height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
        <CssBaseline />

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border:0,
          }}
        >
            <Stack>
            <Grid item sx={{pt:2}}>
                {/* <img src={EdonLogo} alt="Logo" style={{width: "100%"}} /> */}
                <Typography variant="h1" align="center" sx={{ mt: 1, mb: 1, textAlign:"center" }}>
                    Open Mobile App Scan & Go
                </Typography>
            </Grid>
            </Stack>
            </Box>
      </Box>
    </ThemeProvider>
  );
}