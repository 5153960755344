import * as React from "react";

// import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";
// import FormControlLabel from "@mui/material/FormControlLabel";
// import Checkbox from "@mui/material/Checkbox";

// import axios from "axios";

// import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material//MenuItem";

// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import MessageSnackBar from "./MessageSnackBar";

//NOTE Child
import ReplenishmentOrderGrid from "./ReplenishmentOrderGrid";

// const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });

export default function ReplenishOrderInfo(props) {
  //! Local SnackBar Messaging
  // const [snackBarMessage, setSnackBarMessage] = React.useState("");
  // const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  // const handleClose = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setSnackbarOpen(false);
  // };

  // //SECTION Message Bar Controls
  // const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  // const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  // const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  // const handleMessageBarClosing = () => {
  //   setMessageBarOpen(false);
  // };
  //!SECTION

  

  //SECTION Form Validation
  //! Form Validation
  const [ setButtonDisable] = React.useState(true);
  const [ setValidFields] = React.useState({
    productId: false,
    serialNum: false,
    quantity: false,
    userFirstName: false,
    userLastName: false,
    userEmail: false,
  });
  const [ setHelperText] = React.useState({
    productId: "SKU Number",
    serialNum: "Unique Identifier",
    quantity: false,
    userFirstName: "",
    userLastName: "",
    userEmail: "",
  });
  //    NOTE Review
  const [setValidFirstName] = React.useState(false);

  const [ setValidEmail] = React.useState(false);


  const [validProductNumber, setValidProductNumber] = React.useState(true);
  const [validSerialNumber, setValidSerialNumber] = React.useState(true);

  // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

  // const formValidation = (e) => {
  //   e.preventDefault();
  //   // console.log(e.target.id);
  //   // console.log(e.target.value);
  //   switch (e.target.id) {
  //     case "userEmail":
  //       var email = e.target.value;
  //       var pattern =
  //         /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  //       var emailValid = email.match(pattern);
  //       if (emailValid) {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           userEmail: false,
  //         }));
  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           userEmail: "",
  //         }));
  //         setValidEmail(true);
  //       } else {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           userEmail: true,
  //         }));

  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           userEmail: "Need a valid email adress",
  //         }));
  //         setValidEmail(false);
  //       }
  //       break;

  //     case "userFirstName":
  //       if (e.target.value.length > 1) {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           userFirstName: false,
  //         }));
  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           userFirstName: "",
  //         }));
  //         setValidFirstName(true);
  //       } else {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           userFirstName: true,
  //         }));
  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           userFirstName: "First Name needs at least two characters",
  //         }));
  //         setValidFirstName(false);
  //       }
  //       break;

  //     case "productId":
  //       // NOTE check to see if it is a valid Product Id
  //       if (e.target.value.length > 1) {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           productId: false,
  //         }));
  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           productId: "",
  //         }));
  //         setValidProductNumber(true);
  //       } else {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           productId: true,
  //         }));
  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           productId: "This is not a valid Product Number",
  //         }));
  //         setValidProductNumber(false);
  //       }
  //       break;
  //     case "serialNum":
  //       if (e.target.value.length > 1) {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           serialNum: false,
  //         }));
  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           serialNum: "",
  //         }));
  //         setValidSerialNumber(true);
  //       } else {
  //         setValidFields((prevState) => ({
  //           ...prevState,
  //           serialNum: true,
  //         }));
  //         setHelperText((prevState) => ({
  //           ...prevState,
  //           serialNum: "This is not a valid Serial Number",
  //         }));
  //         setValidSerialNumber(false);
  //       }
  //       break;

  //     default:
  //       break;
  //   }
  // };

  // NOTE Review
  React.useEffect(() => {
    if (validProductNumber && validSerialNumber) {
      setButtonDisable(false);
    } else {
      setButtonDisable(true);
    }
  }, [validProductNumber, validSerialNumber]);
  //!SECTION

  // SECTION Replenishment Orders
  const [products, setProducts] = React.useState([]);
  // const [ replenishmentOrders, setReplenishmentOrder ] = React.useState(
  //         {"SB-ATL-RO-001" : [
  //           {
  //             productId: "EC-ORS-12-TST",
  //             qty: 1,
  //             status: "Not Filled"
  //           }
  //         ]
  //       }
  //         )

  const handleRemoveProduct = async (currentProducts) => {
    setProducts([...currentProducts]);
  };

  //SECTION Select Destination
  //   const handleSelectDestination = (event) => {
  //       console.log("value", event.target.value)
  //       console.log(event.target)
  //       setSelectedDestination(event.target.value);
  //       setViewReplenishmentOrder(true);
  //       props.handleSelectedDestination(event.target.value)
  //       // setViewDestinationAddress(true)
  //     // setSelectedAccount(event.target.value);
  //     // setValidSelectedAccount(true);
  //   };
  //     const [selectedDestination, setSelectedDestination ] = React.useState("");
  //     const destinationList = [
  //     {
  //     id: 1,
  //     value: "SB-ATL",
  //     label: "Salt Box Atlanta",
  //     },
  //     {
  //     id: 2,
  //     value: "SB-Chicago",
  //     label: "SB-Chicago",
  //     },
  //     {
  //     id: 3,
  //     value: "SB-NY",
  //     label: "SB-NY",
  //     },
  //     {
  //     id: 4,
  //     value: "SB-Miami",
  //     label: "SB-Miami",
  //     },
  //     {
  //     id: 5,
  //     value: "Other",
  //     label: "Other",
  //     },
  // ];
  //!SECTION select Destination

  const [viewOrderDetail, setViewDetailOrder] = React.useState(false);
  //SECTION Select Replenishment Order

  const handleSelectReplenishmentOrder = (event) => {
    setSelectedReplenishmentOrder(event.target.value);
    setViewDetailOrder(true);
    props.handleViewFulfillmentDetail(true);
    props.handleReplenishmentOrderSelected(event.target.value);

    // setViewDestinationAddress(true)
    // setSelectedAccount(event.target.value);
    // setValidSelectedAccount(true);
  };
  const [selectedReplenishmentOrder, setSelectedReplenishmentOrder] =
    React.useState("");
  //     const replenishmentOrderList = [
  //     {
  //     id: 1,
  //     value: "SB-ATL-RO-001",
  //     label: "SB-ATL-RO-001",
  //     },
  //     {
  //     id: 2,
  //     value: "SB-ATL-RO-002",
  //     label: "SB-ATL-RO-002",
  //     },
  //     {
  //     id: 3,
  //     value: "SB-ATL-RO-005",
  //     label: "SB-ATL-RO-005",
  //     },
  //     {
  //     id: 4,
  //     value: "SB-ATL-RO-003",
  //     label: "SB-ATL-RO-003",
  //     },
  //     {
  //     id: 5,
  //     value: "SB-ATL-RO-004",
  //     label: "SB-ATL-RO-004",
  //     },
  // ];
  //!SECTION select Destination

  //NOTE review
  //SECTION Axios Create User
  const handleAddProduct = async (event) => {
    event.preventDefault();
    // console.log("What the Fuck")
    const formData = new FormData(event.currentTarget);
    const productId = formData.get("productId");
    const serialNum = formData.get("serialNum");
    var newId = products.length + 1;
    var addProduct = {
      id: newId,
      productId: productId,
      serialNum: serialNum,
    };
    setProducts((prevState) => [...prevState, addProduct]);

    // console.log("data", data)
    // await axios({
    //   method: "post",
    //   url: `/adminstration/addplatformuser/`,
    //   data: data,
    // })
    //   .then(function (response) {
    //     // Message about the User account
    //     // console.log(response.data);
    //     setSnackBarMessage(response.data);
    //     setSnackbarOpen(true);
    //   })
    //   .catch(function (error) {
    //     // handle error
    //     console.log(error.response.data.detail);
    //     //!Message bar
    //     setMessageBarMessage(`Oh no! Something went wrong. ${error.response.data.detail} You can email info@edonconnect.tech for help. ${error.response.data.detail}`);
    //     setMessageBarOpen(true);
    //     setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
    //   })
    //   .then(function (response) {
    //     // Send the email
    //     if (sendUserEmail) {
    //       // console.log("Account Infor", assignAccount);
    //       props.sendUserInvite(userEmail, assignAccount, userFirstName);
    //     }
    //   });
  };
  //!SECTION

  //NOTE review
  //SECTION Axios Create User
  //   const handleCreatePlatformUser = async (event) => {
  //     event.preventDefault();
  //     const formData = new FormData(event.currentTarget);
  //     const assignAccount = formData.get("assignAccount");
  //     const userEmail = formData.get("userEmail");
  //     const userFirstName = formData.get("userFirstName");
  //     const userLastName = formData.get("userLastName");
  //     const userRole = parseInt(formData.get("userRole"));
  //     const creatorEmail = userData.email;
  //     var data = {
  //       creatorEmail: creatorEmail,
  //       userFirstName: userFirstName,
  //       userLastName: userLastName,
  //       userEmail: userEmail,
  //       userRole: userRole,
  //       accountCode: assignAccount,
  //     };
  //     // console.log("Sending Data", data)

  //     // console.log("data", data)
  //     await axios({
  //       method: "post",
  //       url: `/adminstration/addplatformuser/`,
  //       data: data,
  //     })
  //       .then(function (response) {
  //         // Message about the User account
  //         // console.log(response.data);
  //         setSnackBarMessage(response.data);
  //         setSnackbarOpen(true);
  //       })
  //       .catch(function (error) {
  //         // handle error
  //         console.log(error.response.data.detail);
  //         //!Message bar
  //         setMessageBarMessage(`Oh no! Something went wrong. ${error.response.data.detail} You can email info@edonconnect.tech for help. ${error.response.data.detail}`);
  //         setMessageBarOpen(true);
  //         setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
  //       })
  //       .then(function (response) {
  //         // Send the email
  //         if (sendUserEmail) {
  //           // console.log("Account Infor", assignAccount);
  //           props.sendUserInvite(userEmail, assignAccount, userFirstName);
  //         }
  //       });
  //   };
  //!SECTION




  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 1,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <Typography component="h1" variant="h5">
        Replenishment Order Information
      </Typography>

      <Box
        component="form"
        onSubmit={handleAddProduct}
        noValidate
        sx={{ mt: 1 }}
      >
        {true && (
          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="selectReplenishmentOrder"
            label="Replenishment Order"
            value={selectedReplenishmentOrder}
            onChange={handleSelectReplenishmentOrder}
            type="text"
            id="selectReplenishmentOrder"
            helperText="Select Replenishment Order"
          >
            {props.replenishmentOrderList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>

      {viewOrderDetail && (
        <ReplenishmentOrderGrid
          gridData={props.replenishmentOrder}
          handleRemoveProduct={handleRemoveProduct}
        />
      )}
    </Grid>
  );
}
