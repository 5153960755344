import * as React from 'react';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';

// import axios from 'axios';

// import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
// import MenuItem from '@mui/material//MenuItem';

// import { createTheme, ThemeProvider } from '@mui/material/styles';

// import Snackbar from '@mui/material/Snackbar'
// import MuiAlert from '@mui/material/Alert';
// import MessageSnackBar from './MessageSnackBar';

//NOTE Child
import ReplenishmentShipmentGrid from './ReplenishmentShipmentGrid';




// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function ReplenishmentProductInfo(props) {

    const [ serialNumberOption ] = React.useState(true);
    const [ productQuantityOption] = React.useState(false);


   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "productId": false,
        "serialNum": false,
        "quantity": false,
        "userFirstName": false,
        "userLastName": false,
        "userEmail": false,
        
    });
    const [ helperText, setHelperText ] = React.useState(
        {
        "productId":"SKU Number",
        "serialNum":"Unique Identifier",
        "quantity": false,
        "userFirstName":"",
        "userLastName":"",
        "userEmail":"",
       });
    //    NOTE Review
    // const [ validFirstName, setValidFirstName ] = React.useState(false);
    // const [ validLastName, setValidLastName ] = React.useState(false);
    // const [ validEmail, setValidEmail ] = React.useState(false);
    // const [ validSeletedRole, setValidSelectedRole ] = React.useState(false);
    // const [ validSeletedAccount, setValidSelectedAccount ] = React.useState(false);


    const [ validProductNumber, setValidProductNumber ] = React.useState(false);
    const [ validSerialNumber, setValidSerialNumber ] = React.useState(false);
    
    // ! Get from database
    // ! Get from database
    const [ allValidSerialNumbers ] = React.useState([
      "LNP001",
      "BHD001",
      "KBG002",
      "APS001",
      "ORS001",
      "ARF001"
    ])


    // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);
       //NOTE Move to another file
    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      // console.log(props.replenishmentOrder[0].productId);
      switch (e.target.id){

        case 'productId': 
        // NOTE check to see if it is a valid Product Id
          // if (props.replenishmentOrder.includes(e.target.value)){
          if (e.target.value === props.replenishmentOrder[0].productId ){
            setValidFields(prevState => ({
              ...prevState,
              "productId": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                productId: "",
                }));
              setValidProductNumber(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "productId": true}));
              setHelperText(prevState => ({
                ...prevState,
                productId: "This product number is not on the order or not valid.",
                }));
              setValidProductNumber(false)
          }
          break;
        case 'serialNum': 
          if (allValidSerialNumbers.includes(e.target.value)){
            setValidFields(prevState => ({
              ...prevState,
              "serialNum": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "",
                }));
              setValidSerialNumber(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "serialNum": true}));
              setHelperText(prevState => ({
                ...prevState,
                serialNum: "This is not a valid Serial Number",
                }));
              setValidSerialNumber(false)
          }
          break;

        default:
          break;
      }
    }

    // NOTE Review
    React.useEffect(() => {
      if (validProductNumber && validSerialNumber ) {
        setButtonDisable(false);
      }else{
        setButtonDisable(true);
      }
    },[ validProductNumber, validSerialNumber ]
    )
    //!SECTION

    const [ products, setProducts ] = React.useState([])
    const productIdInput = React.useRef(null)
    const serialNumInput = React.useRef(null)

    const handleRemoveProduct = async (currentProducts) => {
        setProducts([...currentProducts])
    };

    //NOTE review
    //SECTION Axios Create User
  const handleAddProduct = async (event) => {
    event.preventDefault();
    // console.log("What the Fuck")
    const formData = new FormData(event.currentTarget);
    const productId = formData.get("productId");
    const serialNum = formData.get("serialNum");
    var newId = products.length + 1
    var addProduct = {
        "id":newId,
        "productId": productId,
        "serialNum": serialNum,
    }   
    props.handleReplenishmentOrderProductAdd(addProduct);
    setProducts(prevState => ([
        ...prevState,
        addProduct
    ]));
    productIdInput.current.value = "";
    serialNumInput.current.value = "";
    //! if there is one product disable add button
    setButtonDisable(true);
  };
  //!SECTION




  return (

    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 1,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >

        <Typography component="h1" variant="h5">
          Shipment Product Detail
        </Typography>

       { true && 
        <Box
          component="form"
          onSubmit={handleAddProduct}
          noValidate
          sx={{ mt: 1 }}
        >

          <TextField
            error = {validFields.productId}
            helperText={helperText.productId}
            inputRef={productIdInput}
            margin="normal"
            required
            fullWidth
            name="productId"
            label="Product Number"
            type="text"
            id="productId"
            onChange={(e) => formValidation(e)}
          />
        {serialNumberOption &&
          <TextField
            error = {validFields.serialNum}
            helperText={helperText.serialNum}
            inputRef={serialNumInput}
            margin="normal"
            fullWidth
            required
            name="serialNum"
            label="Serial Number"
            type="text"
            id="serialNum"
            onChange={(e) => formValidation(e)}
          />
          }
        { productQuantityOption &&
          <TextField
            error = {validFields.quantity}
            helperText={helperText.quantity}
            margin="normal"
            fullWidth
            disabled
            required
            name="quantity"
            label="Quantity"
            type="number"
            id="Quantity"
            onChange={(e) => formValidation(e)}
          />
        }

          <Button
            disabled={buttonDisable}
            align="left"
            type="submit"
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Add Product to Shipment
          </Button>

        </Box>
        }

          <ReplenishmentShipmentGrid  gridData = {products} handleRemoveProduct={handleRemoveProduct}/>

    </Grid>
  );
}