import * as React from 'react';
import axios from 'axios';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';
import {useAuth0 } from "@auth0/auth0-react";

import { Visibility } from '@mui/icons-material';
import { VisibilityOff } from '@mui/icons-material';

// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Paper from '@mui/material/Paper';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import EdonWidget from './img/edon Connect Logo only.png'
// import MuiLink from '@mui/material/Link'
//! signing on




// import userEvent from '@testing-library/user-event';
import { useHistory } from "react-router-dom";
import Copyright from './Components/Copyright.js';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { IconButton, Input, InputAdornment } from '@mui/material';





const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert sx={{width: '100%'}} elevation={6} ref={ref} variant="filled" {...props} />;
  });

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

const vertical ='top';
const horizontal ='center';

export default function PasswordSet(props) {
    const userData = React.useContext(UserContext);
    const storedHeaders = sessionStorage.getItem('headers');
    const headers = storedHeaders ? JSON.parse(storedHeaders) : {};
    const history = useHistory();
    const { logout } = useAuth0();





    const [ dialogOpen, setDialogOpen ] = React.useState(false);
    const [ enteredEmail, setEnteredEmail ] = React.useState('');
    // const [ confirmedEmail, setConfirmedEmail ] = React.useState('');
    const [ confirmedPassword, setConfirmedPassword ] = React.useState('');


  
    // const handleCloseDialog = (event) => {
    //   setDialogOpen(false);
    //   // console.log(enteredEmail, confirmedPassword);
    //   props.createUserPassword( enteredEmail, confirmedPassword);
    //   // history.push('/');
    // };

    const onEmailChange = (event) => {
      setEnteredEmail(event.target.value);
    }
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpenMessage(false);
    };

    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validPassword, setValidPassword ] = React.useState(false);
    const [ badPassword, setBadPassword ] = React.useState(false);   
    const [ validPassword2, setValidPassword2 ] = React.useState(false);   
    const [ badPassword2, setBadPassword2 ] = React.useState(false);  
    const [ password, setPassword ] = React.useState('');
    const [ password2, setPassword2 ] = React.useState('');
    const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;

    const formValidation =  (e) => {
      e.preventDefault();
      switch (e.target.id){   
        case 'password': 
          var passwordValue = e.target.value;
          // console.log(password);
          // const testPassword = "Nolan_soccer1!";
          // console.log("testPassword", testPassword);
          // console.log("Test password valid:", pattern.test(testPassword)); // Should log true
          // console.log(pattern);
          var passwordValid = pattern.test(passwordValue);
          // console.log(passwordValid);

          // console.log(validPassword, validPassword2);
          if (passwordValid){
            // setValidFields(prevState => ({
            //   ...prevState,
            //   "userEmail": false,
            //   }));
            //   setHelperText(prevState => ({
            //     ...prevState,
            //     userEmail: "",
            //     }));
              setPassword(e.target.value);
              setValidPassword(true);
              setBadPassword(false)
            }else{
              // setValidFields(prevState => ({
              //   ...prevState,
              //   "userEmail": true}));
  
              // setHelperText(prevState => ({
              //   ...prevState,
              //   userEmail: "Need a valid email adress",
              //   }));
              setValidPassword(false);
              setBadPassword(true);
          }
          break;
        case 'password2':
          var password2 = e.target.value; 
          if (password2 === password){
              setValidPassword2(true);
              setBadPassword2(false);            
            }else{
              setValidPassword2(false)
              setBadPassword2(true);            
          }
          default:
            break;
      }
    }
  
    React.useEffect(() => {
      if (validPassword && validPassword2) {
        setButtonDisable(false);
      }else{
        setButtonDisable(true);
      }
    },[ validPassword, validPassword2]
    )
    const [seePassword, setSeePassword] = React.useState(false);

    const [viewPasswordMessage, setViewPasswordMessage] = React.useState("View Password");
    
    const handleChangePassword = (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const password = data.get('password')
        const password2 = data.get('password2')
        const passwordLength = password.length;

        //! Check the password
        var error = false;
        if (password){
          const samePassword = (password === password2);
          if(passwordLength<8){
            setMessageSeverity('error');
            setUserMessage("Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character");
            setOpenMessage(true);
            error = true;
          }
          if(!samePassword){
            setUserMessage("Passwords do not match.");
            setOpenMessage(true);
            error = true;
          }
        };

        if (!error){
          // console.log(password, userData.email,headers);
          changePassword(userData.email, password);
        }else{
          console.log("error");}
    };
    const changePassword = async (email, password) => {
      const data = {
        userEmail: email,
        password: password,
      };


      await axios.patch('security/resetplatformoktauserpassword', data, { headers: headers })
      .then((response) => {
        setMessageSeverity('success');
        setUserMessage("Password has been changed. Please log in with your new password.");
        setOpenMessage(true);
        //remove user from local storage
        localStorage.clear();
        //remove headers from session storage
        sessionStorage.clear();
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        });

        // setTimeout(() => {
        //   history.push('/');
        // }, 2000);
      })
      .catch((error) => {
        setMessageSeverity('error');
        setUserMessage("There was an error changing the password. ", error);
        setOpenMessage(true);
      });


    }

    const [ openMessage, setOpenMessage ] = React.useState(false);
    const [ userMessage, setUserMessage] = React.useState('');
    const [ messageSeverity, setMessageSeverity ] = React.useState('error');

    const passwordViewer = () => {
      if (seePassword){
        setViewPasswordMessage("View Password");
        setSeePassword(false);
      }else{
        setViewPasswordMessage("Hide Password");
        setSeePassword(true);
      }
    }

    const goHome = () => {
      history.push('/');
    }


  return (

    <ThemeProvider theme={darkTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {/* <Avatar sx={{ m: 1, bgcolor: '#68a0d7' }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <img src={EdonWidget} width={50}  alt="Logo" />
          <Typography component="h1" marginTop={2} variant="h5">
            Set Password
          </Typography>

            {/* Dialog box in case the email is not in local storage */}
            {/* <Dialog open={dialogOpen} onClose={handleClose}>
              <DialogTitle>Verify Your Email</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Please enter your email address here. 
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  id="emailEntered"
                  label="Email Address"
                  type="emailEntered"
                  fullWidth
                  variant="standard"
                  onChange={onEmailChange}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog}>Submit Email</Button>
              </DialogActions>
            </Dialog> */}

          <Box component="form" onSubmit={handleChangePassword} noValidate sx={{ mt: 1 }}>

            <Snackbar sx={{width: '50%'}} anchorOrigin={{vertical, horizontal}} open={openMessage} autoHideDuration={6000} onClose={handleClose}>
              <Alert sx={{width: '100%'}}  onClose={handleClose} severity={messageSeverity}>{userMessage}</Alert>
            </Snackbar>

            <TextField
              error={badPassword}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type={seePassword ? 'test': 'password'}
              id="password"
              autoComplete="current-password"
              onChange={(e) => formValidation(e)}
              // endAndornment={
              //   <InputAdornment position="end">
              //     <IconButton
              //     edge="end"
              //     >
              //       {true ? <Visibility /> : <VisibilityOff />}
              //     </IconButton>
              //   </InputAdornment>
              // }
              />
            <TextField
              error={badPassword2}
              margin="normal"
              required
              fullWidth
              name="password2"
              label="Repeat Password"
              type={seePassword ? 'test': 'password'}
              id="password2"
              autoComplete="current-password"
              onChange={(e) => formValidation(e)}
            />
            <Button 
              align="center"
              onClick={passwordViewer}
              fullWidth
              sx={{ mt: 0, mb: 0 }} 
              >
                {viewPasswordMessage}
              </Button>

            <Button
              align="center"
              disabled={buttonDisable}
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} 
            >
              Set Password
            </Button>
            <Grid container>

              {true &&
              <Grid item xs>
                <Typography variant="body2" align="center">
                "Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character."
                </Typography>
              </Grid>
                }
            <Button 
              align="center"
              onClick={goHome}
              fullWidth
              sx={{ mt: 0, mb: 0 }} 
              >
                Go Home
              </Button>

              <Grid sm={12} md={12} lg={12} border={0} item>

              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
);
}