import * as React from 'react';

// import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { DataGridPro, GridToolbar, } from '@mui/x-data-grid-pro';
// import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseInfo } from '@mui/x-data-grid-pro';

import { dataGridStyles } from './dataGridStyles';


const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);



export default function SimpleGridShippedItems(props) {

    const [selectedModel, setSelectedModel ] = React.useState();
    const  GridData  = {
      columns: props.gridColumns, 
      rows: props.gridData
      };

  return (

    <div style={{ height: 400, width: '100%'}}>
      <DataGridPro
          sx={dataGridStyles.root}
          pagination

        // pinnedColumns={{left:['itemNumber']}}
        initialState={{
            pageSize: 25,
            columns: {
              columnVisibilityModel: props.visibleColumns,
            },
            filter: {
              filterModel: props.filterModel,
            },
            sorting: {
              sortModel: props.sortModel,
            },
            pinnedColumns: props.pinnedModel,

          }}
        slots={{toolbar: GridToolbar}}

        onSelectionModelChange={(newSelectionModel) => {
            setSelectedModel(newSelectionModel)
            // props.handleMarketFileNames(newSelectionModel)
        }}
            selectedModel={selectedModel}
            {...GridData} 

      />
    </div>

  );
};