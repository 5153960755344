import * as React from 'react';
import { useContext } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';


import axios from 'axios';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material//MenuItem';
// import { createTheme} from '@mui/material/styles';

import { UserContext } from '../context/UserContext';




export default function CreateRegion(props) {

  const userData = useContext(UserContext);
  const storedHeaders = sessionStorage.getItem('headers');
  const headers = storedHeaders ? JSON.parse(storedHeaders) : {};

  const title = "Create A Region";
  //&Account DropDown
  //&Account DropDown
  //&Account DropDown
  //! Data and Select for Account Dropdown
  const [selectedAccount, setSelectedAccount] = React.useState('');
  //! accountList comes from Dashboard
  const handleAccountSelect = (event) => {
    setSelectedAccount(event.target.value);
  };



   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);


    const [ validFields, setValidFields ] = React.useState(
      {
        "regionName": false,
        "countryName": false,
     });

    const [ helperText, setHelperText ] = React.useState(
      {
        "regionName":"Region Name",
        "countryName":"Country Name",
        "regionDesc":"Description of Region",
    
       });


    const [ validRegionName, setValidRegionName ] = React.useState(false);
    const [ validCountryName, setValidCountryName ] = React.useState(false);

    React.useEffect(() => {
        if (validRegionName && validCountryName) {
          setButtonDisable(false);
        }else{
          setButtonDisable(true);
        }
      },[ validRegionName, validCountryName]
      )
 

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){

        case 'regionName': 
          var regionName = e.target.value;
          // eslint-disable-next-line 
          if (regionName.length > 1){
            setValidFields(prevState => ({
                ...prevState,
                "regionName": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            regionName: "Region Name",
            }));
            setValidRegionName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "regionName": true}));

              setHelperText(prevState => ({
                ...prevState,
                regionName: "Name needs at least two characters",
                }));
              setValidRegionName(false);
          }
          break;

        case 'countryName':
          var countryName = e.target.value;
          // eslint-disable-next-line 
          if (countryName.length > 1){
            setValidFields(prevState => ({
                ...prevState,
                "countryName": false,
                }));
            setHelperText(prevState => ({
            ...prevState,
            countryName: "Country Name",
            }));
            setValidCountryName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "countryName": true}));

              setHelperText(prevState => ({
                ...prevState,
                countryName: "Country Name needs at least two characters",
                }));
              setValidCountryName(false);
          }
          break;
        // case 'itemCost':           
        //     var itemCost = e.target.value;
        //     // eslint-disable-next-line
        //     if (itemCost.length > 3){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "itemCost": false, 
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         itemCost: "Item Cost",
        //         }));
        //         setValidItemCost(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "itemCost": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             itemCost: "Cost needs at least five characters",
        //             }));
        //         setValidItemCost(false);
        //     }
        //     break;
        // case 'size':
        //     var size = e.target.value;
        //     // eslint-disable-next-line
        //     if (size.length > 4){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "size": false,
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         size: "Size",
        //         }));
        //         setValidSize(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "size": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             size: "State needs at least two characters",
        //             }));
        //         setValidSize(false);
        //     }
        //     break;
        // case 'weight':
        //     var weight = e.target.value;
        //     // eslint-disable-next-line
        //     if (weight.length > 0){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "weight": false,
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         weight: "Product Weight",
        //         }));
        //         setValidWeight(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "weight": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             weight: "Weight needs at least five characters",
        //             }));
        //         setValidWeight(false);
        //     }
        //     break;
        // case 'manufacture':
        //     var manufacture = e.target.value;

        //     if (manufacture.length > 3){
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "manufacture": false,
        //             }));
        //         setHelperText(prevState => ({
        //         ...prevState,
        //         manufacture: "Product Manufacture",
        //         }));
        //         setValidManufacture(true);
        //     }else{
        //         setValidFields(prevState => ({
        //             ...prevState,
        //             "manufacture": true}));
        //         setHelperText(prevState => ({
        //             ...prevState,
        //             manufacture: "Manufacture needs at least six character",
        //             }));
        //         setValidManufacture(false);
        //     }
        //     break;


        default:
          break;
      }
    }


    //!SECTION
    


    //SECTION Axios Create Product
  const handleCreateRegion = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    var assignAccount =formData.get("assignAccount");
    var regionName = formData.get("regionName");
    var countryName = formData.get("countryName");
    var regionDesc = formData.get("regionDesc");

    if (assignAccount === "" || assignAccount === null || assignAccount === undefined){
      assignAccount = userData.accountCode;
    }
    
    var data = {
        accountCode: assignAccount,
        regionName: regionName,
        countryName: countryName,
        regionDesc: regionDesc,
    };

    await axios(
      {
      method: "post",
      url: `/MobileAdmin/createregion/`,
      data: data,
      headers: headers
      }
      )
      .then(function (response) {
        // console.log("response", response.data);
        props.handleMessageBarOpen(response.data.message, "success");
        props.toggleViewCreateRegion();
      })
      .catch(function (error) {
        // handle error
        // console.log(error.response);
        props.handleMessageBarOpen(error.response.data.detail, "error");
      })


  };
  //!SECTION


  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      {/* <Stack direction="column" alignItems="center" spacing={2}> */}
        <Typography component="h1" variant="h5">
          {title}
        </Typography>

        <Box
          component="form"
          onSubmit={handleCreateRegion}
          noValidate
          overflow="auto"
          sx={{ mt: 1 }}
        >
          {userData.userRole === 10 &&
          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="assignAccount"
            label="Account"
            value={selectedAccount}
            onChange={handleAccountSelect}
            type="number"
            id="assignAccount"
            helperText="Select an Account"
          >
            {props.accountList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          }

          <TextField
            error = {validFields.regionName}
            helperText={helperText.regionName}
            margin="normal"
            required
            fullWidth
            name="regionName"
            label="ex. MidWest"
            type="text"
            id="regionName"
            onChange={(e) => formValidation(e)}
          />
          <TextField  
            error = {validFields.countryName}
            helperText={helperText.countryName}
            margin="normal"
            required
            fullWidth
            name="countryName"
            label="ex. USA"
            type="text"
            id="countryName"
            onChange={(e) => formValidation(e)}
          />
          <TextField

            helperText={helperText.regionDesc}
            margin="normal"
            fullWidth
            name="regionDesc"
            label="ex. From Michigan to Ohio"
            type="text"
            id="regionDesc"
          />
         
          

          <Button
            disabled={buttonDisable}
            align="center"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create Region
          </Button>
          
          {/* <FormControlLabel
            control={<Checkbox defaultChecked onChange={handleEmailCheckbox} />}
            label="Send Mobile User Notification Email"
          /> */}
        </Box>
      {/* </Stack> */}
    </Grid>
  );
}