import * as React from 'react';

import { DataGrid } from '@mui/x-data-grid';
import {
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
} from '@mui/x-data-grid';

// import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function ReplenishmentShipmentGrid(props) {

    // const deleteProduct = React.useCallback( (sentID) => async () => {
    //     var currentProducts = [...props.gridData]
    //     currentProducts.splice(sentID-1,1)
    //     // console.log("CurrentProducts", currentProducts)
    //     props.handleRemoveProduct(currentProducts)
    // },
    // [props], );

    function CustomToolbar() {
      return (
        <GridToolbarContainer>
          {/* <GridToolbarColumnsButton /> */}
          <GridToolbarFilterButton />
          {/* <GridToolbarDensitySelector /> */}
          <GridToolbarExport />
        </GridToolbarContainer>
      );
    }

    

    const [selectedModel, setSelectedModel ] = React.useState();
    const  GridData  = {
        columns: [
            {field: 'id', hide: true},
            {field: 'carrier', headerName: 'Carrier', align:'center', headerAlign: 'center', width:100, editable: false},
            {field: 'trackingNumber', headerName: 'Tracking Number', align:'center', headerAlign: 'center', width:150, editable: false},
            {field: 'shipmentStatus', headerName: 'Status', align:'center', headerAlign: 'center', width:150, editable: false},
            {field: 'shipmentOrigin', headerName: 'Origin', align:'center', headerAlign: 'center', width:100, editable: false},
            {field: 'destinationId', headerName: 'DestinationId', align:'center', headerAlign: 'center', width:150, editable: false},
      ], 
      rows: props.gridData
      };

  return (

    <div style={{ height: 300, width: '100%' }}>
      <DataGrid 
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        props.handleSelectReplenishmentShipment(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...GridData} 
      components={{
        Toolbar: CustomToolbar,
      }}
      />
    </div>

  );
};