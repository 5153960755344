import * as React from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
// import Grid from '@mui/material/Grid';





import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function FileCleaningSelections(props) {

    const [selectedModel, setSelectedModel ] = React.useState();
    const  ExampleFile  = {
      columns: props.fileExampleColumnNames,
      // columns: [
      // {field: 'id', hide: true},
      // {field: 'file', headerName: 'File', width: 250, align: 'center', editable: false},
      // {field: 'market', headerName: 'Market', width: 250, align: 'center', editable: false},
      // {field: 'userdistance', headerName: 'Distance', width: 100, editable: false, headerAlign: 'center', align: 'center', type: 'number'},
      // {field: 'userwarehouses', headerName: 'Warehouse', width: 100, editable: false, headerAlign: 'center', align: 'center', type: 'number'},
      // {field: 'userservicelevel', headerName: 'Service', width: 100, editable: false, headerAlign: 'center', align: 'center', type: 'number'},
      // {field: 'statwarehousecount', headerName: 'Warehouse Count', width: 145, headerAlign: 'center', align: 'center', editable: false, type: 'number'},
      // {field: 'statavgdistance', headerName: 'Average Distance', width: 145, headerAlign: 'center', align: 'center', editable: false, type: 'number'},
      // {field: 'statservicelevel', headerName: 'Service Level', width: 130, headerAlign: 'center', align: 'center', editable: false, type: 'number'},
      // ], 
      rows: props.fileExampleRows
      }
      //console.log("Net Models:",props.netModels)



  return (


    <div style={{ height: 400, width: '100%' }}>

      <DataGrid 
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        // props.handleSelectedNetModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...ExampleFile} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};