import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress'

export default function SelectLabels(props) {
  const [ distance, setDistance ] = React.useState('');
  const [ warehouses, setWarehouses ] = React.useState('');
  const [ serviceLevel, setServiceLevel ] = React.useState('');
  const [ completedParameters, setCompletedParameters ] = React.useState(false);

  const allParametersCheck = () => {
      if(distance && warehouses && serviceLevel){
          //console.log("Completed all items")
          setCompletedParameters(true);
      };

    };

    const handleDistanceChange = (event) => {
        setDistance(event.target.value);
        allParametersCheck();
        };

    const handleWarehouseChange = (e) => {
        setWarehouses(e.target.value);
    };
    const handleServiceLevelChange = (event) => {
        setServiceLevel(event.target.value);
    };

    const optimize = () => {
        props.handleOptimizing(distance, warehouses, serviceLevel);
    }

    React.useEffect(() => {
        allParametersCheck();
    }, [distance, warehouses, serviceLevel])



  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 250 }}>
        <InputLabel id="distance-select-helper-label">Distance</InputLabel>
            <Select
            labelId="distance-label"
            id="distance-select"
            value={distance}
            label="Distance"
            onChange={handleDistanceChange}
            >
            <MenuItem value={10}>Ten Miles</MenuItem>
            <MenuItem value={15}>Fifteen Miles</MenuItem>
            <MenuItem value={20}>Twenty Miles</MenuItem>
            <MenuItem value={25}>Twenty-Five Miles</MenuItem>
            <MenuItem value={30}>Thirty Miles</MenuItem>
            
            </Select>
        <FormHelperText>Longest Distance from Warehouse</FormHelperText>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 250 }}>
        <InputLabel id="warehouses-select-helper-label">Warehouses</InputLabel>
            <Select
            labelId="warehouses-label"
            id="warehouses-select"
            value={warehouses}
            label="Warehouses"
            onChange={handleWarehouseChange}
            >

            <MenuItem value={1}>One</MenuItem>
            <MenuItem value={5}>Five</MenuItem>
            <MenuItem value={10}>Ten</MenuItem>
            <MenuItem value={15}>Fifteen</MenuItem>
            <MenuItem value={20}>Twenty</MenuItem>
            <MenuItem value={25}>Twenty Five</MenuItem>
            </Select>
        <FormHelperText>Maximum Number of Warehouses</FormHelperText>
      </FormControl>

      <FormControl sx={{ m: 1, minWidth: 250 }}>
        <InputLabel id="service-level-select-helper-label">Outliers</InputLabel>
            <Select
            labelId="service-level-label"
            id="service-level-select"
            value={serviceLevel}
            label="Service Level"
            onChange={handleServiceLevelChange}
            >

            <MenuItem value={.85}>85%</MenuItem>
            <MenuItem value={0.9}>90%</MenuItem>
            <MenuItem value={0.95}>95%</MenuItem>
            <MenuItem value={0.99}>99%</MenuItem>
            </Select>
        <FormHelperText>Percent of Orders within distance</FormHelperText>
      </FormControl>
      <Grid container sx={{alignContent: 'center',}} spacing={2}>
        <Grid item align={'center'}  xs={12} sm={12} md={12} sx={{ mt: 3, border:0}}>
                    {completedParameters &&
                    <Button onClick={optimize} sx={{backgroundColor:"#68a0d7", color: "white", 
                    '&:hover': {backgroundColor: "#BA5F8e"}}} variant="contained">Optimize</Button>}
                    {false &&
                    <CircularProgress/> }
            </Grid>
      </Grid>

    </div>
  );
}