import React from "react";

import axios from 'axios';

import { Route, Switch, BrowserRouter } from "react-router-dom";

import { useSessionStorage } from 'react-use';
import './App.css';
import Dashboard from './Dashboard.js'
import Upload from './DataUpload.js'
import ResetPasswordRequest from './ResetPasswordRequest.js';
import Network from './NetworkOptimization.js'
import Inventory from './InventoryOptimization.js'
import Demand from './DemandAnalyzer.js';
import Simulator from './Simulator.js';
import Partners from './Partners.js';
import Resources from './YourResources.js';
import Replenishment from './Replenishment.js';
import Fulfillment from './Fulfillment.js';
import Evaluation from './EvaluationDashboard.js';
import MachineLearning from './MachineLearning.js';
import EdonAPI from './EdonAPI.js';
import IoT from './IoT.js';
import InventoryManagement from './InventoryManagementPage.js';
import SignIn from './SignIn.js';
import SignUp from './SignUp.js';
import PasswordSet from './PasswordSet.js';
import Home from './Home.js';

import WarehouseScanning from './WarehouseScanningPage.js'

import {UserProvider} from "./context/UserContext";
// import firebase from "./firebase.js";

import * as Sentry from "@sentry/react";
import FrontPage from "./FrontPage.js";
// import { BrowserTracing } from "@sentry/tracing";
// import HeaderMenus from "./Components/HeaderMenus.js";

// const DSN_SENTRY = process.env.SENTRY_DSN;
// const BACKEND_URL = process.env.RBACKEND_URL;
// const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

// const CI = process.env.REACT_APP_CI;

// console.log("CI", CI)
//get the .env file DNS_SENTRY
const DSN_SENTRY = process.env.SENTRY_DSN;
const REACT_APP_DEV_EDON_URL = process.env.REACT_APP_DEV_EDON_URL;



//!Sentry Error Tracking
//! put the server URL in tracePropagationTargets
Sentry.init({
  dsn: DSN_SENTRY,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// axios.defaults.baseURL = REACT_APP_BACKEND_URL;

// if False {
//   REACT_APP_BACKEND_URL ? axios.defaults.baseURL = REACT_APP_BACKEND_URL:
//     axios.defaults.baseURL = 'http://127.0.0.1:8000';
// }else{
  // axios.defaults.baseURL = 'http://127.0.0.1.8000';
// }


//! uncomment before build
//! uncomment before build
//! uncomment before build
// axios.defaults.baseURL = 'https://127.0.0.1/8000/';
// axios.defaults.baseURL = 'http://127.0.0.1:8000/';
// axios.defaults.baseURL = 'http://127.0.0.1:8001/';
// axios.defaults.baseURL = 'https://edon-connect-backend-2-b3d2e6a4a550.herokuapp.com/';


axios.defaults.baseURL = REACT_APP_DEV_EDON_URL;
//! uncomment before build
//! uncomment before build
//! uncomment before build
//! uncomment before build

// Platform Admin: 10
// Member Admin: 8
// Account Admin: 6
// User: 4
// Viewer: 2




function App() {


  const linkURL = 'http://edonconnect.tech/#/PasswordSet'
  // const linkURL = 'http://localhost:3000/#/PasswordSet'
  const mobileSite = 'https://edon-connect-mobile-backend.herokuapp.com/'
  // const mobileSite = 'http://127.0.0.1:8000/'
  
  const [ user, setUser ] = useSessionStorage(sessionStorage, "state-user", {});
  const [ setAccessToken ] = useSessionStorage(sessionStorage, "access-user", {});
  const [ headers, setHeaders ] = useSessionStorage(sessionStorage, "headers", {});
  // const history = useHistory();



  // const [ userInfo, setUserInfo ] = React.useState({});

  const [ setBadLogin ] = React.useState(false);
  const [ emailLink ] = React.useState();
  const [ emailSent, setEmailSent ] = React.useState(false);

  //! Set a cutoff after a certain
 //!Change the sign on from the database
  React.useEffect(() => {
    var currentTime = new Date();
    const timeSinceLastSignOn = currentTime - Date.parse(user.lastSignOnDate);
    var daysSinceLastSignon = timeSinceLastSignOn / 86400000
    //! if the user has not signed in in two days sign them off
    // console.log("Context", userTry)
    // console.log("User", user)
    // console.log("Token", accessToken)
    // console.log("Headers start", accessToken)
    if (daysSinceLastSignon > 2){
      setUser({isAuthenticated:false});
      }; // eslint-disable-next-line no-console
  },// eslint-disable-next-line no-console
  [setUser, user.lastSignOnDate]);



  // const onLogin = (email, password) => {

  //   var lastSignOnDate = new Date();
  //   updatePlatformUserSignOnDate(email);
  //   // logInToken(email, lastSignOnDate); //!This sets the user info to Session Storage
  //   logInToken(email, password, lastSignOnDate); //!This sets the user info to Session Storage

  // };

  //SECTION Axios getPlatform User
  const logInToken = async (email, password) => {

    const auth = {
      user: email,
      password: password,
    }
    await axios.post(`LogIn/login`, auth )
      .then(function (response) {
        // console.log("getPlatform UserInfo", response.data)
        setAccessToken(response.data.access_token)
        setUser({
          email: response.data.userEmail,
          isAuthenticated: true,
          lastSignOnDate: response.data.userLastSignOnDate,
          role: response.data.userRole,
          roleName: response.data.roleName,
          accountCode: response.data.accountCode,
          services: response.data.services,
          membershipCode: response.data.membershipCode,
          firstName: response.data.userFirstName,
          lastName: response.data.userLastName,
          company: response.data.userCompany,
          accountViewer: response.data.accountViewer, 
          membershipViewer: response.data.membershipViewer, 
        });
        const authorization = "Bearer " + response.data.access_token
        // console.log("Token", response.data.access)
        setHeaders({
          'Content-Type': 'application/json',
          'Authorization': authorization
        });
      })
      .catch(function (error) {
        // handle error
        alert("The password or email is not in the platform system. Please try again or contact your adminstrator.")
        setBadLogin(true);
      })
      .then(function () {
        // console.log("App Headers", headers)
      });
  }
//!SECTION



  const handleEmailSent = () => {
    setEmailSent(false);
  }


// //!SECTION



  const onRegister = (userEmail, securityCode) => {
    window.sessionStorage.setItem('registerationEmail', userEmail );
    window.sessionStorage.setItem('registerationSecurityCode', securityCode );
    window.location.href=linkURL; //! send the user to the linkURL "password set page"
  }
    
  //! Create and email
  // const sendEmailVerification = async (email) => {
  //   var actionCodeSettings = {
  //     url:linkURL,
  //     handleCodeInApp: true,
  //   };
  //   await firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
  //     .then((response) => {
  //       // console.log('email to google', email)
  //       setEmailSent(true);
  //       window.localStorage.setItem('emailForSignIn', email );
  //       // history.push("/"); //! Need to figure out how to get the user to the login page
  //     })
  //     .catch((error) => {
  //       // console.log(error);
  //     })
  //     .then(function(){
  //       // console.log("Made it through")
  //     });
  //   }

  
  const changeDatabasePassword = async ( data) => {
    // console.log("Change Password", data)
    await axios.put(`security/platformuserchangepassword`, data)
    .then(function(response){
      alert(`Password set for ${data.email}.  Please login.`)
      // console.log("Password Set", response.data)
      setPasswordSet(true);
      // localStorage.removeItem('emailForSignIn');

      sessionStorage.removeItem('registerationEmail');
      sessionStorage.removeItem('registerationSecurityCode');
      gotoSignIn();
    })
    .catch((error) => {
      var errorMsg = error.response.data.detail
      setBadLogin(true);
      alert(`Could not set the password on platform. ${errorMsg}  Please contact your administrator.`)
      // sessionStorage.removeItem('registerationEmail');
    })
  }

  const gotoSignIn = () => {
    window.location.href="/";
  }


  const [passwordSet, setPasswordSet] = React.useState(false);

  const createUserPassword = async ( password) => {
    // console.log("Create User Password", email, password)
    const data = {
      "email": sessionStorage.getItem("registerationEmail"),
      "password": password,
      "securityCode": sessionStorage.getItem("registerationSecurityCode")
    }
    changeDatabasePassword(data);
  }





  const [openHeaderDrawer, setOpenHeaderDrawer] = React.useState(true);

  const handleOpenHeaderDrawer = () => {
    setOpenHeaderDrawer(!openHeaderDrawer);
  };


  
  return (

    <BrowserRouter>
      <UserProvider> 
      {/* <HeaderMenus openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} /> */}
      <Switch>
      <Route exact 
        path="/"
        render={() => 
           <Home openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />
      <Route exact 
        path="/Dashboard"
        render={() => 
          (!user) ? <SignIn user={user}/> :<Dashboard user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}  />
        } />
      <Route exact 
        path="/SignIn"
        render={() => 
          (!user) ? <SignIn user={user}   /> : <Dashboard user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}   />
        } />

      <Route exact 
        path="/PasswordSet"
        render={() => 
          // (passwordSet) ? <SignIn user={user} resetPasswordEmail={resetPasswordEmail}  /> :
          <PasswordSet createUserPassword={createUserPassword} logInToken={logInToken}  emailLink={emailLink} passwordSet={passwordSet} />
        } />

      <Route exact 
        path="/SignUp"
        render={() => 
          <SignUp emailSent={emailSent} handleEmailSent={handleEmailSent} onRegister={onRegister}  />
        } />

      <Route exact 
        path="/ResetPasswordRequest"
        render={() => 
          <ResetPasswordRequest emailSent={emailSent} handleEmailSent={handleEmailSent} onRegister={onRegister}  />
        } />

      <Route exact 
        path="/inventory"
        render={() => 
          (!user) ? <Home />  : <Inventory user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/upload"
        render={() => 
          (!user) ? <Home />  : <Upload user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/>
        } />

      <Route exact 
        path="/network"
        render={() => 
          (!user) ? <Home />  : <Network user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} headers={headers} />
        } />

      <Route exact 
        path="/demand"
        render={() => 
          (!user) ? <Home />  : <Demand user={user} headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/simulator"
        render={() => 
          (!user) ? <Home />  : <Simulator openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} user={user} />
        } />

      <Route exact 
        path="/partners"
        render={() => 
          (!user) ? <Home />  : <Partners headers={headers} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/resources"
        render={() => 
          (!user) ? <Home />  : <Resources openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}  />
        } />

      <Route exact 
        path="/inventoryTracking"
        render={() => 
          (!user) ? <Home />  : <InventoryManagement  headers ={headers} user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} /> //warehouseScanningpage
        } />

      <Route exact 
        path="/replenishment"
        render={() => 
          (!user) ? <Home />  : <Replenishment user={user}openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />

      <Route exact 
        path="/fulfillment"
        render={() => 
          (!user) ? <Home />  : <Fulfillment user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/>
        } />

      <Route exact 
        path="/CycleCount"
        render={() => 
          (!user) ? <Home />  : <WarehouseScanning  headers= {headers} user={user} mobileSite={mobileSite} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/> 
        } />
      <Route exact 
        path="/iot"
        render={() => 
          (!user) ? <Home />  : <IoT  user={user}openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer}/> //IoT
        } />
        <Route exact 
          path="/evaluation"
          render={() => 
            (!user) ? <Home />  : <Evaluation user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
          } />
      <Route exact 
        path="/MachineLearning"
        render={() => 
          (!user) ? <Home />  : <MachineLearning user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />
      <Route exact 
        path="/EdonAPI"
        render={() => 
          (!user) ? <Home />  : <EdonAPI user={user} openHeaderDrawer={openHeaderDrawer} handleOpenHeaderDrawer={handleOpenHeaderDrawer} />
        } />
      <Route exact 
        path="/FrontPage"
        render={() => 
          <FrontPage />
        } />
  
      <Route path="*" render={() => 
            <Home />
          } />

    </Switch>
    </UserProvider>
    </BrowserRouter>





  );
}

export default App;


