import * as React from 'react';
import axios from 'axios';


import FileDrop from './FileDrop.js';
import MessageSnackBar from './MessageSnackBar.js';
import { UserContext } from '../context/UserContext.js';

// import Typography from '@mui/material/Typography';
// import Grid from '@mui/material/Grid';


// This component accepts a file from FileDrop
// sends it to the endpoint
// It receives a prop to toggle the view for this component
export default function RegionalFileDrop(props) {
  const { userData } = React.useContext(UserContext);

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  //SECTION Axios Upload a region's Zip File
  const handleUploadFile = (file) => {
    const userid = userData.email
    // console.log(file);

    const formData = new FormData();
    formData.append('file', file);

    var postConfig = {
      method: 'post',
      url: `/uploadzipmetros/${userid}`,
      data : formData
    };
    axios(postConfig)
    .then(function (response) {
        // console.log(response)
        alert(response.data)
        props.toggleRegionDrop();

    })
    .catch(function (error) {
      // console.log(error);
      //!Message bar
      setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
      setMessageBarOpen(true);
      setMessageSeverity("error"); // error: red, warning: orange, information: blue, success: green
    });
  }
  //!SECTION


  return (
    <>
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
      <FileDrop handleUploadFile={handleUploadFile} />
    </>
  );
}