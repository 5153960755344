import * as React from "react";

import Button from "@mui/material/Button";

import TextField from "@mui/material/TextField";

import Stack from "@mui/material/Stack";

import Grid from "@mui/material/Grid";
// import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material//MenuItem";

// import { createTheme, ThemeProvider } from "@mui/material/styles";

// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";

// const theme = createTheme();

// const darkTheme = createTheme({
//   palette: {
//     mode: "dark",
//   },
// });


export default function DropDownSelector(props) {


  // Store Item selected
  const [selectedItem, setSelectedItem] = React.useState("");
  const [buttonDisabled, setButtonDisabled] = React.useState(true);
  
  // handle the event of item selection
  const handleItemSelect = (event) => {
    // console.log(event, value);
    setSelectedItem(event.target.value);
    setButtonDisabled(false);
  };

  // handle the button click by sending the section back up
  const handleSendItem = async () => {
    props.toggleClose();
    // alert(props.dropDownTitles.dropDownAlertMessage + selectedItem)
    props.handleSelectedItem(selectedItem);
  };

  return (
    <Grid
      container
      marginTop={0}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 1,
        border: 0,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        <Typography component="h1" variant="h5">
          {props.dropDownTitles.dropDownTitle}
        </Typography>


          <TextField
            margin="normal"
            select
            fullWidth
            name="SelectItem"
            label={props.dropDownTitles.dropDownLabel}
            value={selectedItem}
            onChange={handleItemSelect}
            type="number"
            id={props.itemList.id}
            helperText={props.dropDownTitles.dropDownHelperText}
          >
            {props.itemList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.id}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        {props.dropDownTitles.buttonView &&
          <Button
            disabled={buttonDisabled}
            align="center"
            type="submit"
            onClick={handleSendItem}
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            {props.dropDownTitles.dropDownButtonTitle}
          </Button>
        }
        {/* </Box> */}
      </Stack>
    </Grid>
  );
}
