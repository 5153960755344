import * as React from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function InventoryDetailGrid(props) {
    
    const [selectedItem, setSelectedItem ] = React.useState();

    const  InventoryModel  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'Item Number', headerName: 'Item Number', width: 120, editable: false},
      {field: 'Unit Cost', headerName: 'Unit Cost', width: 100, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'Item Echelon', headerName: 'Echelon', width: 100, align:'center', headerAlign: 'center', editable: false,},
      {field: 'Review Period', headerName: 'Review Period', width: 120, align:'center', headerAlign: 'center', editable: false,},
      {field: 'Demand Rate per Period', headerName: 'Demand Rate', width: 120, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'Demand Variation', headerName: 'Demand Variation', width: 140, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'Market Upto Stock Level', headerName: 'Market Stock', width: 120, headerAlign: 'center', editable: false, type: 'number'},
      {field: 'Market Item Cost', headerName: 'Market Item Cost', width: 140, headerAlign: 'center', editable: false, type: 'number'},

      {field: 'Echelon Matrix Cell', headerName: 'Echelon Cell', width: 145, headerAlign: 'center', editable: false, type: 'number'},
      {field: 'Avg Time Between Orders', headerName: 'Time Between Orders', width: 100, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'Safety Stock Level', headerName: 'Safety Stock', width: 130, headerAlign: 'center', editable: false, type: 'number'},
      {field: 'Upto Stock Level', headerName: 'Stock Level', width: 130, headerAlign: 'center', editable: false, type: 'number'},
      {field: 'Order Point', headerName: 'Order Point', width: 130, headerAlign: 'center', editable: false, type: 'number'},
      {field: 'Market Order Point', headerName: 'Market Point', width: 130, headerAlign: 'center', editable: false, type: 'number'},
      ], 
      rows: props.inventoryDetail
      }

  return (
    <div style={{ height: 400, width: '100%' }}>
        
        <DataGrid 
        onSelectionModelChange={(newSelectionModel) => {
        setSelectedItem(newSelectionModel)
        }}
        selectedItem={selectedItem}
        {...InventoryModel} 
        components={{
            Toolbar: GridToolbar,
        }}/>
    

    </div>

);
};