import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InboundShipmentGrid from './InboundShipmentGrid.js';



export default function InboundShipments(props) {

  //SECTION Local SnackBar Messaging
  


  //!SECTION Local SnackBar Messaging


    // const [ serialNumberOption, setSerialNumberOption ] = React.useState(true);
    // const [ productQuantityOption, setProductQuantityOption ] = React.useState(false);


   //SECTION Form Validation
    //! Form Validation
    // const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "selectTrackingNumber": false,

        
    });
    const [ helperText, setHelperText ] = React.useState(
        {
        "selectTrackingNumber":"Shipment Tracking Number",
       });

    const [ setValidTrackingNumber ] = React.useState(false);
    // // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){
        case 'selectTrackingNumber': 
        // NOTE check to see if it is a valid Tracking Number
          if (e.target.value.length > 8){
            setValidFields(prevState => ({
              ...prevState,
              "selectTrackingNumber": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                selectTrackingNumber: "",
                }));
              setValidTrackingNumber(true);
              handleSelectTrackingNumber(e);

            }else{
              setValidFields(prevState => ({
                ...prevState,
                "selectTrackingNumber": true}));
              setHelperText(prevState => ({
                ...prevState,
                selectTrackingNumber: "This is not a valid Tracking Number",
                }));  
              setValidTrackingNumber(false);
              setSelectedTrackingNumber(e.target.value)
          }
          break;


        default:
          break;
      }
    }

    // NOTE Review
    // React.useEffect(() => {
    //   if (validProductNumber && validSerialNumber ) {
    //     setButtonDisable(false);
    //   }else{
    //     setButtonDisable(true);
    //   }
    // },[ validProductNumber, validSerialNumber ]
    // )
    //!SECTION

    
    // const [ viewOrderDetail, setViewDetailOrder ] = React.useState(false);

const [ viewShipmentDetail, setViewShipmentDetail ] = React.useState(false);

  //SECTION Select Tracking Number
  const handleSelectTrackingNumber = (event) => {
      // console.log("value", event.target.value)
      // console.log(event.target)
      setSelectedTrackingNumber(event.target.value)
      setViewShipmentDetail(true)
      props.handleViewAssociation();
      props.handleGetShipmentData(event.target.value);

  };

    const [selectedTrackingNumber, setSelectedTrackingNumber ] = React.useState("");


  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >
        <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 }} >
        <Typography component="h1" variant="h5">
          Inbound Shipment Information
        </Typography>
        </Grid>
          {/* <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {snackBarMessage}
            </MuiAlert>
          </Snackbar> */}

          
        <Grid item xs={12} md={12} lg={12} sx={{alignContent: 'center', border:0 }} >
          <TextField
            error = {validFields.selectTrackingNumber}
            helperText={helperText.selectTrackingNumber}
            margin="normal"
            required
            name="selectTrackingNumber"
            label="Tracking Number"
            value={selectedTrackingNumber}
            onChange={(e) => formValidation(e)}
            type="text"
            id="selectTrackingNumber"
          />
        </Grid>

        {
            viewShipmentDetail &&
            <InboundShipmentGrid gridData = {props.shipmentDetail} />
        }


    </Grid>
  );
}