import * as React from 'react';

import { DataGrid, GridToolbar, GridActionsCellItem } from '@mui/x-data-grid';
// import { useDemoData } from '@mui/x-data-grid-generator';
import axios from 'axios';


import DeleteIcon from '@mui/icons-material/Delete';
import MessageSnackBar from './MessageSnackBar';
import { UserContext } from '../context/UserContext.js';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID

LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function NetworkOptimizationGrid(props) {
    const userData = React.useContext(UserContext)

  
    const currencyFormatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
    //SECTION Message Bar Controls
    const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
    const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
    const [ messageSeverity, setMessageSeverity ] = React.useState('success')
    const handleMessageBarClosing = () => {
      setMessageBarOpen(false);
    };
    //!SECTION

    //What is this for
    // eslint-disable-next-line 
    const [selectedInvOptzID, setSelectedInvOptzID ] = React.useState();
    // console.log("Saved Inventory Optimization: ", selectedInvOptzID)

    //SECTION Axios get A saved Inventory Optmization
    const getASavedInventoryOptimization = async (invOptzID) => {
      const headers = props.headers
      await axios.get(`InvOptz/asavedinventoryoptimization/${invOptzID}`, { headers })
      .then(function (response) {
        // handle success
        // console.log("Inventory Model:", response.data.detail.data)
        props.handleMarketSummary(response.data)
        props.handleInventoryDetail(response.data.detail.data)
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
      .then(function () {
        // always executed
      });
    };
    //!SECTION



    const  InvOptzModels  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'actions', hide:(userData.userRole<6), type: 'actions', align: 'center',headerName: 'Delete', minWidth: 125,  
      getActions: (params) => [
        <GridActionsCellItem icon={<DeleteIcon />} onClick={deleteInventoryOptz(params.id)}  label="Delete" />
      ]},
      {field: 'memberCompany', headerName: 'Member Company', hide:(userData.userRole<10), width:200, editable: false},
      {field: 'accountCompany', headerName: 'Account Company', hide:(userData.userRole<8),width:200, editable: false},
      {field: 'user', headerName: 'Creator', width:200, editable: false},
      {field: 'warehouses', headerName: 'Number of Warehouses', width:200, editable: false},
      {field: 'marketTotalCosts', type: 'number', width:150, valueFormatter: ({value})=> currencyFormatter.format(Number(value)), headerName: 'Total Market Costs', editable: false},
      {field: 'marketTotalQuantity', headerName: 'Total Market Quantity', width: 175, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'sameDayServiceLevel', headerName: 'Same Day Service', type: 'number', width:175, editable: false},
      {field: 'twoHourServiceLevel', headerName: 'Two Hour Service', width: 175, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'marketcost', headerName: 'Market Costs', width: 150, valueFormatter: ({value})=> currencyFormatter.format(Number(value)), editable: false, headerAlign: 'center', type: 'number'},
      {field: 'nodecost', headerName: 'Node Costs', width: 150, valueFormatter: ({value})=> currencyFormatter.format(Number(value)), editable: false, headerAlign: 'center', type: 'number'},
      {field: 'marketquantity', headerName: 'Market Quantity', width: 150, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'nodequantity', headerName: 'Node Quantity', width: 150, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'marketcountbyitemType', headerName: 'Market Item Count', width: 150, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'nodecountbyitemType', headerName: 'Node Item Count', width: 150, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'gridValueList', headerName: 'Grid Values', width: 300, editable: false, headerAlign: 'center', type: 'number'},
      {field: 'inventoryid', headerName: 'Inventory ID', width: 300, editable: false, headerAlign: 'center', type: 'number'},

      ], 
      rows: props.savedInventoryOptimizations
      }

      //SECTION Axios Delete a Inventory Optimization 
      const deleteInventoryOptz = React.useCallback( (sentID) => async () => {
        // console.log("Id: ", sentID)
        // console.log(props.savedInventoryOptimizations)
        const index = props.savedInventoryOptimizations.map(model => model.id).indexOf(sentID)
        // console.log(index)
        // console.log(props.savedInventoryOptimizations[index].inventoryid);
        const inventoryID = props.savedInventoryOptimizations[index].inventoryid;
        // console.log(inventoryID)
        await axios.delete(`/deleteplatformninventorymodel/${userData.email}/${inventoryID}`)
        .then(function (response) {
          // handle success
          // console.log("Inventory Message:", response.data)
          props.toggleSavedInvOptz()
          alert(response.data)

        })
        .catch(function (error) {
          // handle error
          // console.log(error);
          //!Message bar
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.message}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        })
        .then(function () {
          // always executed
        });

      },[],);
    //!SECTION




  return (
    <div style={{ height: 400, width: '100%' }}>
      <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
      <DataGrid 
      onSelectionModelChange={(newSelectionModel)=> {
        setSelectedInvOptzID(props.savedInventoryOptimizations[newSelectionModel-1].inventoryid)
        getASavedInventoryOptimization(props.savedInventoryOptimizations[newSelectionModel-1].inventoryid);
        // console.log(newSelectionModel)
        // console.log("Selection", props.savedInventoryOptimizations[newSelectionModel-1].inventoryid)
      }}
      selectedModel={setSelectedInvOptzID}

      {...InvOptzModels} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};