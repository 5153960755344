import * as React from 'react';


import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
// import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function UserStats(props) {
    
    const [selectedModel, setSelectedModel ] = React.useState();
    const  AccountStats  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'membershipName', headerName: 'Membership Name', headerAlign: 'center', align: 'center', flex:1, editable: false},
      {field: 'companyName', headerName: 'Company Name', headerAlign: 'center', align: 'center', flex:1, editable: false},
      {field: 'accountCode', headerName: 'Account Code',  width:175, editable: false, headerAlign: 'center',  align: 'center'},
      {field: 'adminEmail', headerName: 'Admin Email', headerAlign: 'center', align: 'center', width:275, editable: false},
      // {field: 'userRole', headerName: 'User Role',  flex:1, editable: false, headerAlign: 'center', align: 'center', type:'number' },
      // {field: 'assigned', headerName: 'Assigned',  width:100, editable: false, headerAlign: 'center', align: 'center'},
      {field: 'creatorEmail', headerName: 'Creator', headerAlign: 'center', align: 'center', width:300, editable: false },
      {field: 'createdate', headerName: 'Create Date', headerAlign: 'center', align: 'center', width:125, editable: false },
      {field: 'active', headerName: 'Active',  width:100, editable: false, headerAlign: 'center', align: 'center' },
      {field: 'membershipCode', headerName: 'Membership Code',  flex:1, editable: false, headerAlign: 'center',  align: 'center'},
      // {field: 'actions', type: 'actions', headerName: 'Delete Account', width:150 ,  
      // getActions: (params) => [
      //   <GridActionsCellItem icon={<DeleteIcon />} onClick={props.deleteAccount(params.id)}  label="Delete" />
      // ]},

      ], 
      rows: props.accountsDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...AccountStats} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};