import * as React from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';


import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function MarketFilesGrid(props) {
    
    const [selectedModel, setSelectedModel ] = React.useState();
    const  ResourceMembershipStats  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'resourceMember', headerName: 'Resource Partner', headerAlign: 'center', align:'center',  width:170, editable: false},
      {field: 'accountOwner', headerName: 'Account Owner', headerAlign: 'center', align: 'center', width:150, editable: false},
      {field: 'userId', headerName: 'User', headerAlign: 'center', align: 'center', flex:1, editable: false },
      {field: 'numberOfOrderFiles', headerName: 'Order Files',  width:100, editable: false, type:'number' },
      {field: 'numberOfMarkets', headerName: 'Markets',  width:130, editable: false, type:'number' },
      {field: 'numberOfNetOptz', headerName: 'Network Optimizations',  width:180, editable: false, type:'number' },
      {field: 'marketsWithNetOptz', headerName: 'Network Markets', width:150, editable: false, type:'number' },
      {field: 'numberOfInvOptz', headerName: 'Inventory Optimizations',  width:180, editable: false, type:'number'} ,
      {field: 'marketsWithInvOptz', headerName: 'Inventory Markets', width:150, editable: false, type:'number' },
      ], 
      rows: props.resourceMembershipStats
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGrid 

        showCellRightBorder
        showColumnRightBorder
        showCellLeftBorder
        
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...ResourceMembershipStats} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};