import React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';



import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
// import Skeleton from '@mui/material/Skeleton';




// const mdTheme = createTheme();
// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });





const headings = [

  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  "Network Optimization in Progress",
  
]
const content = [
    "This one is taking more time than expected.",
    "This one is taking more time than expected.",
    "This one is taking more time than expected.",
    "This one is taking more time than expected.",
    "When your service level is met the Optimization returns the results.",
    "We are working on your Network Optimization.",
    "There are millions of calculations that can take place.",
    "We will keep adding warehouses until your service level is reached.",
    "When your service level is met the Optimization returns the results.",
    "We are working on your Network Optimization.",
    "There are millions of calculations that can take place.",
    "We will keep adding warehouses until your service level is reached.",
    "When your service level is met the Optimization returns the results.",
    "We are working on your Network Optimization.",
]

export default function Waiting(props) {

 
    const card = 1;

    return (
          <Grid container spacing={4}>

            <Grid item key={card} xs={12} sm={12} md={12}>

                  <Typography gutterBottom variant="h4" component="h2">
                    {headings[props.processingMessageIndex]}
                  </Typography>

                    <Box sx={{ width: 300, height:300, alignItems: 'center', justifyContent: 'center', border: 1, m:5, display: 'flex' }}>
                        <CircularProgress size={150} />
                    </Box>

                
                  <Typography variant="h6" sx={{m:5, align: 'center', justifyContent:'center'}}>
                        {content[props.processingMessageIndex]}
                  </Typography>

            </Grid>


        </Grid>
    )
}