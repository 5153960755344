import React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';
import ComputerImage from '../img/upload.jpg'

import Typography from '@mui/material/Typography';


// const mdTheme = createTheme();
// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });


const cards = [1,2,3];

const headings = [
  "Nothing",
  "Regional",
  "Market",
  "Node"
]
const content = [
  "Nothing",
  "Any items in the Regional Echelon will not be deployed in the market.  They will not be available same day. This can be for your high cost items.",
  "Items in the Market Echelon will be deployed in one location in the market.  They will be available same day, but not always within 2-4 hours.",
  "Node Echelon items will be deployed in all of the market locations. They will be available same day and within 2-4 hours.",
]
const axiscards = [1,2,3];

const axisheadings = [
  "Nothing",
  "Volume",
  "Costs",
  "Where to Start"
]
const axiscontent = [
  "Nothing",
  "The x-axis is the measure of volume or how often an item is ordered. 25 will be the slowest 25% of items. The 100 will be the fastest 25% items." ,
  "The y-axis is the measure of cost of items ordered. 25 will have the least costly items. The 100 will be the most costly items." ,
  "If you don't know where to start, use the default values and adjust the settings based on your budget and service requirements",

]

const echelonAxisSubTitle = 'All of the items are broken out into 16 groups based on the percentage of cost and volume of each item compared to all the other items. You can balance the service level needed and the cost based on which items you put the which echelon. '




export default function EchelonGridExplanations(props) {





    return (
        <Grid container spacing={2} sx={{alignContents:'center'}}>
            <Grid item  align={'center'} xs={12} sm={12} md={12} >
              <Typography paddingTop={5} variant="h4" >Echelon Definitions</Typography>
            </Grid>
            
            {cards.map((card) => (
                <Grid item key={card} xs={12} sm={12} md={4}>
                    <Card
                    sx={{ height: '80%', display: 'flex', flexDirection: 'column' }}
                    >
                    <CardMedia
                        component="img"
                        sx={{
                            // 16:9
                            pt: '10%',
                            maxHeight: "50%"
                        }}
                        image={ComputerImage}
                        alt="Card Image"
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2">
                            {headings[card]}
                        </Typography>
                        <Typography>
                            {content[card]}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                )
            )}

        <Grid item  align={'center'} xs={12} sm={12} md={12} >
            <Typography paddingTop={0} variant="h4" >Echelon Axis</Typography>
        </Grid>

        <Grid item  align={'center'} xs={12} sm={12} md={12} >
            <Typography padding={2} variant="subtitle1">{echelonAxisSubTitle}  </Typography>
        </Grid>

        {axiscards.map((axiscard) => (
                <Grid item key={axiscard} xs={12} sm={12} md={4}>
                    <Card
                    sx={{ height: '80%', display: 'flex', flexDirection: 'column' }}
                    >
                    <CardMedia
                        component="img"
                        sx={{
                            // 16:9
                            pt: '10%',
                            maxHeight: "50%"
                        }}
                        image={ComputerImage}
                        alt="Card Image"
                    />
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography gutterBottom variant="h5" component="h2">
                        {axisheadings[axiscard]}
                        </Typography>
                        <Typography>
                        {axiscontent[axiscard]}
                        </Typography>
                    </CardContent>
                    </Card>
                </Grid>
                )
            )}



        </Grid>


    )
}