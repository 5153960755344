import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from './context/UserContext.js';
import axios from 'axios';

import { useSessionStorage } from 'react-use';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Box from '@mui/material/Box';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';

import HeaderMenus from './Components/HeaderMenus.js';
import MessageSnackBar from './Components/MessageSnackBar';
import DemandAnalyzerTable from './Components/DemandAnalyzerTable';

import DropDownInitialValue from "./Components/DropDownInitialValue.js";
import ForecastGrid from './Components/ForecastGrid';

import Paper from '@mui/material/Paper';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';


import BarChart from './Components/BarChart.js'
import ControlChart from './Components/ControlChart.js'
import ForecastLineChart from './Components/ForecastLineChart.js'
import Unauthorized from './Components/Unauthorized.js'
import AccountDropDown from './Components/AccountDropDown.js';

import EdonLogo from './edonLogoWhite.png'
import MicrosoftLogo from './MicrosoftLogo.jpeg'
import AcmeLogo from './img/acme.jpeg';
import Copyright from "./Components/Copyright.js";




// const mdTheme = createTheme();
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
const staticCustomerList = [
  {"_id":"All", "id":"All", "key":"All", "value":"All", "label":"All"},	

]

const staticCustomerGroupList = [
  {"_id":"All", "id":"All", "key":"All", "value":"All", "label":"All"},
  {"_id":"9W HALO - DBA ANGELICA CORP", "id":"9W HALO - DBA ANGELICA CORP", "key":"9W HALO - DBA ANGELICA CORP", "value":"9W HALO - DBA ANGELICA CORP", "label":"9W HALO - DBA ANGELICA CORP"},
  {"_id":"AMERICAN TEXTILE MAINTENANCE COMPANY", "id":"AMERICAN TEXTILE MAINTENANCE COMPANY", "key":"AMERICAN TEXTILE MAINTENANCE COMPANY", "value":"AMERICAN TEXTILE MAINTENANCE COMPANY", "label":"AMERICAN TEXTILE MAINTENANCE COMPANY"},
  {"_id":"BERKSHIRE HEALTH SYSTEMS", "id":"BERKSHIRE HEALTH SYSTEMS", "key":"BERKSHIRE HEALTH SYSTEMS", "value":"BERKSHIRE HEALTH SYSTEMS", "label":"BERKSHIRE HEALTH SYSTEMS"},
  {"_id":"BOSCO MEDICAL AUSTRALIA", "id":"BOSCO MEDICAL AUSTRALIA", "key":"BOSCO MEDICAL AUSTRALIA", "value":"BOSCO MEDICAL AUSTRALIA", "label":"BOSCO MEDICAL AUSTRALIA"},
  {"_id":"CARDINAL HEALTH MED PROD SVCS", "id":"CARDINAL HEALTH MED PROD SVCS", "key":"CARDINAL HEALTH MED PROD SVCS", "value":"CARDINAL HEALTH MED PROD SVCS", "label":"CARDINAL HEALTH MED PROD SVCS"},
  {"_id":"CARDINAL HEALTH MED PRODS & SRVCS", "id":"CARDINAL HEALTH MED PRODS & SRVCS", "key":"CARDINAL HEALTH MED PRODS & SRVCS", "value":"CARDINAL HEALTH MED PRODS & SRVCS", "label":"CARDINAL HEALTH MED PRODS & SRVCS"},
  {"_id":"CARDINALHEALTH PRESOURCE", "id":"CARDINALHEALTH PRESOURCE", "key":"CARDINALHEALTH PRESOURCE", "value":"CARDINALHEALTH PRESOURCE", "label":"CARDINALHEALTH PRESOURCE"},
  {"_id":"CATHOLIC HEALTH SERVICES OF LONG ISL", "id":"CATHOLIC HEALTH SERVICES OF LONG ISL", "key":"CATHOLIC HEALTH SERVICES OF LONG ISL", "value":"CATHOLIC HEALTH SERVICES OF LONG ISL", "label":"CATHOLIC HEALTH SERVICES OF LONG ISL"},
  {"_id":"CENTURY LINEN", "id":"CENTURY LINEN", "key":"CENTURY LINEN", "value":"CENTURY LINEN", "label":"CENTURY LINEN"},
  {"_id":"CLAFLIN COMPANY", "id":"CLAFLIN COMPANY", "key":"CLAFLIN COMPANY", "value":"CLAFLIN COMPANY", "label":"CLAFLIN COMPANY"},
  {"_id":"CONCORDANCE HEALTHCARE - PAYER ONLY", "id":"CONCORDANCE HEALTHCARE - PAYER ONLY", "key":"CONCORDANCE HEALTHCARE - PAYER ONLY", "value":"CONCORDANCE HEALTHCARE - PAYER ONLY", "label":"CONCORDANCE HEALTHCARE - PAYER ONLY"},
  {"_id":"CRAZY SCRUBS", "id":"CRAZY SCRUBS", "key":"CRAZY SCRUBS", "value":"CRAZY SCRUBS", "label":"CRAZY SCRUBS"},
  {"_id":"DISTRIBUTION COOPERATIVE INC", "id":"DISTRIBUTION COOPERATIVE INC", "key":"DISTRIBUTION COOPERATIVE INC", "value":"DISTRIBUTION COOPERATIVE INC", "label":"DISTRIBUTION COOPERATIVE INC"},
  {"_id":"DISTRIBUTION COOPERATIVE, INC.", "id":"DISTRIBUTION COOPERATIVE, INC.", "key":"DISTRIBUTION COOPERATIVE, INC.", "value":"DISTRIBUTION COOPERATIVE, INC.", "label":"DISTRIBUTION COOPERATIVE, INC."},
  {"_id":"DIVISION LAUNDRY & CLEANERS INC", "id":"DIVISION LAUNDRY & CLEANERS INC", "key":"DIVISION LAUNDRY & CLEANERS INC", "value":"DIVISION LAUNDRY & CLEANERS INC", "label":"DIVISION LAUNDRY & CLEANERS INC"},
  {"_id":"EL CAMINO HOSPITAL MOUNTIAN VIEW", "id":"EL CAMINO HOSPITAL MOUNTIAN VIEW", "key":"EL CAMINO HOSPITAL MOUNTIAN VIEW", "value":"EL CAMINO HOSPITAL MOUNTIAN VIEW", "label":"EL CAMINO HOSPITAL MOUNTIAN VIEW"},
  {"_id":"EMERALD TEXTILES SVCS, SAN DIEGO LLC", "id":"EMERALD TEXTILES SVCS, SAN DIEGO LLC", "key":"EMERALD TEXTILES SVCS, SAN DIEGO LLC", "value":"EMERALD TEXTILES SVCS, SAN DIEGO LLC", "label":"EMERALD TEXTILES SVCS, SAN DIEGO LLC"},
  {"_id":"EVERGREEN LINEN SOLUTIONS", "id":"EVERGREEN LINEN SOLUTIONS", "key":"EVERGREEN LINEN SOLUTIONS", "value":"EVERGREEN LINEN SOLUTIONS", "label":"EVERGREEN LINEN SOLUTIONS"},
  {"_id":"FDR SERVICES CORP", "id":"FDR SERVICES CORP", "key":"FDR SERVICES CORP", "value":"FDR SERVICES CORP", "label":"FDR SERVICES CORP"},
  {"_id":"FMOL HEALTH SYSTEMS", "id":"FMOL HEALTH SYSTEMS", "key":"FMOL HEALTH SYSTEMS", "value":"FMOL HEALTH SYSTEMS", "label":"FMOL HEALTH SYSTEMS"},
  {"_id":"HENRY SCHEIN, INC.", "id":"HENRY SCHEIN, INC.", "key":"HENRY SCHEIN, INC.", "value":"HENRY SCHEIN, INC.", "label":"HENRY SCHEIN, INC."},
  {"_id":"HILO MEDICAL CENTER", "id":"HILO MEDICAL CENTER", "key":"HILO MEDICAL CENTER", "value":"HILO MEDICAL CENTER", "label":"HILO MEDICAL CENTER"},
  {"_id":"HUGH CHATHAM MEMORIAL HOSPITAL", "id":"HUGH CHATHAM MEMORIAL HOSPITAL", "key":"HUGH CHATHAM MEMORIAL HOSPITAL", "value":"HUGH CHATHAM MEMORIAL HOSPITAL", "label":"HUGH CHATHAM MEMORIAL HOSPITAL"},
  {"_id":"IHC CENTRAL LAUNDRY", "id":"IHC CENTRAL LAUNDRY", "key":"IHC CENTRAL LAUNDRY", "value":"IHC CENTRAL LAUNDRY", "label":"IHC CENTRAL LAUNDRY"},
  {"_id":"JACKSON ALLISON MEDICAL & SURGICAL", "id":"JACKSON ALLISON MEDICAL & SURGICAL", "key":"JACKSON ALLISON MEDICAL & SURGICAL", "value":"JACKSON ALLISON MEDICAL & SURGICAL", "label":"JACKSON ALLISON MEDICAL & SURGICAL"},
  {"_id":"JOHN T MATHER MEMORIAL HOSPITAL", "id":"JOHN T MATHER MEMORIAL HOSPITAL", "key":"JOHN T MATHER MEMORIAL HOSPITAL", "value":"JOHN T MATHER MEMORIAL HOSPITAL", "label":"JOHN T MATHER MEMORIAL HOSPITAL"},
  {"_id":"LINEN KING LLC", "id":"LINEN KING LLC", "key":"LINEN KING LLC", "value":"LINEN KING LLC", "label":"LINEN KING LLC"},
  {"_id":"LOGANS HEALTHCARE DIVISION", "id":"LOGANS HEALTHCARE DIVISION", "key":"LOGANS HEALTHCARE DIVISION", "value":"LOGANS HEALTHCARE DIVISION", "label":"LOGANS HEALTHCARE DIVISION"},
  {"_id":"LUMINIS HEALTH/ANNE ARUNDEL MEDICAL", "id":"LUMINIS HEALTH/ANNE ARUNDEL MEDICAL", "key":"LUMINIS HEALTH/ANNE ARUNDEL MEDICAL", "value":"LUMINIS HEALTH/ANNE ARUNDEL MEDICAL", "label":"LUMINIS HEALTH/ANNE ARUNDEL MEDICAL"},
  {"_id":"MCKESSON MEDICAL SURGICAL INC.", "id":"MCKESSON MEDICAL SURGICAL INC.", "key":"MCKESSON MEDICAL SURGICAL INC.", "value":"MCKESSON MEDICAL SURGICAL INC.", "label":"MCKESSON MEDICAL SURGICAL INC."},
  {"_id":"MEDICAL CENTER LAUNDRY", "id":"MEDICAL CENTER LAUNDRY", "key":"MEDICAL CENTER LAUNDRY", "value":"MEDICAL CENTER LAUNDRY", "label":"MEDICAL CENTER LAUNDRY"},
  {"_id":"MEDLINE - DS", "id":"MEDLINE - DS", "key":"MEDLINE - DS", "value":"MEDLINE - DS", "label":"MEDLINE - DS"},
  {"_id":"MEDLINE INDUSTRIES, INC.", "id":"MEDLINE INDUSTRIES, INC.", "key":"MEDLINE INDUSTRIES, INC.", "value":"MEDLINE INDUSTRIES, INC.", "label":"MEDLINE INDUSTRIES, INC."},
  {"_id":"MISSION LINEN SANTA BARB#ACTG", "id":"MISSION LINEN SANTA BARB#ACTG", "key":"MISSION LINEN SANTA BARB#ACTG", "value":"MISSION LINEN SANTA BARB#ACTG", "label":"MISSION LINEN SANTA BARB#ACTG"},
  {"_id":"NYU LANGONE", "id":"NYU LANGONE", "key":"NYU LANGONE", "value":"NYU LANGONE", "label":"NYU LANGONE"},
  {"_id":"NYU WINTHROP HOSPITAL", "id":"NYU WINTHROP HOSPITAL", "key":"NYU WINTHROP HOSPITAL", "value":"NYU WINTHROP HOSPITAL", "label":"NYU WINTHROP HOSPITAL"},
  {"_id":"OWENS & MINOR", "id":"OWENS & MINOR", "key":"OWENS & MINOR", "value":"OWENS & MINOR", "label":"OWENS & MINOR"},
  {"_id":"OWENS & MINOR", "id":"OWENS & MINOR", "key":"OWENS & MINOR", "value":"OWENS & MINOR", "label":"OWENS & MINOR"},
  {"_id":"PDL DISTRIBUTION, LLC", "id":"PDL DISTRIBUTION, LLC", "key":"PDL DISTRIBUTION, LLC", "value":"PDL DISTRIBUTION, LLC", "label":"PDL DISTRIBUTION, LLC"},
  {"_id":"SCRUBS & BEYOND, LLC", "id":"SCRUBS & BEYOND, LLC", "key":"SCRUBS & BEYOND, LLC", "value":"SCRUBS & BEYOND, LLC", "label":"SCRUBS & BEYOND, LLC"},
  {"_id":"STERILE SURGICAL SYSTEMS", "id":"STERILE SURGICAL SYSTEMS", "key":"STERILE SURGICAL SYSTEMS", "value":"STERILE SURGICAL SYSTEMS", "label":"STERILE SURGICAL SYSTEMS"},
  {"_id":"THE 37COMPANY    .", "id":"THE 37COMPANY    .", "key":"THE 37COMPANY    .", "value":"THE 37COMPANY    .", "label":"THE 37COMPANY    ."},
  {"_id":"UNITED MEDICAL TRADE COMPANY", "id":"UNITED MEDICAL TRADE COMPANY", "key":"UNITED MEDICAL TRADE COMPANY", "value":"UNITED MEDICAL TRADE COMPANY", "label":"UNITED MEDICAL TRADE COMPANY"},
  {"_id":"UPMC SUPPLY CHAIN MANAGEMENT", "id":"UPMC SUPPLY CHAIN MANAGEMENT", "key":"UPMC SUPPLY CHAIN MANAGEMENT", "value":"UPMC SUPPLY CHAIN MANAGEMENT", "label":"UPMC SUPPLY CHAIN MANAGEMENT"},
  {"_id":"VAMC - AUSTIN, TX", "id":"VAMC - AUSTIN, TX", "key":"VAMC - AUSTIN, TX", "value":"VAMC - AUSTIN, TX", "label":"VAMC - AUSTIN, TX"},
]


export default function DemandAna(props) {
  const userData = useContext(UserContext)


  const pageTitle = "Demand Analyzer"


  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION
  

  //SECTION - Fake Data
  const [ histogramData, setHistogramData ] = React.useState([{'name': 'Aug 2022', 'value': '2660'}, {'name': 'Sep 2022', 'value': '2965'}, {'name': 'Nov 2022', 'value': '1321'}, {'name': 'Dec 2022', 'value': '796'}, {'name': 'Jan 2023', 'value': '673'}])
  const [ histogramRange, setHistogramRange ] = React.useState(['2660', '2965', '1321', '796', '673'])

  const [ statsData, setStatsData ] = React.useState([
    {
      "id": "1",
      "stat": "Count",
      "value": "2190"
    },
    {
      "id": "2",
      "stat": "Sum",
      "value": "21900"
    },
    {
      "id": "3",
      "stat": "Average",
      "value": "219"
    },
    {
      "id": "4",
      "stat": "Standard Deviation",
      "value": "50"
    },
    {
      "id": "5",
      "stat": "Max",
      "value": "419"
    },
    {
      "id": "6",
      "stat": "Min",
      "value": "119"
    },

  ]
  );

  const [ lineData, setLineData ]  = React.useState ([
    {
      "name": "1/6/2023",
      "value": 200,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "1/13/2023",
      "value": 210,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "1/20/2023",
      "value": 383,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "1/27/2023",
      "value": 194,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/3/2023",
      "value": 249,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/10/2023",
      "value": 159,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/17/2023",
      "value": 179,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/24/2023",
      "value": 188,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    }
  ])

  const [ rangeData, setRangeData ]  = React.useState ([
    {
      "name": "1/6/2023",
      "value": 200,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "1/13/2023",
      "value": 210,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "1/20/2023",
      "value": 383,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "1/27/2023",
      "value": 194,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/3/2023",
      "value": 249,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/10/2023",
      "value": 159,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/17/2023",
      "value": 179,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "name": "2/24/2023",
      "value": 188,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    }
  ])
  // !SECTION - Fake Data


    //SECTION Account Select
    const [ currentDemandAccount, setCurrentDemandAccount ] = useSessionStorage(localStorage, "CurrentInventoryAccount", userData.company);
    const [ setCurrentDemandAccountCode ] = useSessionStorage(localStorage, "CurrentInventoryAccountCode", userData.accountCode);
    
    const [ setAccountName ] = React.useState(userData.company);
    const [ accountCode, setAccountCode ] = React.useState(userData.accountCode);
    const [ viewAccounts, setViewAccounts ] = React.useState(false);
    const [ viewAccountsMessage, setAccountsMessage ] = React.useState('Select Account');

    const toggleAccounts = () => {
      if (viewAccounts) {
        setViewAccounts(false);
        setAccountsMessage("View Accounts");
        // setViewFileDeleteButton(false);
      } else {
        setViewAccounts(true);
        setAccountsMessage("Hide Accounts");
        // getAccountList();
      }
    };
    const handleSelectedAccount = async (accountname, accountcode, membershipCode) => {
      setAccountName(accountname);
      setAccountCode(accountcode);
      setCurrentDemandAccount(accountname);
      setCurrentDemandAccountCode(accountcode);


    };
    //!SECTION Account Select

    //SECTION DropDowns Select

    const plecibo = () => {
    };


    //SECTION Data Analysis

    const [ viewDataAnalysis, setViewDataAnalysis ] = React.useState(false);
    const [ dataAnalysisMessage, setDataAnalysisMessage ] = React.useState('View Data Analysis');

    const toggleDataAnalysis = () => {
      if (viewDataAnalysis) {
        setViewDataAnalysis(false);
        setDataAnalysisMessage('View Data Analysis');
      }else{

        getPeriodList();
        getCustomerList();
        getSalesList();
        getRegionList();
        getProductGroupList();
        getColorList();
        getProductNumberList();
        // getSavedNetworkOptimizations();
        setViewDataAnalysis(true);
        setDataAnalysisMessage('Hide Data Analysis');
      };
    };

    //SECTION - Period DropDown
    const [ periodList, setPeriodList ] = React.useState([
      {"_id":"Week", "id":"Week", "key":"Week", "value":"Week", "label":"Week"},
      {"_id":"Month", "id":"Month", "key":"Month", "value":"Month", "label":"Month"},
      {"_id":"Quarter", "id":"Quarter", "key":"Quarter", "value":"Quarter", "label":"Quarter"},
      {"_id":"Year", "id":"Year", "key":"Year", "value":"Year", "label":"Year"},
    ]);

    const [ selectedPeriod, setSelectedPeriod ] = React.useState(
      "Month"
    );

    const getPeriodList = async () => {
      
      var account = currentDemandAccount;

      setPeriodList([
        {"_id":"Week", "id":"Week", "key":"Week", "value":"Week", "label":"Week"},
        {"_id":"Month", "id":"Month", "key":"Month", "value":"Month", "label":"Month"},
        {"_id":"Quarter", "id":"Quarter", "key":"Quarter", "value":"Quarter", "label":"Quarter"},
        {"_id":"Year", "id":"Year", "key":"Year", "value":"Year", "label":"Year"},
      ]);
      account = account.replace(/\s+/g, ''); //remove spaces from account name regex
      // await axios
      // .get(
      //   `/DemandAnalyzer/datalists/${account}/${listName}`,
      //   { headers: headers }
      //   )
      //   .then(function async(response) {
      //     // console.log(response.data.message[0]);
      //     setPeriodList([response.data.message[0]])
      //     })
      //   .catch(function (error) {
      //     if (error.response.status === 401) {
      //       onLogout();
      //     }
      //     if (error.response.status != 401) {
      //       // console.log(error.response.data.detail);
      //       setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
      //       setMessageBarOpen(true);
      //       setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      //     }
      //   })
    };
    const periodListTitles = {
      dropDownTitle: "Period",
      dropDownLabel: "Select Period",
      dropDownHelperText: "",
      dropDownAlertMessage: "Period " ,
    };
    const [ periodQuery ] = React.useState('month_year'); 
    const handleSelectedPeriod = async (selection) => {
      // setSelectedPeriod(selection);
      setSelectedPeriod("Month");

      // setPeriodQuery(periodList.find(x => x.value === selection).id);
    };
    //!SECTION - Period DropDown

    //SECTION - Customer DropDown
    const [ customerList, setCustomerList ] = React.useState([]);
    const [ selectedCustomer, setSelectedCustomer ] = React.useState('All');
    const getCustomerList = async () => {
      var account = currentDemandAccount;
      account = account.replace(/\s+/g, ''); //remove spaces from account name regex
      setCustomerList(staticCustomerList)
      // await axios
      // .get(
      //   `/DemandAnalyzer/datalists/${account}/${listName}`,
      //   { headers: headers }
      //   )
      //   .then(function async(response) {
      //     // console.log(response.data.message);
      //     setCustomerList(response.data.message)
      //     })
      //   .catch(function (error) {
      //     if (error.response.status === 401) {
      //       onLogout();
      //     }
      //     if (error.response.status != 401) {
      //       // console.log(error.response.data.detail);
      //       setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
      //       setMessageBarOpen(true);
      //       setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      //     }
      //   })
    };
    const customerListTitles = {
      dropDownTitle: "Customer",
      dropDownLabel: "Select Customer",
      dropDownHelperText: "",
      dropDownAlertMessage: "Customer " ,
    };

    const [ customerQuery, setCustomerQuery ] = React.useState('All'); 
    const handleSelectedCustomer = async (selection) => {
      setSelectedCustomer(selection);
      setCustomerQuery(customerList.find(x => x.value === selection).id);

    };
    //!SECTION - Customer DropDown

    //SECTION - Sales DropDown
    const [ salesList, setSalesList ] = React.useState([]);
    const [ selectedSales, setSelectedSales ] = React.useState('All');
    const getSalesList = async () => {
      const headers = props.headers;
      var listName = "salesPersonNumber";
      var account = currentDemandAccount;
      account = account.replace(/\s+/g, ''); //remove spaces from account name regex
      await axios
      .get(
        `/DemandAnalyzer/datalists/${account}/${listName}`,
        { headers: headers }
        )
        .then(function async(response) {
          // console.log(response.data.message);
          setSalesList(response.data.message)
          })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");

          }
          if (error.response.status !== 401) {
            // console.log(error.response.data.detail);
            setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
    };
    const salesListTitles = {
      dropDownTitle: "Sales Rep",
      dropDownLabel: "Select Sales Rep",
      dropDownHelperText: "",
      dropDownAlertMessage: "Sales Rep " ,
    };
    const [ salesQuery, setSalesQuery ] = React.useState('All');
    const handleSelectedSales = async (selection) => {
      setSelectedSales(selection);
      setSalesQuery(salesList.find(x => x.value === selection).id);
    };
    //!SECTION - Customer DropDown


    
    //SECTION - Region DropDown
    const [ regionList, setRegionList ] = React.useState([]);
    const [ selectedRegion, setSelectedRegion ] = React.useState('All');
    const getRegionList = async () => {
      const headers = props.headers;
      var listName = "region";
      var account = currentDemandAccount;
      account = account.replace(/\s+/g, ''); //remove spaces from account name regex
      await axios
      .get(
        `/DemandAnalyzer/datalists/${account}/${listName}`,
        { headers: headers }
        )
        .then(function async(response) {
          // console.log(response.data.message);
          setRegionList(response.data.message)
          })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");

          }
          if (error.response.status !== 401) {
            // console.log(error.response.data.detail);
            setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
    };
    const regionListTitles = {
      dropDownTitle: "Region",
      dropDownLabel: "Select Region",
      dropDownHelperText: "",
      dropDownAlertMessage: "Region " ,
    };
    const [ regionQuery, setRegionQuery ] = React.useState('All');
    const handleSelectedRegion = async (selection) => {
      setSelectedRegion(selection);
      setRegionQuery(regionList.find(x => x.value === selection).id);
    };
    //!SECTION - Regional DropDown
    
    
    //SECTION - Product Group DropDown
    const [ productGroupList, setProductGroupList ] = React.useState([]);
    const [ selectedProductGroup, setSelectedProductGroup ] = React.useState('All');
    const getProductGroupList = async () => {
      const headers = props.headers;
      var listName = "productGroup";
      var account = currentDemandAccount;
      account = account.replace(/\s+/g, ''); //remove spaces from account name regex
      await axios
      .get(
        `/DemandAnalyzer/datalists/${account}/${listName}`,
        { headers: headers }
        )
        .then(function async(response) {
          // console.log(response.data.message);
          setProductGroupList(response.data.message)
          })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            // console.log(error.response.data.detail);
            setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
    };
    const productGroupListTitles = {
      dropDownTitle: "Product Group",
      dropDownLabel: "Select Product Group",
      dropDownHelperText: "",
      dropDownAlertMessage: "Product Group " ,
    };
    const [ productGroupQuery, setProductGroupQuery ] = React.useState('All');
    const handleSelectedProductGroup = async (selection) => {
      setSelectedProductGroup(selection);
      setProductGroupQuery(productGroupList.find(x => x.value === selection).id);
    };
    //!SECTION - Product Group DropDown
    
    //SECTION - Item Number DropDown
    const [ productNumberList, setProductNumberList ] = React.useState([]);
    const [ selectedProductNumber, setSelectedProductNumber ] = React.useState('All');
    const getProductNumberList = async () => {
      const headers = props.headers;
      var listName = "productNumber";
      var account = currentDemandAccount;
      account = account.replace(/\s+/g, ''); //remove spaces from account name regex
      await axios
      .get(
        `/DemandAnalyzer/datalists/${account}/${listName}`,
        { headers: headers }
        )
        .then(function async(response) {
          // console.log(response.data.message);
          setProductNumberList(response.data.message)
          })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            // console.log(error.response.data.detail);
            setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
    };
    const productNumberListTitles = {
      dropDownTitle: "Product",
      dropDownLabel: "Select Product",
      dropDownHelperText: "",
      dropDownAlertMessage: "Product " ,
    };
    const [ productNumberQuery, setProductNumberQuery ] = React.useState('All');
    const handleSelectedProductNumber = async (selection) => {
      setSelectedProductNumber(selection);
      setProductNumberQuery(productNumberList.find(x => x.value === selection).id);
    };
    //!SECTION - Product Group DropDown
    

    //SECTION - Color DropDown
    const [ colorList, setColorList ] = React.useState([]);
    const [ selectedColor, setSelectedColor ] = React.useState('All');
    const getColorList = async () => {
      const headers = props.headers;
      var listName = "color";
      var account = currentDemandAccount;
      account = account.replace(/\s+/g, ''); //remove spaces from account name regex
      await axios
      .get(
        `/DemandAnalyzer/datalists/${account}/${listName}`,
        { headers: headers }
        )
        .then(function async(response) {
          // console.log(response.data.message);
          setColorList(response.data.message)
          })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");

          }
          if (error.response.status !== 401) {
            // console.log(error.response.data.detail);
            setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
    };
    const colorListTitles = {
      dropDownTitle: "Color",
      dropDownLabel: "Select Color",
      dropDownHelperText: "",
      dropDownAlertMessage: "Color " ,
    };
    const [ colorQuery, setColorQuery ] = React.useState('All');
    const handleSelectedColor = async (selection) => {
      setSelectedColor(selection);
      setColorQuery(colorList.find(x => x.value === selection).id);
    };
    //!SECTION - Product Group DropDown
    
  
    //!SECTION - DropDowns Select   
    //SECTION - Calulated Demand DropDowns
    const calculate = async () => {
      // console.log("Calculate");
      
      const headers = props.headers;
      setForecastSpinner(true);

      var parameters = {};

      if (selectedCustomer !== "All") {
        parameters.customerNumber = customerQuery;
      }
      if (selectedSales !== "All") {  
        parameters.salesPersonNumber = salesQuery;
      }
      if (selectedRegion !== "All") {
        parameters.region = regionQuery;
      }
      if (selectedProductGroup !== "All") {
        parameters.productGroup = productGroupQuery;
      }
      if (selectedColor !== "All") {
        parameters.color = colorQuery;
      }
      if (selectedProductNumber !== "All") {
        parameters.productNumber = productNumberQuery;
      }

      var account = currentDemandAccount;
      await axios
      .get(
        `/DemandAnalyzer/dataanalyzerdata/${account}/${periodQuery}`,
        // `/DemandAnalyzer/dataanalyzerdata/${account}/${periodQuery}/${selectedCustomer}/${selectedSales}/${selectedRegion}`,
        { headers: headers, params: parameters  }
        )
        .then(function async(response) {
          // console.log(response.data.rangeData);
          setLineData(response.data.message);
          setStatsData(response.data.statData);
          setRangeData(response.data.rangeData);
          setHistogramData(response.data.histogramData);
          setHistogramRange(response.data.histogramRange);
          setForecastSpinner(false);
          
          // setRegionList(response.data.message)
          })
        .catch(function (error) {
          if (error.response.status === 401) {
            alert ("Your session has expired. Please login again.");
          }
          if (error.response.status !== 401) {
            // console.log(error.response.data.detail);
            setForecastSpinner(false);
            setMessageBarMessage(`${error.response.data.detail}`);
            setMessageBarOpen(true);
            setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
          }
        })
    }
    //!SECTION - Calulated


    

//!FORECASTING

    //SECTION - Forecasting
    const [ viewForecasting, setViewForecasting ] = React.useState(false);
    const [ forecastingMessage, setForecastingMessage ] = React.useState('View Forecasting');

    const toggleForecasting = () => {
      if (viewForecasting) {
        setViewForecasting(false);
        setForecastingMessage('View Forecasting');
      }else{
        // console.log("Period List", periodList)
        // getSavedNetworkOptimizations();
        setViewForecasting(true);
        getPeriodList();
        // console.log(staticCustomerList)
        // setCustomerList(staticCustomerList);
        getForecastItemsList("customerAcctNumber", setForecastItemList);
        setForecastingMessage('Hide Forecasting');
      };
    };

    //SECTION - Period
    //! Using component above
    //!SECTION - Period

    //SECTION - Forecast DropDown

    const [ fileList ] = React.useState([
      {
      "_id": "Your Demand Data.csv",
      "id": "Your Demand Data.csv",
      "key": "Your Demand Data.csv",
      "value": "Your Demand Data.csv",
      "label": "Your Demand Data.csv"
      }
    ]);

    const [ selectedFile, setSelectedFile ] = React.useState("Your Demand Data.csv");


    const fileTitles = {
      dropDownTitle: "File",
      dropDownLabel: "Select File",
      dropDownHelperText: "",
      dropDownAlertMessage: "File " ,
    };

    const handleSelectedFile = async (selection) => {
      setSelectedFile(selection);
      // console.log(selection);
      // setForecastTypeQuery(colorList.find(x => x.value === selection).id);
    };
    //!SECTION - Forecast DropDown

    //SECTION - Forecast DropDown

    const [ forecastTypeList] = React.useState([
      {
      "_id": "last_period",
      "id": "last_period",
      "key": "last_period",
      "value": "Last Period",
      "label": "Last Period"
      },
      {
      "_id": "meanForecast",
      "id": "meanForecast",
      "key": "meanForecast",
      "value": "Mean Forecast",
      "label": "Mean Forecast"
      },
      {
      "_id": "moving_average",
      "id": "moving_average",
      "key": "moving_average",
      "value": "Moving Average",
      "label": "Moving Average"
      },
      {
      "_id": "expo_smoothing",
      "id": "expo_smoothing",
      "key": "expo_smoothing",
      "value": "Exponential Smoothing",
      "label": "Exponential Smoothing"
      },
      {
      "_id": "double_expo_smoothing",
      "id": "double_expo_smoothing",
      "key": "double_expo_smoothing",
      "value": "Double Exponential Smoothing",
      "label": "Double Exponential Smoothing"
      },
      {
      "_id": "triple_expo_smoothing",
      "id": "triple_expo_smoothing",
      "key": "triple_expo_smoothing",
      "value": "Triple Exponential Smoothing",
      "label": "Triple Exponential Smoothing"
      },
      {
      "_id": "triple_expo_additive",
      "id": "triple_expo_additive",
      "key": "triple_expo_additive",
      "value": "Triple Exponential Additive",
      "label": "Triple Exponential Additive"
      },
      {
      "_id": "tree",
      "id": "tree",
      "key": "tree",
      "value": "Tree",
      "label": "Tree"
      },
      {
      "_id": "forecast",
      "id": "forecast",
      "key": "forecast",
      "value": "Forecast",
      "label": "Forecast"
      },
      {
      "_id": "exremely_randomized_tree",
      "id": "exremely_randomized_tree",
      "key": "exremely_randomized_tree",
      "value": "Extremely Randomized Tree",
      "label": "Extremely Randomized Tree"
      },
      {
      "_id": "adaptive_boosting",
      "id": "adaptive_boosting",
      "key": "adaptive_boosting",
      "value": "Adaptive Boosting",
      "label": "Adaptive Boosting"
      },
      {
      "_id": "extreme_gradient_boosting",
      "id": "extreme_gradient_boosting",
      "key": "extreme_gradient_boosting",
      "value": "Extreme Gradient Boosting",
      "label": "Extreme Gradient Boosting"
      },
      {
      "_id": "neural_network",
      "id": "neural_network",
      "key": "neural_network",
      "value": "Neural Network",
      "label": "Neural Network"
      },
      {
      "_id": "simulation",
      "id": "simulation",
      "key": "simulation",
      "value": "Simulation",
      "label": "Simulation"
      },
      {
      "_id": "SPC",
      "id": "SPC",
      "key": "SPC",
      "value": "SPC",
      "label": "SPC"
      },
    ]);

    const [ selectedForecastType, setSelectedForecastType ] = React.useState("Mean Forecast");


    const forecastTypeListTitles = {
      dropDownTitle: "Forecast Type",
      dropDownLabel: "Select Forecast Type",
      dropDownHelperText: "",
      dropDownAlertMessage: "Forecast Type " ,
    };


    const handleSelectedForecastType = async (selection) => {
      setSelectedForecastType(selection);
      // console.log(selection);
      // setForecastTypeQuery(colorList.find(x => x.value === selection).id);
    };
    //!SECTION - Forecast DropDown

    //SECTION - Forecast By DropDown


    const [ forecastByList ] = React.useState([
      {
      "_id": "customer",
      "id": "customer",
      "key": "customer",
      "value": "Customer",
      "label": "Customer"
      },
      {
      "_id": "customerGroup",
      "id": "customerGroup",
      "key": "customerGroup",
      "value": "Customer Group",
      "label": "Customer Group"
      },
      {
      "_id": "sales_rep",
      "id": "sales_rep",
      "key": "sales_rep",
      "value": "Sales Rep",
      "label": "Sales Rep"
      },
      {
      "_id": "region",
      "id": "region",
      "key": "region",
      "value": "Region",
      "label": "Region"
      },
      {
      "_id": "item_number",
      "id": "item_number",
      "key": "item_number",
      "value": "Item Number",
      "label": "Item Number"
      }
    ]);

    const [ selectedForecastBy, setSelectedForecastBy ] = React.useState("Customer");


    const forecastByListTitles = {
      dropDownTitle: "Forecast By",
      dropDownLabel: "Select Forecast By",
      dropDownHelperText: "",
      dropDownAlertMessage: "Forecast By " ,
    };

    const handleSelectedForecastBy = async (selection) => {

      setForecastItemList([
        {
          "_id": "all",
          "id": "all",
          "key": "all",
          "value": "All",
          "label": "All"
          }
      ]);
      //Get the id of the selected forecast by
      // console.log(selection)
      var forecastBy = forecastByList.find(x => x.value === selection).id;
      if (selection === "Customer") {
        forecastBy = "customerAcctNumber";
      };
      if (selection === "Sales Rep") {
        forecastBy = "salesPersonNumber";
      };
      if (selection === "Item Number") {
        forecastBy = "productItemNumber";
      };

      getForecastItemsList(forecastBy, setForecastItemList);
      setSelectedForecastBy(selection);
      // console.log(forecastBy);
      // setForecastTypeQuery(colorList.find(x => x.value === selection).id);
    };

    //!SECTION - Forecast By DropDown

  //SECTION - Forecast Item
  const [ forecastGroupingTitle, setForecastGroupingTitle] = React.useState("Acct No");

  const [ forecastGridColumns, setforecastGridColumns ] = React.useState([
    {field: 'id', hide: true},
    {field: forecastGroupingTitle, headerName: forecastGroupingTitle, width: 200, editable: false},
    {field: 'Period', headerName: 'Period', width: 200, editable: false},
    {field: 'Quantity', headerName: 'Quantity', width: 200, editable: false},
    {field: 'Total Sales', headerName: 'Total Sales', width: 200, editable: false, hide: true},
    {field: 'Period Type', headerName: 'Period Type', width: 200, editable: false}
    ])
  const [ forecastGridData, setForecastGridData ] = React.useState([])

  
  const [ selectedForecastItem, setSelectedForecastItem ] = React.useState("All");
  
  
  const forecastItemListTitles = {
    dropDownTitle: "Forecast Item",
    dropDownLabel: "Select Forecast Item",
    dropDownHelperText: "",
    dropDownAlertMessage: "Forecast Item " ,
  };

  
  
  const [ forecastItemList, setForecastItemList ] = React.useState([
    {
      "_id": "all",
      "id": "all",
      "key": "all",
      "value": "All",
      "label": "All"
      },
    
  ])


  const handleSelectedForecastItem = async (selection) => {
    setSelectedForecastItem(selection);
    // console.log(selection);
    // setForecastTypeQuery(colorList.find(x => x.value === selection).id);
  };
  
  const getForecastItemsList = async (listName, setFunction) => {
    const headers = props.headers;
    // console.log(setFunction)
    var account = currentDemandAccount;
    account = account.replace(/\s+/g, ''); //remove spaces from account name regex
    await axios
    .get(
      `/DemandAnalyzer/datalists/${account}/${listName}`,
      { headers: headers }
      )
      .then(function async(response) {
        // console.log(listName)
        var responseList = response.data.message;
        if (listName === "customerAcctNumber") {
          responseList = staticCustomerList;
        }
        if (listName === "customerGroup") {
          responseList = staticCustomerGroupList;
        }


        setFunction(responseList)
        })
      .catch(function (error) {
        if (error.response.status === 401) {
          alert ("Your session has expired. Please login again.");
        }
        if (error.response.status !== 401) {
          // console.log(error.response.data.detail);
          setMessageBarMessage(`${error.response.data.detail}  You can add a warehouse on the Dashboard page.`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        }
      })
  };
  


  //!SECTION - Forecast Item DropDown

  //SECTION - Number of Periods

  const [ numberOfPeriodsList ] = React.useState([
    {
      "_id": "1",
      "id": "1",
      "key": "1",
      "value": "1",
      "label": "1"
      },
    {
      "_id": "2",
      "id": "2",
      "key": "2",
      "value": "2",
      "label": "2"
      },
    {
      "_id": "3",
      "id": "3",
      "key": "3",
      "value": "3",
      "label": "3"
      },
    
  ])

  const [ selectedNumberOfPeriods, setSelectedNumberOfPeriods ] = React.useState("1");


  const numberOfPeriodsListTitles = {
    dropDownTitle: "Number of Periods",
    dropDownLabel: "Select Number of Periods",
    dropDownHelperText: "",
    dropDownAlertMessage: "Number of Periods " ,
  };

  const handleSelectedNumberOfPeriods = async (selection) => {
    setSelectedNumberOfPeriods(selection);
    // console.log(selection);
    // setForecastTypeQuery(colorList.find(x => x.value === selection).id);
  };

  //!SECTION - Number of Periods

  //SECTION - Outside Information

  const [ outsideInformationList] = React.useState([
    {
      "_id": "discount_001",
      "id": "discount_001",
      "key": "discount_001",
      "value": "Discount 001",
      "label": "Discount 001"
      },
    
  ])

  const [ selectedOutsideInformation, setSelectedOutsideInformation ] = React.useState("Discount 001");


  const outsideInfomationListTitles = {
    dropDownTitle: "Outside Information",
    dropDownLabel: "Select Outside Information",
    dropDownHelperText: "",
    dropDownAlertMessage: "Outside Information " ,
  };


  const handleSelectedOutsideInfomation = async (selection) => {
    setSelectedOutsideInformation(selection);
    // console.log(selection);
    // setForecastTypeQuery(colorList.find(x => x.value === selection).id);
  };


  //!SECTION - Outside Information

  //SECTION - Forecast 
  const [ forecastGraphData, setGraphData ] = React.useState([
    {
      "Period": "1/6/2023",
      "Quantity": 200,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "Period": "1/13/2023",
      "Quantity": 210,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "Period": "1/20/2023",
      "Quantity": 383,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "Period": "1/27/2023",
      "Quantity": 194,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "Period": "2/3/2023",
      "Quantity": 249,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "Period": "2/10/2023",
      "Quantity": 159,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "Period": "2/17/2023",
      "Quantity": 179,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    },
    {
      "Period": "2/24/2023",
      "Quantity": 188,
      "avg": 219,
      "UCL": 427,
      "LCL": 12
    }
  ])

const [forecastSpinner, setForecastSpinner] = React.useState(false);

const gridChanges = (forecastGroupingTitle) => {
  // console.log(selectedForecastType);
  setforecastGridColumns(
  [
    {field: 'id', hide: true},
    {field: forecastGroupingTitle, headerName: forecastGroupingTitle, width: 200, editable: false},
    {field: 'Period', headerName: 'Period', width: 200, editable: false},
    {field: 'Quantity', headerName: 'Quantity', width: 200, editable: false},
    {field: 'Total Sales', headerName: 'Total Sales', width: 200, editable: false, hide: false},
    {field: 'Period Type', headerName: 'Period Type', width: 200, editable: false},
    {field: 'Quantity Forecast', headerName: 'Quantity Forecast', width: 200, editable: false, hide: ((selectedForecastType === "Moving Average" || "Exponential Smoothing" || "Double Exponential Smoothing") ? false : true) },
    {field: 'Sales Forecast', headerName: 'Sales Forecast', width: 200, editable: false, hide: ((selectedForecastType === "Moving Average" || "Exponential Smoothing"|| "Double Exponential Smoothing") ? false : true) },
    ]
  )
  }

  const saveForecast = async () => {
    setForecastSpinner(false);
    setMessageBarMessage("Forecast cannot be saved by this account.");
    setMessageBarOpen(true);
    setMessageSeverity("warning")
  }


const forecast = async () => {

  
  setForecastSpinner(true);
  
  
  const headers = props.headers;

  var parameters = {};
  parameters.forecastBy = selectedForecastBy;

  if (selectedForecastItem !== "All") {
    parameters.forecastItem = selectedForecastItem;
  }
  if (selectedOutsideInformation !== "All") {  
    parameters.outsideInfo = selectedOutsideInformation;
  }

  
  var account = currentDemandAccount;
  await axios
  .get(
    `/DemandAnalyzer/calculateforecast/${account}/${selectedFile}/${periodQuery}/${selectedForecastType}/${selectedNumberOfPeriods}`,
    { headers: headers, params: parameters  }
    )
    .then(function async(response) {
      // console.log(response.data.table_data.length);
      if (response.data.table_data.length === 0) {
        setMessageBarMessage(`Not enough data found for the selected parameters.`);
        setMessageBarOpen(true);
        setMessageSeverity("warning") // error: red, warning: orange, information: blue, success: green
        setForecastSpinner(false);
      }else{
        // console.log(response.data.table_data);
        // console.log(Object.keys(response.data.table_data[0])[0]);
        // console.log(response.data.table_data);
        setForecastGroupingTitle(Object.keys(response.data.table_data[0])[0])
        gridChanges(Object.keys(response.data.table_data[0])[0]);
        // // console.log(Object.keys(response.data.table_data[0])[0]);
        setForecastGridData(response.data.table_data);

        setGraphData(response.data.graph_data);
        setForecastSpinner(false);
        setLineData(response.data.message);
      }

      })
    .catch(function (error) {
      setForecastSpinner(false);
      if (error.response.status === 401) {
        alert ("Your session has expired. Please login again.");
      }
      if (error.response.status !== 401) {
        // console.log(error.response.data.detail);
        setForecastSpinner(false);
        setMessageBarMessage(`${error.response.data.detail}`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      }
    })
}
//!SECTION - forecast



  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <HeaderMenus pageTitle={pageTitle} openHeaderDrawer={props.openHeaderDrawer} handleOpenHeaderDrawer={props.handleOpenHeaderDrawer}  />

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />



        <Container 
        overflow="auto"
        maxWidth="xl"
        sx={{ pt: 8, pb:4, border:1 }} >
          <img src={EdonLogo} width={200} alt="Logo" />
          


          <Grid
              container
              spacing={3}
              sx={{ alignContent: "center", border: 0, pb: 2 }}
            >
              {/* <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} /> */}
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                sx={{ alignContent: "center", border: 0 }}
              >
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    border: 0,
                  }}
                >
                  {accountCode === '0xef4b67067c' ?
                  <img src={MicrosoftLogo}  width={200} alt="Logo" /> :
                  accountCode === ('0xf14195642') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" /> : 
                  accountCode === ('0x6a6d078c6d3') ?
                  <img src={AcmeLogo}  width={200} alt="Logo" />:  
                  <Typography align="center" variant={"h4"}>
                    
                  </Typography>
                  }
                  <Typography align="center" variant={"h4"}>
                    {currentDemandAccount}
                  </Typography>
                  <Typography align="center" variant={"h5"}>
                    {pageTitle}
                  </Typography>
                  <Typography align="center" variant={"subtitle1"}>
                    {userData.email}
                  </Typography>

                  {/* Unauthorized User */}
                  {!userData.services.includes("NO") &&
                  <Grid item md={12} lg={12} sx={{ alignContent: "center", p:2, border: 0 }}>
                    <Unauthorized />
                  </Grid>
                  }

              {/* Accounts Drop Down */}
              {userData.accountViewer && (
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ alignContent: "center", p:0, border: 0 ,display:"flex", flexDirection:"column"}}
                >

                    <Button
                      onClick={toggleAccounts}
                    >
                      {viewAccountsMessage}
                    </Button>
                    {
                      viewAccounts && (

                     <AccountDropDown 
                      handleSelectedAccount={handleSelectedAccount}
                      toggleAccounts={toggleAccounts}
                      user={userData}
                      headers={props.headers} />
                    
                      ) // send the list of files and a handler down to the dropdown
                    }
                </Grid>
              )}

                </Paper>
              </Grid>
            </Grid>

            <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
            
            {/* Data Analysis */}
            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "center", border: 0 }}
            >
            <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ justifyContent: "center", border: 0, pb:2 }}
                >
            {/* Dropdowns */}
            <Paper
                sx={{ p: 2, border:0, display: "flex", flexDirection: "column" }}
                >
            <Button onClick={toggleDataAnalysis}>
              {dataAnalysisMessage}
            </Button>

            { viewDataAnalysis && (
              <>
            <Paper sx={{ p: 0, border:0, display: "flex", flexDirection: "row" }}>
              <Grid container paddingTop = {0} paddingBottom = {0} spacing={4}>
        
                <Grid item  xs={3} sm={3} md={3} sx={{border:0, pb:0}}>
                <DropDownInitialValue
                    itemList={periodList}
                    handleSelectedItem={handleSelectedPeriod}
                    dropDownTitles={periodListTitles}
                    toggleClose={plecibo}
                    initialSelection={selectedPeriod}
                    />
                </Grid>
                <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
                <DropDownInitialValue
                    itemList={customerList}
                    handleSelectedItem={handleSelectedCustomer}
                    dropDownTitles={customerListTitles}
                    toggleClose={plecibo}
                    initialSelection={"All"}
                    />
                </Grid>
                <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
                <DropDownInitialValue
                    itemList={salesList}
                    handleSelectedItem={handleSelectedSales}
                    dropDownTitles={salesListTitles}
                    toggleClose={plecibo}
                    initialSelection={"All"}
                    />
                </Grid>
                <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
                <DropDownInitialValue
                    itemList={regionList}
                    handleSelectedItem={handleSelectedRegion}
                    dropDownTitles={regionListTitles}
                    toggleClose={plecibo}
                    initialSelection={"All"}
                    />
                </Grid>
              </Grid>
            </Paper>
            
            <Paper sx={{ pb: 2, border:0, display: "flex", flexDirection: "row" }}>
            <Grid container paddingTop = {0} paddingBottom = {0} spacing={4}>
              <Grid item  xs={4} sm={4} md={4} sx={{border:0, p:0}}>
              <DropDownInitialValue
                  itemList={productGroupList}
                  handleSelectedItem={handleSelectedProductGroup}
                  dropDownTitles={productGroupListTitles}
                  toggleClose={plecibo}
                  initialSelection={"All"}
                  />
              </Grid>
              <Grid item  xs={4} sm={4} md={4} sx={{border:0, p:0}}>
              <DropDownInitialValue
                  itemList={productNumberList}
                  handleSelectedItem={handleSelectedProductNumber}
                  dropDownTitles={productNumberListTitles}
                  toggleClose={plecibo}
                  initialSelection={"All"}
                  />
              </Grid>
              <Grid item  xs={4} sm={4} md={4} sx={{border:0, p:0}}>
              <DropDownInitialValue
                  itemList={colorList}
                  handleSelectedItem={handleSelectedColor}
                  dropDownTitles={colorListTitles}
                  toggleClose={plecibo}
                  initialSelection={"All"}
                  />
              </Grid>
            {/* <DropDownInitialValue
                itemList={periodList}
                handleSelectedItem={handleSelectedPeriod}
                dropDownTitles={periodListTitles}
                toggleClose={plecibo}
                initialSelection={"Month"}
              /> */}
        
            </Grid>
            </Paper>
            

          {/* Graphs */}
          <Paper sx={{ p: 0, mt:0, border:0,  display: "flex", flexDirection: "column" }}>
          <Grid container spacing={0} justifyContent={'space-between'} sx={{border:0, pt:0}}>

          <Grid container paddingTop = {10} paddingBottom = {5} spacing={2} sx={{justifyContent:"center", flexDirection: "row" }}>
            
            <Button disabled = {true} onClick={calculate}  variant='contained'sx={{backgroundColor:"#68a0d7"}} >
            {forecastSpinner && (
              <CircularProgress sx={{ margin: 1 }} color="inherit" size={20} />
              )}
              Calculate</Button>
          </Grid>

            <Grid item  xs={8} sm={8} md={8} sx={{border:1, p:5}}>
              <Box sx ={{display: "flex", pb:5, paddingRight:0, justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Demand Individual Control Chart </Typography>
              </Box>
              <ControlChart data={lineData} range={[]} />
            </Grid>
              
            <Grid item  xs={4} sm={4} md={4} sx={{border:1, p:5, m:0}}>
              <Box sx ={{display: "flex", pb:2 ,justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Statistics </Typography>
              </Box>
              <DemandAnalyzerTable data={statsData} range={[]} />
            </Grid>


            <Grid item  xs={8} sm={8} md={8} sx={{border:1, p:5}}>
              <Box sx ={{display: "flex", pb:5, justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Moving Range Chart </Typography>
              </Box>
              <ControlChart data={rangeData} range={[]} />
            </Grid>
              
            <Grid item  xs={4} sm={4} md={4} sx={{border:1, p:5}}>
              <Box sx ={{display: "flex", pb:2, justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Histogram</Typography>
              </Box>
              <BarChart data={histogramData} range={histogramRange} />
            </Grid>


          </Grid>
          </Paper>
          </>
            )
          }
          </Paper>
          </Grid>
          </Grid>




            {/* //!Forecasting */}


            <Grid
              container
              spacing={3}
              sx={{ justifyContent: "center", border: 0 }}
            >
            <Grid
                  item
                  xs={12}
                  md={12}
                  lg={12}
                  sx={{ justifyContent: "center", border: 0, pb:2 }}
                >
            {/* Dropdowns */}
            <Paper
                sx={{ p: 2, display: "flex", flexDirection: "column" }}
                >
            <Button onClick={toggleForecasting}>
              {forecastingMessage}
            </Button>

            { viewForecasting && (
              <>
            <Paper sx={{ p: 2, border:0, display: "flex",  flexDirection: "row" }}>
              <Grid container paddingTop = {0} paddingBottom = {0} justifyContent={"space-between"} spacing={4}>
        
                <Grid item  xs={3} sm={3} md={3} sx={{border:0, pb:0}}>
                <DropDownInitialValue
                    itemList={periodList}
                    handleSelectedItem={handleSelectedPeriod}
                    dropDownTitles={periodListTitles}
                    toggleClose={plecibo}
                    initialSelection={"Month"}
                    />
                </Grid>

                <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
                <DropDownInitialValue
                    itemList={fileList}
                    handleSelectedItem={handleSelectedFile}
                    dropDownTitles={fileTitles}
                    toggleClose={plecibo}
                    initialSelection={selectedFile}
                    />
                </Grid>


              </Grid>
            </Paper>

            
            <Paper sx={{ pb: 2, border:0, display: "flex", flexDirection: "row" }}>
            <Grid container paddingTop = {0} paddingBottom = {0} spacing={4}>
              <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
              <DropDownInitialValue
                  itemList={forecastByList}
                  handleSelectedItem={handleSelectedForecastBy}
                  dropDownTitles={forecastByListTitles}
                  toggleClose={plecibo}
                  initialSelection={selectedForecastBy}
                  />
              </Grid>
              <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
              <DropDownInitialValue
                  itemList={forecastItemList}
                  handleSelectedItem={handleSelectedForecastItem}
                  dropDownTitles={forecastItemListTitles}
                  toggleClose={plecibo}
                  initialSelection={selectedForecastItem}
                  />
              </Grid>
              <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
              <DropDownInitialValue
                  itemList={numberOfPeriodsList}
                  handleSelectedItem={handleSelectedNumberOfPeriods}
                  dropDownTitles={numberOfPeriodsListTitles}
                  toggleClose={plecibo}
                  initialSelection={selectedNumberOfPeriods}
                  />
              </Grid>
              <Grid item  xs={3} sm={3} md={3} sx={{border:0, p:0}}>
              <DropDownInitialValue
                  itemList={outsideInformationList}
                  handleSelectedItem={handleSelectedOutsideInfomation}
                  dropDownTitles={outsideInfomationListTitles}
                  toggleClose={plecibo}
                  initialSelection={selectedOutsideInformation}
                  />
              </Grid>
            {/* <DropDownInitialValue
                itemList={periodList}
                handleSelectedItem={handleSelectedPeriod}
                dropDownTitles={periodListTitles}
                toggleClose={plecibo}
                initialSelection={"Month"}
              /> */}
        
            </Grid>
            </Paper>
            



          {/* <Grid container paddingTop = {0} paddingBottom = {0} spacing={2} sx={{justifyContent:"center", flexDirection: "row" }}>
            <Button onClick={calculate} variant='contained'sx={{backgroundColor:"#68a0d7"}} >Calculate</Button>
          </Grid> */}

          {/* Graphs */}
          <Paper sx={{ p: 0, mt:0, border:0,  display: "flex", flexDirection: "column" }}>
          <Grid container spacing={0} justifyContent={'space-between'} sx={{border:0, pt:0}}>

          {/* <Grid container paddingTop = {10} paddingBottom = {5} spacing={2} sx={{justifyContent:"center", flexDirection: "row" }}>
            <Button onClick={forecast} variant='contained'sx={{backgroundColor:"#68a0d7"}} >Forecast</Button>
          </Grid> */}

            <Grid item  xs={2} sm={2} md={2} sx={{border:1, p:5}}>
            <Box sx ={{display: "flex", pb:2, justifyContent:'center'}} >
            <DropDownInitialValue
                itemList={forecastTypeList}
                handleSelectedItem={handleSelectedForecastType}
                dropDownTitles={forecastTypeListTitles}
                toggleClose={plecibo}
                initialSelection={selectedForecastType}
                />
              </Box>

            <Box sx ={{display: "flex", pb:2, justifyContent:'center'}} >
            
            <Button disabled={true} onClick={forecast} variant='contained'sx={{backgroundColor:"#68a0d7"}}>
            {forecastSpinner && (
              <CircularProgress sx={{ margin: 1 }} color="inherit" size={20} />
              )}
              Forecast</Button>
              </Box>

              <Button disabled={true} onClick={saveForecast} variant='contained'sx={{backgroundColor:"warning.main"}}>
                Save Forecast
              </Button>
           

            </Grid>

            <Grid item  xs={10} sm={10} md={10} sx={{border:1, p:5}}>
              <Box sx ={{display: "flex", pb:5, paddingRight:0, justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Forecast Trend:  {selectedForecastType}</Typography>
              {/* <Typography variant="h6" gutterBottom>{selectedForecastType}</Typography> */}
              </Box>
              <ForecastLineChart data={forecastGraphData} range={[]} />
            </Grid>
              
              
            <Grid item  xs={12} sm={12} md={12} sx={{border:1, p:5}}>
              <Box sx ={{display: "flex", pb:2, justifyContent:'space-around'}} >
              <Typography variant="h6" gutterBottom>Forecast Table</Typography>
              </Box>
              <ForecastGrid 

                    forecastGroupingTitle={forecastGroupingTitle}
                    gridColumns={forecastGridColumns}
                    gridData={forecastGridData} />
            </Grid>



          </Grid>
          </Paper>
          </>
            )
          }

            </Paper>
          </Grid>
          </Grid>



          <Grid item xs={12} sm={12} md={12}>
            <Copyright sx={{ pt: 4, align: "center" }} />
          </Grid>

        </Container>

          
        </Box>
      </Box>
    </ThemeProvider>
  );
}