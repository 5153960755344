import * as React from 'react';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';
// import DeleteIcon from '@mui/icons-material/Delete';
import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

export default function ReplenishmentShipmentGrid(props) {

    // const deleteProduct = React.useCallback( (sentID) => async () => {
    //     var currentProducts = [...props.gridData]
    //     currentProducts.splice(sentID-1,1)
    //     // console.log("CurrentProducts", currentProducts)
    //     props.handleRemoveProduct(currentProducts)
    // },
    // [props], );

    const [selectedModel, setSelectedModel ] = React.useState();
    const  GridData  = {
        columns: [
            {field: 'id', hide: true},
            {field: 'productId', headerName: 'Product Number', align:'center', headerAlign: 'center', width:150, editable: false},
            {field: 'serialNum', headerName: 'Serial Number', align:'center', headerAlign: 'center', width:120, editable: false},
            {field: 'status', headerName: 'Status', align:'center', headerAlign: 'center', width:120, editable: false},
      ], 
      rows: props.gridData
      };

  return (

    <div style={{ height: 300, width: '100%' }}>
      <DataGrid 
      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
        // props.handleMarketFileNames(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...GridData} 
      components={{
        Toolbar: GridToolbar,
      }}
      />
    </div>

  );
};