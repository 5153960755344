import * as React from 'react';
import { useContext } from 'react'; 
import { UserContext } from '../context/UserContext.js';

import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import axios from 'axios';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material//MenuItem';
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import MessageSnackBar from './MessageSnackBar';



export default function CreateUser(props) {
  const userData = useContext(UserContext);
  const storedHeaders = sessionStorage.getItem('headers');
  const headers = storedHeaders ? JSON.parse(storedHeaders) : {};


  //! Local SnackBar Messaging
  const [snackBarMessage ] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')

  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION

  //&Account DropDown
  //&Account DropDown
  //&Account DropDown
  //! Data amd Select for Account Dropdown
  const [selectedAccount, setSelectedAccount] = React.useState(userData.accountCode);

  //! accountList comes from Dashboard
  const handleAccountSelect = (event) => {
    setSelectedAccount(event.target.value);
    // setValidSelectedAccount(true);
  };

  //&Roles
  //&Roles
  //&Roles
  //! Role conversion to named Role
  React.useEffect(() => {
      // console.log("user", userData)
    validRoles(userData.userRole);
  }, [userData.userRole]);

  const validRoles = (userRole) => {
    const roles = [
      {
        id: 1,
        value: 10,
        label: "Platform Admin",
      },
      {
        id: 2,
        value: 8,
        label: "Member Admin",
      },
      {
        id: 3,
        value: 6,
        label: "Account Admin",
      },
      {
        id: 4,
        value: 4,
        label: "User",
      },
      {
        id: 5,
        value: 2,
        label: "Viewer",
      },
    ];
    var i = 10 - userRole;
    while (i > 0) {
      roles.shift();
      i = i - 2;
    }
    // console.log(roles)
    setCurrentRoles(roles);
  };
  //! Data and Select for Roles Dropdown
  const [currentRoles, setCurrentRoles] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState("");
  const handleRoleSelect = (event) => {
    setSelectedRole(event.target.value);
    setValidSelectedRole(true);
  };



   //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "userFirstName": false,
        "userLastName": false,
        "userEmail": false,

     });
    const [ helperText, setHelperText ] = React.useState(
      {
        "userFirstName":"",
        "userLastName":"",
        "userEmail":"",
       });
    const [ validFirstName, setValidFirstName ] = React.useState(false);
    const [ validLastName, setValidLastName ] = React.useState(false);
    const [ validEmail, setValidEmail ] = React.useState(false);
    const [ validSeletedRole, setValidSelectedRole ] = React.useState(false);
    // const [ validSeletedAccount, setValidSelectedAccount ] = React.useState(false);


    // const forceUpdate = React.useCallback(() => setValidField(!validField),[]);

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){

        case 'userEmail': 
          var email = e.target.value;
          var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          var emailValid = email.match(pattern);
          if (emailValid){
            setValidFields(prevState => ({
              ...prevState,
              "userEmail": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                userEmail: "",
                }));
              setValidEmail(true);
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "userEmail": true}));

              setHelperText(prevState => ({
                ...prevState,
                userEmail: "Need a valid email adress",
                }));
              setValidEmail(false);
          }
          break;

        case 'userFirstName': 
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "userFirstName": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                userFirstName: "",
                }));
              setValidFirstName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "userFirstName": true}));
              setHelperText(prevState => ({
                ...prevState,
                userFirstName: "First Name needs at least two characters",
                }));
              setValidFirstName(false)
          }
          break;

        case 'userLastName': 
          if (e.target.value.length > 1){
            setValidFields(prevState => ({
              ...prevState,
              "userLastName": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                userLastName: "",
                }));
              setValidLastName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "userLastName": true}));
              setHelperText(prevState => ({
                ...prevState,
                userLastName: "Last Name needs at least two characters",
                }));
              setValidLastName(false)
          }
          break;
        default:
          break;
      }
    }
    React.useEffect(() => {
 
      if (validFirstName && validEmail && validFirstName && validLastName && validSeletedRole) {
        setButtonDisable(false);
      }else{
        setButtonDisable(true);
      }
    },[ validEmail, validFirstName, validLastName, validSeletedRole]
    )
    //!SECTION
    


    //SECTION Axios Create User
  const handleCreatePlatformUser = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    if (formData.get("assignAccount")) {
      var assignAccount = formData.get("assignAccount");
    }else{
      var assignAccount = userData.accountCode;
    }
    const userEmail = formData.get("userEmail");
    const userFirstName = formData.get("userFirstName");
    const userLastName = formData.get("userLastName");
    const userRole = parseInt(formData.get("userRole"));
    const creatorEmail = userData.email;
    // const headers = props.headers;
    var data = {
      creatorEmail: creatorEmail,
      userFirstName: userFirstName,
      userLastName: userLastName,
      userEmail: userEmail,
      userRole: userRole,
      accountCode: assignAccount,
    };

    await axios(
      {
      method: "post",
      url: `/adminstration/addoktaplatformuser/`,
      data: data,
      headers: headers
    }
      )
      .then(function (response) {
        // Message about the User account
        // console.log(response.data);
        props.handleMessageBarOpen(response.data, "success");
        // Send the email
        if (sendUserEmail) {
          // console.log("Account Infor", assignAccount);
          props.sendUserInvite(userEmail, assignAccount, userFirstName);
        }
        props.toggleViewCreateUser();
      })
      .catch(function (error) {
        // handle error
        // console.log(error.response.data.detail);
        //!Message bar
        setMessageBarMessage(`Oh no! Something went wrong. ${error.response.data.detail} You can email info@edonconnect.tech for help.`);
        setMessageBarOpen(true);
        setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
      });
  };
  //!SECTION

  //& Email Checkbox
  const [sendUserEmail, setSendUserEmail] = React.useState(true);

  const handleEmailCheckbox = () => {
    setSendUserEmail(!sendUserEmail);
  };

  return (
    <Grid
      container
      marginTop={5}
      sx={{
        justifyContent: "center",
        alignContent: "center",
        p: 5,
        border: 1,
        borderStyle: "dotted",
        borderRadius: "5px",
      }}
    >

      {/* <Stack direction="column" alignItems="center" spacing={2}> */}
      <Box>
        <Typography component="h1" variant="h5">
          Create a New User
        </Typography>
      </Box>
        <Box
          component="form"
          onSubmit={handleCreatePlatformUser}
          noValidate
          overflow="auto"
          sx={{ mt: 1}}
        >
          <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />
          <Snackbar
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">
              {snackBarMessage}
            </MuiAlert>
          </Snackbar>

        {userData.userRole === 10 &&
          <TextField
            margin="normal"
            select
            required
            fullWidth
            name="assignAccount"
            label="Account"
            value={selectedAccount}
            onChange={handleAccountSelect}
            type="number"
            id="assignAccount"
            helperText="Select an Account"
          >
            {props.accountList.map((option) => (
              <MenuItem
                sx={{ whiteSpace: "normal" }}
                key={option.value}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          }

          <TextField
            error = {validFields.userEmail}
            helperText={helperText.userEmail}
            margin="normal"
            required
            fullWidth
            name="userEmail"
            label="User Email"
            type="text"
            id="userEmail"
            onChange={(e) => formValidation(e)}
          />

          <TextField
            error = {validFields.userFirstName}
            helperText={helperText.userFirstName}
            margin="normal"
            fullWidth
            required
            name="userFirstName"
            label="User First Name"
            type="text"
            id="userFirstName"
            onChange={(e) => formValidation(e)}
          />
          <TextField
            error = {validFields.userLastName}
            helperText={helperText.userLastName}
            margin="normal"
            fullWidth
            required
            name="userLastName"
            label="User Last Name"
            type="text"
            id="userLastName"
            onChange={(e) => formValidation(e)}
          />

          <TextField
            margin="normal"
            select
            fullWidth
            name="userRole"
            label="User Role"
            type="number"
            value={selectedRole}
            onChange={handleRoleSelect}
            id="userRole"
            helperText="Please Select Role for User"
          >
            {currentRoles.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>

          <Button
            disabled={buttonDisable}
            align="center"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Create New User
          </Button>
          
          <FormControlLabel
            control={<Checkbox defaultChecked onChange={handleEmailCheckbox} />}
            label="Send User Notification Email"
          />
        </Box>
      {/* </Stack> */}
    </Grid>
  );
}