import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


export default function MessageSnackBar(props) {

    // console.log("Props",props)
    // const [ messagebarOpen, setMessagebarOpen ] = React.useState(props.messageBarOpen)
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        props.handleMessageBarClosing();
      };
    
    
    return (
    <div>
      <Snackbar
      anchorOrigin = {{ horizontal: 'center', vertical: 'top'}}
      open={props.messageBarOpen}
      autoHideDuration={5000}
      onClose={handleClose}
    >
        <MuiAlert elevation={6} variant="filled" severity={props.messageSeverity}>{props.messageBarMessage}</MuiAlert>
      </Snackbar>
    </div>


)};