import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Copyright from './Components/Copyright';
import Stack from '@mui/material/Stack';
import EdonLogo from './edonLogoWhite.png'
import HeaderMenus from './Components/HeaderMenus.js';



// const theme = createTheme();

const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export default function Home(props) {


  return (
    <ThemeProvider theme={darkTheme}>

      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
          <HeaderMenus  onLogout={props.onLogout} />
        <Box
          sx={{
            width: '80%',
            display: 'flex',
            mt: 0,
            alignItems: 'center',
            justifyContent: 'center',
            border:0,
            
          }}
        >
          {/* <Toolbar /> */}
            <Stack>
              <Grid item sx={{pt:2}}>
                <img src={EdonLogo} alt="Logo" style={{width: "100%"}} />
              </Grid>
                 
                <Copyright sx={{ mt: 1, mb: 1, textAlign:"center" }} />
            </Stack>
        </Box>
      </Box>
    </ThemeProvider>
  );
}