import React from 'react';

import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { LicenseInfo } from '@mui/x-data-grid-pro';

const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);


export default function ParetoDistanceTable(props) {

  var maxPoint = Math.max(...props.netOptzData.resultDistanceChart[1].map(i=>Number(i)));
  maxPoint = Math.round(maxPoint/100)*100;



    return (
        <div style={{ height: 400, width: '100%' }}>
            <ResponsiveContainer height="80%">
              <BarChart
                        maxBarSize={50}
                        data={props.paretoDistanceChart}
                        margin={{
                          top: 25,
                          right: 30,
                          left: 10,
                          bottom: 5,
                        }}
                      >
                <XAxis angle={0} height={30} wrap dataKey="Distance" />
                <YAxis type='number' domain={[0, maxPoint]} />
                <Tooltip />
                <Legend />
                <Bar dataKey="Orders" fill="#68a0d7" />
              </BarChart>
              </ResponsiveContainer>


            </div>


    )
}