import * as React from 'react';
import { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { dataGridStyles } from './dataGridStyles';

import { 
  DataGridPro,
  GridToolbar,
  } from '@mui/x-data-grid-pro';

export default function CurrentWarehouseShelfTable(props) {

  const userData = useContext(UserContext)
  const visibleColumns = {
    id: false,
    userCode: false,
  }
    const [selectedModel, setSelectedModel ] = React.useState();
    const  currentWarehouseShelfs  = {
      columns: [
      {field: 'id', hide: true},
      {field: 'warehouseName', headerName: 'Warehouse', width:200, headerAlign: 'center', align: 'center',  editable: false},
      {field: 'shelfName', headerName: 'Shelf', width:200, headerAlign: 'center', align: 'center',  editable: false},

      ], 
      rows: props.warehouseShelfsDisplayData
      };


  return (

    <div style={{ height: 400, width: '100%' }}>
      <DataGridPro 
        sx={dataGridStyles.root}
        pagination

        initialState={{
          pageSize: 10,
          columns: {
            columnVisibilityModel: visibleColumns,
          },
          sorting: {
            sortModel: [{ field: "status" }],
          },
        }}
        slots={{toolbar: GridToolbar}}

      onSelectionModelChange={(newSelectionModel) => {
        setSelectedModel(newSelectionModel)
      }}
      selectedModel={selectedModel}
      {...currentWarehouseShelfs} 

      />
    </div>

  );
};