import * as React from 'react';
import { useContext } from 'react';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Checkbox from '@mui/material/Checkbox';
// import Paper from '@mui/material/Paper';
import axios from 'axios';

import Stack from '@mui/material/Stack';
// import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
// import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
// import MenuItem from '@mui/material//MenuItem';
// import Container from '@mui/material/Container';
// import { createTheme, ThemeProvider } from '@mui/material/styles';

import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert';
import MessageSnackBar from './MessageSnackBar';

import { UserContext } from '../context/UserContext.js';


// const theme = createTheme();

// const darkTheme = createTheme({
//     palette: {
//       mode: 'dark',
//     },
//   });

export default function CreateAccount(props) {

  const userData = useContext(UserContext)

  //SECTION Message Bar Controls
  const [ messageBarMessage, setMessageBarMessage ] = React.useState('');
  const [ messageBarOpen, setMessageBarOpen ] = React.useState(false);
  const [ messageSeverity, setMessageSeverity ] = React.useState('success')
  const handleMessageBarClosing = () => {
    setMessageBarOpen(false);
  };
  //!SECTION


  const [ snackBarMessage, setSnackBarMessage ] = React.useState('');
  const [ snackbarOpen, setSnackbarOpen ] = React.useState(false)
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
    props.toggleViewCreateAccount();
  };


    //SECTION Form Validation
    //! Form Validation
    const [ buttonDisable, setButtonDisable ] = React.useState(true);
    const [ validFields, setValidFields ] = React.useState(
      {
        "companyName": false,
        "adminstratorEmail": false,

     });
    const [ helperText, setHelperText ] = React.useState(
      {
        "companyName":"",
        "adminstratorEmail":"",
       });
    const [ validName, setValidName ] = React.useState(false);
    const [ validEmail, setValidEmail ] = React.useState(false);

    const formValidation =  (e) => {
      e.preventDefault();
      // console.log(e.target.id);
      // console.log(e.target.value);
      switch (e.target.id){
        case 'companyName': 
          if (e.target.value.length > 4){
            setValidFields(prevState => ({
              ...prevState,
              "companyName": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                companyName: "",
                }));
              setValidName(true);
              
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "companyName": true}));
              setHelperText(prevState => ({
                ...prevState,
                companyName: "Company Name needs a least five characters",
                }));
              setValidName(false)
          }
          break;

        case 'adminstratorEmail': 
          var email = e.target.value;
          // eslint-disable-next-line 
          var pattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          var emailValid = email.match(pattern);
          if (emailValid){
            setValidFields(prevState => ({
              ...prevState,
              "adminstratorEmail": false,
              }));
              setHelperText(prevState => ({
                ...prevState,
                adminstratorEmail: "",
                }));
              setValidEmail(true);
            }else{
              setValidFields(prevState => ({
                ...prevState,
                "adminstratorEmail": true}));

              setHelperText(prevState => ({
                ...prevState,
                adminstratorEmail: "Need a valid email adress",
                }));
              setValidEmail(false);
          }
          break;
        default:
          break;
      }
    }
    React.useEffect(() => {
      if (validName && validEmail) {
        setButtonDisable(false);
      }else{
        setButtonDisable(true);
      }
    },[validName, validEmail]
    )
    //!SECTION




  const handleCreateAccount = async (event) => {
      event.preventDefault();
      const formData = new FormData(event.currentTarget);
      const companyName = formData.get('companyName');
      const adminEmail= formData.get('adminEmail');
      const creatorEmail = userData.email;
      const membershipCode = formData.get('membershipCode')

      var data = JSON.stringify({
          "companyName": companyName,
          "membershipCode": membershipCode,
          "creatorEmail": creatorEmail,
          "adminEmail": adminEmail,
        });
      const headers = props.headers;

      //SECTION Axios Add account Post
      // console.log(props.headers)
      await axios(
        {
          method: 'post',
          url: `/adminstration/addaccount/`,
          data,
          headers: headers
        }
        )
        .then(function (response) {
          setSnackBarMessage(response.data)
          setSnackbarOpen(true);
        })
        .catch(function (error) {
          // handle error
          // console.log(error);
          //!Message bar
          setMessageBarMessage(`Oh no! Something went wrong. Please try again or email info@edonconnect.tech for help. ${error.response.data.detail}`);
          setMessageBarOpen(true);
          setMessageSeverity("error") // error: red, warning: orange, information: blue, success: green
        })
        .then(function () {
          // always executed
        });
  };
  //!SECTION


  return (
    <Grid container 
    marginTop={5} 
    sx={{
      justifyContent:'center',
      alignContent:'center',
      p:5,
      border:1,
      borderStyle: 'dotted',
      borderRadius: '5px'  }}>
      
        <Stack direction="column" alignItems="center" spacing={2}>
          <Typography component="h1" variant="h5">
            Create a New Account
          </Typography>


    <Box component="form" onSubmit={handleCreateAccount} noValidate sx={{ mt: 1 }}> 
    <MessageSnackBar messageBarMessage={messageBarMessage} messageBarOpen={messageBarOpen} handleMessageBarClosing={handleMessageBarClosing} messageSeverity={messageSeverity} />   
      <Snackbar
            anchorOrigin = {{ horizontal: 'center', vertical: 'top'}}
            open={snackbarOpen}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <MuiAlert elevation={6} variant="filled" severity="success">{snackBarMessage}</MuiAlert>
      </Snackbar>

            <TextField
              error = {validFields.companyName}
              helperText={helperText.companyName}
              margin="normal"
              required
              fullWidth
              name="companyName"
              label="Company Name"
              type="text"
              id="companyName"
              onChange={(e) => formValidation(e)}

            />
            <TextField
              error = {validFields.adminstratorEmail}
              helperText={helperText.adminstratorEmail}
              margin="normal"
              required
              fullWidth
              name="adminEmail"
              label="Adminstrative Email"
              type="text"
              id="adminstratorEmail"
              onChange={(e) => formValidation(e)}
            />
 

            {(userData.userRole > 9 && userData.membershipCode === '0xd4eed5a7')  && 
            <TextField
              margin="normal"
              fullWidth
              name="membershipCode"
              label="Membership Code [optional]"
              type="text"
              id="membershipCode"
            />
            }

            <Button
              disabled={buttonDisable}
              align="center"
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }} 
            >
              Create New Account
            </Button>

          </Box>
          </Stack>
        </Grid>

  );
}