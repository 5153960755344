import React from 'react';
// import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';
// import MuiDrawer from '@mui/material/Drawer';
// import Box from '@mui/material/Box';
// import MuiAppBar from '@mui/material/AppBar';
// import Toolbar from '@mui/material/Toolbar';
// import List from '@mui/material/List';
// import Typography from '@mui/material/Typography';
// import Divider from '@mui/material/Divider';
// import IconButton from '@mui/material/IconButton';
// import Badge from '@mui/material/Badge';
// import Container from '@mui/material/Container';
// import Grid from '@mui/material/Grid';
// import Paper from '@mui/material/Paper';
// import Link from '@mui/material/Link';
// import MenuIcon from '@mui/icons-material/Menu';
// import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
// import NotificationsIcon from '@mui/icons-material/Notifications';


// import Button from '@mui/material/Button';

// import Card from '@mui/material/Card';
// import CardMedia from '@mui/material/CardMedia';
// import CardContent from '@mui/material/CardContent';
// import CardActions from '@mui/material/CardActions';
// import LinearProgress from '@mui/material/LinearProgress';
// import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import { LicenseInfo } from '@mui/x-data-grid-pro';
const REACT_APP_GRID = process.env.REACT_APP_GRID
LicenseInfo.setLicenseKey(REACT_APP_GRID);

// {/* Create Themes */}
// const mdTheme = createTheme();
// const darkTheme = createTheme({
//   palette: {
//     mode: 'dark',
//   },
// });







export default function WarehouseDataDisplay(props) {

    


    const  WarehouseTable  = {
        columns: [
        {field: 'id', hide: true},
        {field: 'warehouseNumber', headerName: 'Id Number', flex:1, editable: false},
        {field: 'LAT', headerName: 'Latitude', flex:1,editable: false, headerAlign: 'center', type: 'number', hide: true},
        {field: 'LON', headerName: 'Longitude', flex:1, editable: false, headerAlign: 'center', type: 'number', hide:true},
        {field: 'Provider', headerName: 'Provider', flex:1,editable: false, headerAlign: 'center', type: 'number'},
        {field: 'Address', headerName: 'Address', flex:1, headerAlign: 'center', editable: false, type: 'number'},
        {field: 'City', headerName: 'City', flex:1, headerAlign: 'center', editable: false, type: 'number'},
        {field: 'State', headerName: 'State', flex:1, headerAlign: 'center', editable: false, type: 'number'},
        {field: 'Zip', headerName: 'Zip', flex:1, headerAlign: 'center', editable: false, type: 'number'},
        {field: 'numberOfOrders', headerName: 'Orders', flex:1, headerAlign: 'center', editable: false, type: 'number'},
        {field: 'milesToCenter', headerName: 'Miles to Center', flex:1,headerAlign: 'center', editable: false, type: 'number'},
        ], 
        rows: props.warehouseDetailTable 
        }


    return (
        <div style={{ height: 400, width: '100%' }}>
            <DataGrid 
                {...WarehouseTable} 
                components={{
                    Toolbar: GridToolbar,
                }}
            />

            </div>


    )
}

